import React from 'react'
import {sharedStyles} from '../../style/shared_styles'
import {Button} from 'antd'
import {ButtonStyle} from '../../style/button'
import {i18n} from '../../i18n'

const ActionButtons: React.FC<ActionButtonsProps> = (props: ActionButtonsProps) => {
    return (
        <div style={{display: 'flex'}}>
            <div style={sharedStyles.actionBtnLeft}>
                {props.onBack && (
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={props.onBack}>
                        {i18n.t('button.back')}
                    </Button>
                )}
                {props.onCancel && (
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={props.onCancel}>
                        {i18n.t('button.cancel')}
                    </Button>
                )}
            </div>
            <div style={sharedStyles.actionBtnRight}>
                {props.onSaveAndNew && (
                    <Button
                        type={'default'}
                        shape={'round'}
                        style={props.disabledSaveAndNew ? ButtonStyle.disableButton : ButtonStyle.primary}
                        disabled={props.disabledSaveAndNew}
                        onClick={props.onSaveAndNew}>
                        {i18n.t('button.saveAndNew')}
                    </Button>
                )}

                {props.onSave && (
                    <Button
                        type={'primary'}
                        shape={'round'}
                        style={props.disabledSave ? ButtonStyle.disableButton : ButtonStyle.save}
                        disabled={props.disabledSave}
                        loading={props.loadingSave}
                        onClick={props.onSave}>
                        {props.saveText || i18n.t('button.save')}
                    </Button>
                )}
            </div>
        </div>
    )
}

type ActionButtonsProps = {
    onCancel?: () => void
    onBack?: () => void
    onSave?: () => void
    onSaveAndNew?: () => void
    loadingSave?: boolean
    disabledSave?: boolean
    disabledSaveAndNew?: boolean
    saveText?: string
}

export default ActionButtons
