import React from 'react'
import {PopUpDelete, TitleText} from '../index'
import {Descriptions, PageHeader} from 'antd'
import {CommonType} from '../../../utils/types'
import {i18n} from '../../../i18n'

const DetailsPageLayout = <T extends CommonType>(props: DetailsPageLayoutProps<T>) => {
    const extraActions = []

    if (props.onDelete) {
        extraActions.push(<PopUpDelete key={"delete"} onDelete={props.onDelete} loading={props.loadingDelete} messageDelete={props.messageDelete} />)
    }

    // add more actions

    const renderContent = (column = 1) => {
        if (!props.data) {
            return
        }
        return (
            <div className='content'>
                <Descriptions size='small' column={column}>
                    <Descriptions.Item label={i18n.t("common.details.id")} key={'id'}>
                        <span style={{fontWeight: 'bold'}}>{props.data.id || '-'}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("common.details.created")} key={"created"}>
                        <span style={{fontWeight: 'bold'}}>{(props.data.createdBy || '-') + " : " + (props.data.createdAt?.toLocaleDateString(i18n.locale) || '-')}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("common.details.modified")} key={'modified'}>
                        <span style={{fontWeight: 'bold'}}>{(props.data.modifiedBy || '-') + " : " + (props.data.modifiedAt?.toLocaleDateString(i18n.locale) || '-')}</span>
                    </Descriptions.Item>
                </Descriptions>
            </div>
        )
    }

    return (
        <PageHeader
            className='site-page-header-responsive'
            onBack={() => (props.onBack ? props.onBack() : window.history.back())}
            title={<TitleText text={props.title || ''} size={25} />}
            extra={extraActions}
            footer={props.footer}>
            {renderContent()}
        </PageHeader>
    )
}

type DetailsPageLayoutProps<T extends CommonType> = {
    title?: string
    onDelete?: () => void
    loadingDelete?: boolean
    messageDelete?: string
    footer: React.ReactNode
    extraContent?: {label: string, value?: string}[]
    data?: T

    onBack?: () => void
}

export default DetailsPageLayout
