import {useEffect} from "react";

/**
 * trigger on change on didMount because useForm need to aware all available inputs
 * @param value
 * @param onValueChanged
 * @param defaultValue
 * @param skipInitialize
 */
export const useDefaultValueSet = <T>(value: T, onValueChanged: (val: any, error?: string) => void, defaultValue?: T, skipInitialize?: boolean) => {
    useEffect(() => {
        if (!skipInitialize) {
            onValueChanged(value || defaultValue || undefined)
        }
    }, [])
}
