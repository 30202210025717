import {useEffect, useState} from 'react'
import {useGetPaginationData} from './useGetInitiData'
import {DEFAULT_PAGE_SIZE} from '../../store/crud_store'
import {TablePaginationConfig} from 'antd'
import {ICRUDStore} from '../../store/utils/types'
import {AnyObj} from '../types'

export const usePagination = <T>(
    store: ICRUDStore<T>,
    searchList?: AnyObj,
    sortField?: string,
    sortOrder?: string
): [boolean, TablePaginationConfig] => {
    const [pageLoading, setPageLoading] = useState(false)
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
    const [initLoading] = useGetPaginationData(store, {
        page: page,
        pageSize: pageSize,
        searchList,
        sortField,
        sortOrder,
    })

    const onPageChange = async (page: number, pageSize?: number) => {
        setPageLoading(true)
        setPageSize(pageSize || DEFAULT_PAGE_SIZE)
        setPage(page)
        await store.getPaginatedData({
            page: page,
            pageSize: pageSize,
            searchList,
            sortField,
            sortOrder,
        })
        setPageLoading(false)
    }

    useEffect(() => {
        setPage(1)
    }, [searchList, sortField, sortOrder])

    return [
        initLoading || pageLoading, // loading indicator for both initializing and page change
        {
            // general pagination configuration
            pageSize: pageSize,
            total: store.dataCount,
            showTotal: (total: number, range: [number, number]) => `${range[0]} to ${range[1]} of ${store.dataCount}`,
            onChange: onPageChange,
            position: ['topRight', 'bottomRight'],
            current: page,
        },
    ]
}
