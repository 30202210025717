import {AbdaDto} from '../utils/types'
import {IAbdaService} from './utils/types'
import {EntityPathEnum} from '../utils/enums'
import UploadService from './upload_service'
import {AxiosRequestHeaders, AxiosResponse} from "axios";
import {useAuthHeader} from "../utils/hooks/useAuthHeader";

class AbdaService extends UploadService<AbdaDto> implements IAbdaService {
    constructor() {
        super(EntityPathEnum.ABDA)
    }

    async export(): Promise<AxiosResponse<string>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler(
            {
                method: 'GET',
                url: `/api/private/${this.resourcePath}/export-csv`,
                headers: {
                    ...authHeader
                }
            }
        )
    }
}

export default AbdaService
