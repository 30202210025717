import {useEffect, useState} from 'react'

/**
 * get server data for products / ingredients
 * @param defaultData
 * @param getDataServer
 * @param toggleRefreshData
 */
export const useGetServerData = <T extends unknown>(
    defaultData: T[] | undefined | null,
    getDataServer?: () => Promise<T[] | undefined>
): [T[] | undefined | null, (() => void) | undefined, boolean] => {
    const [data, setData] = useState<T[] | undefined | null>(defaultData || null)
    const [loading, setLoading] = useState(false)

    const getServerData = async () => {
        if (getDataServer) {
            if (data) {
                setLoading(true)
            }
            const response: T[] | undefined = await getDataServer()
            setData(response)
            setLoading(false)
        }
    }

    useEffect(() => {
        getServerData()
    }, [])

    return [data, getServerData, loading]
}
