import React, {useState} from 'react'
import {ActionButtons, Input} from '../../common'
import {I18nEntity} from '../../../utils/types'
import {sharedStyles} from '../../../style/shared_styles'
import {i18n}  from '../../../i18n'

const NewI18nEntry: React.FC<NewI18nEntryProps> = (props: NewI18nEntryProps) => {
    const [newValue, setNewValue] = useState<I18nEntity>(props.defaultData)

    const editValueLanguage = (lang: string, value: string) => {
        setNewValue({
            ...newValue,
            translations: {
                ...newValue.translations,
                [lang]: value,
            },
        })
    }

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn, maxWidth: '100%'}}>
                    <Input
                        mandatory={true}
                        label={i18n.t('planner.key')}
                        value={newValue.key}
                        onValueChanged={(value) => setNewValue({...newValue, key: value})}
                    />
                    {!props.noTranslations && props.languages.map((lang) => (
                        <Input
                            key={lang}
                            label={lang}
                            value={newValue.translations[lang]}
                            onValueChanged={(value) => editValueLanguage(lang, value)}
                        />
                    ))}
                    <ActionButtons saveText={i18n.t('button.add')} onSave={() => props.onSave(newValue)} disabledSave={!newValue.key} />
                </div>
            </div>
        </div>
    )
}

type NewI18nEntryProps = {
    languages: string[]
    defaultData: I18nEntity
    onSave: (value: I18nEntity) => void
    noTranslations?: boolean
}

export default NewI18nEntry
