import React from 'react'
import {Modal, ModalProps} from 'antd'
import {Color} from '../../style/colors'

const CustomModal: React.FC<CustomModalProps & ModalProps> = props => {
    return (
        <Modal footer={null} style={{width: props.width}}
               maskClosable={false}
               destroyOnClose={true}
               maskStyle={{background: Color.active, opacity: 0.7}}
               {...props}>
            {props.children}
        </Modal>
    )
}

type CustomModalProps = {
    title: string,
    width?: number | string
}

export default CustomModal
