import {
    DoctorDto,
    Icd10Dto,
    IngredientDto,
    PraxisDto,
    ProductDto,
    ProductIngredientDto,
    SideEffectIngredientDto,
    SideEffectType,
    UserDto,
    VitalDataIngredientDto,
    VitalDataType,
    VitalParameterDefinition
} from '../types'

export enum ObservationKeys {
    title = 'title',
    parameterType = 'parameterType',
    description = 'description',
    possibleValues = 'possibleValues',
    /*vital data*/
    name = 'name',
    fhirCode = 'fhirCode',
    parameterDefinitions = 'parameterDefinitions'
}

export enum ParamDefinitionKeys {
    max = 'max',
    min = 'min',
    unit = 'unit',
    label = 'label',
    isDecimal = 'isDecimal',
    definitionId = 'definitionId',
    parameterId = 'parameterId',
    fhirCode = 'fhirCode'
}

export enum ProdIngredientCommonKeys {
    // common
    name = 'name',
    active = 'active',
    valid = 'valid',

    // product specific
    pzn = 'pzn',
    packSize = 'packSize',
    packQuantity = 'packQuantity',
    producer = 'producer',
    unit = 'unit',
    dosageForms = 'dosageForms',
    pznSmallerPack = 'pznSmallerPack',
    pznSuccessor = 'pznSuccessor',

    // ingredient specific
    keySto = 'keySto',
    note = 'note',
    url = 'url'
}

export enum RelationsDtoKeys {
    product = 'product',
    ingredient = 'ingredient',
    dose = 'dose',
    unit = 'unit',
    sideEffect = 'sideEffect',
    vitalData = 'vitalData',
    rang = 'rang'
}

export enum DoctorDtoKeys {
    firstName = 'firstName',
    lastName = 'lastName',
    title = 'title',
    praxis = 'praxis',
    doctorType = 'doctorType',
    active = 'active',
    email = 'email'
}

export enum Icd10Keys {
    externalId = 'externalId',
    name = 'name',
    code = 'code',
    medipolisName = 'medipolisName',
    active = 'active'
}

export enum PraxisDtoKeys {
    name = 'name',
    description = 'description',
    active = 'active'
}

export enum UsersDtoKeys {
    email = 'email',
    firstName = 'firstName',
    lastName = 'lastName',
    username = 'username',
    active = 'active',
    roles = 'roles'
}

export const sideEffectMandatoryFields: (keyof SideEffectType & string)[] = [ObservationKeys.title, ObservationKeys.parameterType]

export const vitalMandatoryFields: (keyof VitalDataType & string)[] = [ObservationKeys.name, ObservationKeys.parameterType]
export const paramDefMandatoryFields: (keyof VitalParameterDefinition & string)[] = [ParamDefinitionKeys.unit]
export const productMandatoryFields: (keyof ProductDto & string)[] = [
    ProdIngredientCommonKeys.name,
    ProdIngredientCommonKeys.producer,
    ProdIngredientCommonKeys.pzn
]

export const ingredientMandatoryFields: (keyof IngredientDto & string)[] = [ProdIngredientCommonKeys.name, ProdIngredientCommonKeys.keySto]

export const productIngredientMandatoryFields: (keyof ProductIngredientDto & string)[] = [
    RelationsDtoKeys.product,
    RelationsDtoKeys.ingredient,
    RelationsDtoKeys.rang
]

export const sideEffectIngredientMandatoryFields: (keyof SideEffectIngredientDto & string)[] = [
    RelationsDtoKeys.sideEffect,
    RelationsDtoKeys.ingredient
]

export const vitalDataIngredientMandatoryFields: (keyof VitalDataIngredientDto & string)[] = [RelationsDtoKeys.vitalData, RelationsDtoKeys.ingredient]

export const doctorsMandatoryFields: (keyof DoctorDto & string)[] = [
    DoctorDtoKeys.firstName,
    DoctorDtoKeys.lastName,
    DoctorDtoKeys.praxis,
    DoctorDtoKeys.email
]

export const icd10MandatoryFields: (keyof Icd10Dto & string)[] = [Icd10Keys.name]

export const praxisMandatoryFields: (keyof PraxisDto & string)[] = [PraxisDtoKeys.name]

export const userMandatoryFields: (keyof UserDto & string)[] = [UsersDtoKeys.email, UsersDtoKeys.firstName, UsersDtoKeys.lastName]
