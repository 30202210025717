import React, {useCallback} from 'react'
import CardMenu from './components/CardMenu'
import {Routes} from '../../utils/routes'
import {Typography} from 'antd'
import {i18n}  from '../../i18n'
import {Role} from '../../utils/enums'
import Secured from '../core/security/Secured'
import {styles} from './CardMenu.style'

const HomePage = () => {
    // cant' move outside, i18n don't work
    const pharmaModules1: Module[] = [
        {
            title: i18n.t('home.pharma.sideEffects'),
            link: Routes.SIDE_EFFECTS,
            role: Role.SE
        },
        {
            title: i18n.t('home.pharma.vitalData'),
            link: Routes.VITAL_DATA,
            role: Role.VD
        },
        {
            title: i18n.t('home.pharma.ingredients'),
            link: Routes.INGREDIENTS,
            role: Role.INGR
        },
        {
            title: i18n.t('home.pharma.products'),
            link: Routes.PRODUCTS,
            role: Role.PROD
        },
        {
            title: i18n.t('home.pharma.icd10'),
            link: Routes.ICD10
        }
    ]

    const pharmaModules2: Module[] = [
        {
            title: i18n.t('home.pharma.prodIng'),
            link: Routes.PRODUCT_INGREDIENT,
            role: Role.PROD_INGR
        }
    ]

    const hcpModules: Module[] = [
        {
            title: i18n.t('home.hcp.doctors'),
            link: Routes.DOCTORS,
            role: Role.DOC
        },
        {
            title: i18n.t('home.hcp.praxis'),
            link: Routes.PRAXIS,
            role: Role.PRAX
        }
    ]

    const adminModules: Module[] = [
        {
            title: i18n.t('home.admin.users'),
            link: Routes.USER,
            role: Role.USER
        }
    ]

    const abdaModules: Module[] = [
        {
            title: i18n.t('home.abda.meta'),
            link: Routes.ABDA,
            role: Role.ABDA
        }
    ]

    const plannerModules: Module[] = [
        {
            title: i18n.t('home.planner.therapyNames'),
            link: Routes.THERAPY_NAMES,
            role: Role.TER_NAME
        },
        {
            title: i18n.t('home.planner.titleEntity'),
            link: Routes.TITLE,
            role: Role.TITLE
        },
        {
            title: i18n.t('home.planner.dosageForms'),
            link: Routes.DOSAGE_FORMS,
            role: Role.DOSAGE_FORMS
        },
        {
            title: i18n.t('home.planner.unitMeasure'),
            link: Routes.UNIT_MEASURE,
            role: Role.UNIT_MEASURE
        }
    ]

    const plannerModules2: Module[] = [
        {
            title: i18n.t('home.planner.federalState'),
            link: Routes.FEDERAL_STATE,
            role: Role.FEDERAL_STATE
        },
        {
            title: i18n.t('home.planner.formOfAddress'),
            link: Routes.FORM_OF_ADDRESS,
            role: Role.FORM_OF_ADDRESS
        },
        {
            title: i18n.t('home.planner.gender'),
            link: Routes.GENDER,
            role: Role.GENDER
        }
    ]

    const header = useCallback(
        (text: string) => (
            <Typography.Text code={true} style={styles.titleText}>
                {text}
            </Typography.Text>
        ),
        []
    )

    const generateModules = useCallback((coreModules: Module[]) => {
        return coreModules.map(core => (
            <Secured key={core.title} role={core.role}>
                <CardMenu title={core.title} link={core.link} />
            </Secured>
        ))
    }, [])
    return (
        <div>
            <div style={styles.listWrapper}>
                <div style={styles.title}>{header(i18n.t('home.pharma.title'))}</div>
                <div style={{display: 'flex'}}>{generateModules(pharmaModules1)}</div>
                <div style={{display: 'flex'}}>{generateModules(pharmaModules2)}</div>
            </div>

            <div style={styles.listWrapper}>
                <div style={styles.title}>{header(i18n.t('home.hcp.title'))}</div>
                <div style={{display: 'flex'}}>{generateModules(hcpModules)}</div>
            </div>
            <div style={styles.listWrapper}>
                <div style={styles.title}>{header(i18n.t('home.planner.title'))}</div>
                <div style={{display: 'flex'}}>{generateModules(plannerModules)}</div>
                <div style={{display: 'flex'}}>{generateModules(plannerModules2)}</div>
            </div>
            <div style={styles.listWrapper}>
                <div style={styles.title}>{header(i18n.t('home.admin.title'))}</div>
                <div style={{display: 'flex'}}>{generateModules(adminModules)}</div>
            </div>
            <div style={styles.listWrapper}>
                <div style={styles.title}>{header(i18n.t('home.abda.title'))}</div>
                <div style={{display: 'flex'}}>{generateModules(abdaModules)}</div>
            </div>
        </div>
    )
}

type Module = {
    title: string,
    link: Routes,
    role?: Role
}

export default HomePage
