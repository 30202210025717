export enum ObservationActions {
    FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE',
    FORM_RESET = 'FORM_RESET'
}

export interface FormStateInterface<T> {
    values: T
    errors: {
        [key: string]: string
    }
    formIsValid: boolean
}

export type FormStateAction<T> = {
    type: ObservationActions
    id: string
    value: any
    error?: string
    initState?: FormStateInterface<T>
}

export function formReducer<T>(state: FormStateInterface<T>, action: FormStateAction<T>): FormStateInterface<T> {
    if (action.type === ObservationActions.FORM_INPUT_UPDATE) {
        const updatedValues = {
            ...state.values,
            [action.id]: action.value
        }
        const errors = {
            ...state.errors,
            [action.id]: action.error || ''
        }

        let updatedFormIsValid = true
        for (const key in errors) {
            updatedFormIsValid = updatedFormIsValid && !errors[key]
        }
        return {
            ...state,
            values: updatedValues,
            errors,
            formIsValid: updatedFormIsValid
        }
    }

    if (action.type === ObservationActions.FORM_RESET) {
        return action.initState || state
    }

    return state
}
