import {II18nStore} from './utils/types'
import {I18nEntity, I18nType} from '../utils/types'
import {ApiMessages, II18nService} from '../service/utils/types'
import {isResponseSuccessful} from '../utils/helper'
import {makeAutoObservable} from 'mobx'

class I18nStore implements II18nStore {
    i18nService: II18nService

    constructor(i18nService: II18nService) {
        this.i18nService = i18nService
        makeAutoObservable(this)
    }

    async updateI18nEntities(entities: I18nEntity[], i18nType: I18nType, apiMes: ApiMessages | undefined): Promise<I18nEntity[] | undefined> {
        const response = await this.i18nService.updateI18nEntities(entities, i18nType, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to update ${i18nType} i18n entities, ${response}`)
            return Promise.resolve(undefined)
        }
        return Promise.resolve(response.data)
    }

    async getI18nEntities(i18nType: I18nType, apiMes?: ApiMessages): Promise<I18nEntity[] | undefined> {
        const response = await this.i18nService.getI18nEntities(i18nType, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get ${i18nType} i18n entities, ${response}`)
            return Promise.resolve(undefined)
        }
        return Promise.resolve(response.data)
    }
}

export default I18nStore
