import {i18n}  from '../../../i18n'
import {Checkbox} from 'antd'

export const generateIcd10Columns = () => [
    {
        title: i18n.t('icd10.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: i18n.t('icd10.code'),
        dataIndex: 'code',
        key: 'code',
        sorter: true,
    },
    {
        title: i18n.t('icd10.medipolisName'),
        dataIndex: 'medipolisName',
        key: 'medipolisName',
        sorter: true,
    },
    {
        title: i18n.t('icd10.active'),
        dataIndex: 'active',
        key: 'active',
        sorter: false,
        render: (active: boolean) => <Checkbox checked={active} disabled={true} />,
    },
]
