import {i18n}  from '../../../i18n'
import {PossibleValue, VitalParameterDefinition} from '../../../utils/types'
import {getDuplicateIndexes} from '../../../utils/array_helper'
import {ObservationKeys} from '../../../utils/forms/keys'

export const generateSideEffectColumns = () => [
    {
        title: i18n.t('observation.title'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
    },
    {
        title: i18n.t('observation.type'),
        dataIndex: 'parameterType',
        key: 'parameterType',
        sorter: true,
    },
    {
        title: i18n.t('observation.values'),
        dataIndex: 'possibleValues',
        key: 'possibleValues',
    },
    {
        title: i18n.t('observation.description'),
        dataIndex: 'description',
        key: 'description',
        sorter: true,
    },
]
export const generateVitalDataColumns = () => [
    {
        title: i18n.t('observation.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: i18n.t('observation.type'),
        dataIndex: 'parameterType',
        key: 'parameterType',
        sorter: true,
    },
    {
        title: i18n.t('observation.fhirCode'),
        dataIndex: 'fhirCode',
        key: 'fhirCode',
        sorter: true,
    },
    {
        title: i18n.t('observation.description'),
        dataIndex: 'description',
        key: 'description',
        sorter: true,
    },
]
/**
 * extract error messages for possible values inputs
 * @param possibleValuesErrors
 * @param possibleValuesInput
 */
export const extractPossibleValuesErrors = (
    possibleValuesErrors: Record<string, string>,
    possibleValuesInput?: PossibleValue[]
): Record<string, string> => {
    const newErrors = {...possibleValuesErrors}
    const possibleValues: PossibleValue[] = possibleValuesInput || []
    const duplicateIndexes = getDuplicateIndexes(possibleValues, it => it.value)
    const duplicateLabels = getDuplicateIndexes(possibleValues, it => it.label)

    // check for empty labels
    possibleValues.forEach((it, index) => {
        newErrors[ObservationKeys.possibleValues + index] = !it.label
            ? i18n.t('common.errors.empty')
            : duplicateIndexes.has(index)
            ? i18n.t('sideEff.errors.duplicateValue')
            : duplicateLabels.has(index)
            ? i18n.t('sideEff.errors.duplicateLabel')
            : ''
    })

    return newErrors
}

export const hasAnyValues = (newErrors: Record<string, string>) => {
    let hasErrors = false
    Object.keys(newErrors).forEach(elem => {
        hasErrors = hasErrors || !!newErrors[elem]
    })
    return hasErrors
}

/**
 * check if possible values have certain levels
 * @param possibleValues - array to look for
 * @param levels
 */
export const hasLevels = (possibleValues: PossibleValue[], levels: number[]) => {
    const hasValues: Map<number, boolean> = new Map();
    levels.forEach(level => {
        const found = possibleValues.find(item => item.value === level);
        if (found) {
            hasValues.set(level, true)
        }
    })
    return hasValues.size === levels.length;
}

export const emptyParamDef: VitalParameterDefinition = {
    max: null,
    min: null,
    unit: '',
    label: '',
    isDecimal: false,
    definitionId: '',
    parameterId: '',
    fhirCode: ''
}
