import React, {useEffect} from 'react'
import {DoctorDto, EditModalProps, OptionItem} from '../../../utils/types'
import {useForm} from '../../../utils/hooks'
import {DoctorDtoKeys, doctorsMandatoryFields, ProdIngredientCommonKeys} from '../../../utils/forms/keys'
import {buildEnumOptions, buildSelectOptions} from '../../../utils/helper'
import {DoctorTypes, SearchTypes} from '../../../utils/enums'
import {useStores} from '../../../store'
import {sharedStyles} from '../../../style/shared_styles'
import {ActionButtons, CustomSelect, Input, LiveSearch} from '../../common'
import {i18n}  from '../../../i18n'
import {styles} from '../../observations/style/Observation.style'
import {observer} from 'mobx-react'
import {useSubmit} from '../../relations/utils/hooks'
import {EMAIL_REGEX} from '../../../utils/constants'
import {useNavigate} from 'react-router-dom'

const EditDoctor: React.FC<EditDoctorProps> = observer((props: EditDoctorProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler, isDataChanged] = useForm(props.data, doctorsMandatoryFields, [
        DoctorDtoKeys.praxis
    ])

    const {doctorStore, profileStore} = useStores()

    const doctorTypeOptions: OptionItem[] = buildEnumOptions(DoctorTypes, 'common.doctor.type')

    const doctorTitleOptions: OptionItem[] = buildSelectOptions(profileStore.titles)
    const {loading, onDataSubmit} = useSubmit(doctorStore, onValidateInputs, formState)

    const onSubmit = async () => {
        const isSuccess: boolean = await onDataSubmit()

        if (!props.data && isSuccess) {
            props.onCloseModal?.()
        }
    }

    const navigate = useNavigate()

    useEffect(() => {
        if (!props.data) {
            inputChangeHandler(DoctorTypes.DOCTOR, DoctorDtoKeys.doctorType)
            inputChangeHandler(doctorTitleOptions[0] ? doctorTitleOptions[0].value : '', DoctorDtoKeys.title)
        }
    }, [])

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn}}>
                    <CustomSelect label={i18n.t('doctor.title')} items={doctorTitleOptions} {...extractProps(DoctorDtoKeys.title)} />
                    <Input label={i18n.t('doctor.firstName')} {...extractProps(DoctorDtoKeys.firstName)} />
                    <Input label={i18n.t('doctor.lastName')} {...extractProps(DoctorDtoKeys.lastName)} />
                    <Input label={i18n.t('doctor.email')} regex={EMAIL_REGEX} {...extractProps(DoctorDtoKeys.email)} />
                </div>
                <div style={styles.paramColWrapper}>
                    <LiveSearch
                        label={i18n.t('doctor.praxis')}
                        searchType={SearchTypes.praxis}
                        defaultValue={formState.values.praxis}
                        {...extractProps(DoctorDtoKeys.praxis)}
                    />
                    <CustomSelect label={i18n.t('doctor.doctorType')} items={doctorTypeOptions} {...extractProps(DoctorDtoKeys.doctorType)} />
                    <Input label={i18n.t('doctor.active')} type={'checkbox'} {...extractProps(ProdIngredientCommonKeys.active)} />
                </div>
            </div>
            <ActionButtons
                onSave={onSubmit}
                loadingSave={loading}
                disabledSave={!isDataChanged}
                onCancel={props.data ? () => navigate(-1) : props.onCloseModal}
            />
        </div>
    )
})

interface EditDoctorProps extends EditModalProps {
    data?: DoctorDto
}

export default EditDoctor
