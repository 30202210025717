import {LocalStyleSheet} from "../../../utils/types";

export const styles: LocalStyleSheet = {
    searchRowStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    inputWrapper: {
        width:'30%',
        marginRight: '2%'
    }
}
