import {i18n} from '../../../i18n'

export const generateAbdaColumns = () => [
    {
        title: i18n.t('abda.abdaFile'),
        dataIndex: 'abdaFile',
        key: 'abdaFile',
        sorter: true,
    },
    {
        title: i18n.t('abda.validFrom'),
        dataIndex: 'validFrom',
        key: 'validFrom',
        sorter: true,
    },
    {
        title: i18n.t('abda.uploadDate'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
    },
    {
        title: i18n.t('abda.inserts'),
        dataIndex: 'inserts',
        key: 'inserts',
        sorter: true,
    },
    {
        title: i18n.t('abda.updates'),
        dataIndex: 'updates',
        key: 'updates',
        sorter: true,
    },
    {
        title: i18n.t('abda.relevantUpdates'),
        dataIndex: 'relevantUpdates',
        key: 'relevantUpdates',
        sorter: true,
    },
    {
        title: i18n.t('abda.deletes'),
        dataIndex: 'deletes',
        key: 'deletes',
        sorter: true,
    },
]
