import React, {useMemo} from 'react'
import {SideEffectTableEntryType, SideEffectType, TableProps} from '../../../utils/types'
import {Routes} from '../../../utils/routes'
import {Table} from 'antd'
import {i18n}  from '../../../i18n'
import PossibleValuesEntry from './PossibleValuesEntry'
import {generateSideEffectColumns} from '../utils/observation_helper'
import {useNavigate} from 'react-router-dom'
import {filterColumns} from '../../../utils/helper'
import {ColumnsType} from 'antd/es/table'

const SideEffectsTable: React.FC<SideEffectsTableProps> = (props: SideEffectsTableProps) => {
    const navigate = useNavigate()

    const dataSource =
        props.data?.map(sideEff => {
            return {
                key: sideEff.id,
                id: sideEff.id,
                title: sideEff.title,
                parameterType: i18n.t(`observation.paramType.${sideEff.parameterType}`),
                possibleValues: <PossibleValuesEntry sideEffect={sideEff} />,
                description: sideEff.description || ''
            }
        }) || []

    const columns: ColumnsType<SideEffectTableEntryType> = useMemo(() => {
        return filterColumns(generateSideEffectColumns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={props.loading}
            onRow={(record: SideEffectTableEntryType) => ({
                onClick: () => navigate(`${Routes.SIDE_EFFECTS}/${record.id}`)
            })}
            pagination={props.pagination}
            onChange={props.onTableChange}
        />
    )
}

interface SideEffectsTableProps extends TableProps<SideEffectType> {}

export default SideEffectsTable
