import {LocalStyleSheet} from '../../../utils/types'

export const styles: LocalStyleSheet = {
    navRight: {
        marginLeft: 'auto'
    },
    navLeft: {
        float: 'left'
    },
    navSearchWrapper: {
        float: 'right',
        display: 'flex',
        width: '35%',
        alignItems: 'center'
    },
    navSearch: {
        width: '100%'
    },
    navSearchIcon: {
        paddingRight: 10,
        paddingLeft: 10
    }
}
