import {
    IAbdaService,
    IDoctorsService,
    II18nService,
    IIcd10Service,
    IIngredientService,
    IPatientService,
    IPraxisService,
    IProductIngredientService,
    IProductService,
    IRelationsService,
    ISearchService,
    ISideEffectService,
    IUserService,
    IVitalDataService,
} from './utils/types'
import PatientService from './patient_service'
import SearchService from './search_service'
import ProductService from './product_service'
import IngredientService from './ingredient_service'
import RelationsService from './relations_service'
import {SideEffectIngredientDto, VitalDataIngredientDto} from '../utils/types'
import {EntityPathEnum} from '../utils/enums'
import VitalDataService from './vital_data_service'
import SideEffectService from './side_effects_service'
import DoctorService from './doctor_service'
import PraxisService from './praxis_service'
import Icd10Service from './icd10_service'
import UserService from './user_service'
import I18nService from './i18n_service'
import ProductIngredientService from './product_ingredient_service'
import AbdaService from "./abda_service";

export type IRootService = {
    patientService: IPatientService
    sideEffectService: ISideEffectService
    vitalDataService: IVitalDataService
    searchService: ISearchService
    productService: IProductService
    icd10Service: IIcd10Service
    ingredientService: IIngredientService
    doctorService: IDoctorsService
    praxisService: IPraxisService
    userService: IUserService
    i18nService: II18nService
    productIngService: IProductIngredientService
    sideEffectIngService: IRelationsService<SideEffectIngredientDto>
    vitalDataIngService: IRelationsService<VitalDataIngredientDto>
    abdaService: IAbdaService
}

class RootService implements IRootService {
    patientService: IPatientService
    sideEffectService: ISideEffectService
    vitalDataService: IVitalDataService
    searchService: ISearchService
    productService: IProductService
    icd10Service: IIcd10Service
    ingredientService: IIngredientService
    doctorService: IDoctorsService
    praxisService: IPraxisService
    userService: IUserService
    i18nService: II18nService
    productIngService: IProductIngredientService
    sideEffectIngService: IRelationsService<SideEffectIngredientDto>
    vitalDataIngService: IRelationsService<VitalDataIngredientDto>
    abdaService: IAbdaService

    constructor() {
        this.patientService = new PatientService()
        this.sideEffectService = new SideEffectService()
        this.vitalDataService = new VitalDataService()
        this.searchService = new SearchService()
        this.productService = new ProductService()
        this.ingredientService = new IngredientService()
        this.icd10Service = new Icd10Service()
        this.doctorService = new DoctorService()
        this.praxisService = new PraxisService()
        this.userService = new UserService()
        this.i18nService = new I18nService()
        this.productIngService = new ProductIngredientService()
        this.sideEffectIngService = new RelationsService<SideEffectIngredientDto>(EntityPathEnum.SIDE_EFFECT_INGREDIENT)
        this.vitalDataIngService = new RelationsService<VitalDataIngredientDto>(EntityPathEnum.VITAL_DATA_INGREDIENT)
        this.abdaService = new AbdaService()
    }
}

export default RootService
