import React, {useState} from 'react'
import {observer} from 'mobx-react'
import {useStores} from '../../store'
import {ErrorComponent, FullLoader} from '../common'
import {i18n}  from '../../i18n'
import EditProduct from './components/EditProduct'
import ListPageLayout from '../common/layout/ListPageLayout'
import ProductsTable from './components/ProductsTable'
import {AnyObj, SearchDto} from '../../utils/types'
import SearchComponent from '../common/SearchComponent'
import {FileExtension, SearchComponentType, SearchTypes} from '../../utils/enums'
import {usePagination} from '../../utils/hooks/usePagination'
import {TablePaginationConfig} from 'antd'

const ProductPage = observer(() => {
    const {productStore} = useStores()
    const [searchList, setSearchList] = useState<AnyObj>({})
    const [sortField, setSortField] = useState<string>()
    const [sortOrder, setSortOrder] = useState<string>()
    const [loading, paginationConfig] = usePagination(productStore, searchList, sortField, sortOrder)

    if (productStore.dataList === null) {
        return <FullLoader />
    }

    if (productStore.dataList === undefined) {
        return <ErrorComponent status={404} />
    }

    const searchParams: SearchDto[] = [
        {
            type: SearchComponentType.INPUT,
            searchParam: 'pzn',
            placeholder: i18n.t('product.pzn'),
        },
        {
            type: SearchComponentType.INPUT,
            searchParam: 'productName',
            placeholder: i18n.t('product.productName'),
        },
        {
            type: SearchComponentType.MULTI_LIVE_SEARCH,
            searchParam: 'ingredientId',
            placeholder: i18n.t('product.ingredient'),
            searchType: SearchTypes.ingredient,
        },
    ]

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSortField(sorter.field)
        setSortOrder(sorter.order)
    }

    return (
        <ListPageLayout
            title={i18n.t('product.pageTitle')}
            createModalTitle={i18n.t('product.create')}
            createModal={EditProduct}
            showUploadButton={true}
            draggerProps={{store: productStore, acceptedExtensions: [FileExtension.CSV]}}>
            <SearchComponent searchParams={searchParams} onChangeFilter={setSearchList} />
            <ProductsTable data={productStore.dataList} loading={loading} pagination={paginationConfig} onTableChange={handleTableChange} />
        </ListPageLayout>
    )
})

export default ProductPage
