import React from 'react'
import {useParams} from 'react-router'
import {useStores} from '../../../store'
import {DetailsPageHeader, ErrorComponent, FullLoader} from '../../common'
import {i18n}  from '../../../i18n'
import {Tabs} from 'antd'
import {observer} from 'mobx-react'
import EditIngredient from './EditIngredient'
import {useSimpleGetDelete} from '../../relations/utils/hooks'
import GenericTable from '../../tabs/GenericTable'
import VitalDataIngTable from '../../relations/vital-data-ingredient/VitalDataIngTable'
import SideEffectIngTable from '../../relations/side-effect-ingredient/SideEffectIngTable'

const IngredientDetails = observer(() => {
    const {ingredientId} = useParams<{ingredientId: string}>()

    const {ingredientStore} = useStores()
    const {loading, loadingDelete, currentData, onDelete} = useSimpleGetDelete(ingredientId, ingredientStore)

    if (loading) {
        return <FullLoader />
    }

    if (!currentData) {
        return <ErrorComponent status={404} title={i18n.t('ingredient.errors.notFound')} />
    }

    return (
        <>
            <DetailsPageHeader
                title={currentData.name}
                onDelete={onDelete}
                messageDelete={i18n.t('ingredient.messages.delete')}
                loadingDelete={loadingDelete}
                data={currentData}
                footer={
                    <Tabs defaultActiveKey='details'>
                        <Tabs.TabPane tab={i18n.t('tabs.details.title')} key='details'>
                            <EditIngredient ingredient={currentData} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={i18n.t('tabs.vitalData.title')} key='vitalData'>
                            <GenericTable
                                getDataServer={async () => ingredientStore.getVitalDataForIngredient(currentData)}
                                dataTable={VitalDataIngTable}
                                extraProps={{ingredient: currentData}}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={i18n.t('tabs.sideEffect.title')} key='sideEffect'>
                            <GenericTable
                                getDataServer={async () => ingredientStore.getSideEffectForIngredient(currentData)}
                                dataTable={SideEffectIngTable}
                                extraProps={{ingredient: currentData}}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                }
            />
        </>
    )
})

export default IngredientDetails
