import React from 'react'
import {sharedStyles} from '../../style/shared_styles'
import {i18n} from '../../i18n'
import {Color} from '../../style/colors'
import {ButtonStyle} from '../../style/button'
import {Button} from 'antd'

const ErrorModal: React.FC<ErrorModalProps> = (props: ErrorModalProps) => {
    return (
        <div style={{...sharedStyles.column, alignItems: 'center'}}>
            <ul>
                {props.errorMessages.map(msg => (
                    <li key={msg} style={{color: Color.warningStrong, fontSize: 22}}>
                        {msg}
                    </li>
                ))}
            </ul>
            <Button type={'default'} shape={'round'} style={{...ButtonStyle.primary, marginTop: 25}} onClick={props.onCancel}>
                {i18n.t('button.cancel')}
            </Button>
        </div>
    )
}

type ErrorModalProps = {
    errorMessages: string[]
    onCancel: () => void
}

export default ErrorModal
