import React, {useCallback, useEffect, useMemo, useReducer} from 'react'
import {areArraysEqualsWithExclude, areObjectsEqualsWithExclude, isBoolean, isNumber, isValidDate} from '../helper'
import {formReducer, FormStateAction, FormStateInterface, ObservationActions} from '../forms/formReducer'
import {i18n} from '../../i18n'

export const useForm = <T>(
    initialValue?: Partial<T>,
    mandatoryFields?: (keyof T & string)[],
    checkExtraFields?: (keyof T & string)[] /*by default all primitive fields are checked if modified, if want to check object fields add them here*/
): [
    FormStateInterface<Partial<T>>,
    (key: keyof T & string) => {
        value: any
        error: string
        mandatory: boolean
        onValueChanged: (val: any, error?: string) => void
    },
    () => boolean,
    (value: any, id: string, error?: string) => void,
    boolean
] => {
    const [formState, dispatchFormState] = useReducer<React.Reducer<FormStateInterface<Partial<T>>, FormStateAction<Partial<T>>>>(formReducer, {
        values: initialValue || {},
        errors: {} as any,
        formIsValid: true
    })

    /**
     * if the initial value is changed, update the formState
     */
    useEffect(() => {
        if (initialValue && !areObjectsEqualsWithExclude(initialValue, formState.values)) {
            dispatchFormState({
                type: ObservationActions.FORM_RESET,
                initState: {
                    values: initialValue,
                    errors: {},
                    formIsValid: true
                },
                id: '',
                value: ''
            })
        }
    }, [initialValue])

    const inputChangeHandler = useCallback(
        (value: any, id: string, error?: string) => {
            dispatchFormState({
                type: ObservationActions.FORM_INPUT_UPDATE,
                value,
                id,
                error
            })
        },
        [dispatchFormState]
    )

    const extractProps = useCallback(
        (key: keyof T & string) => {
            let value = formState.values[key] as any
            const isDateValue = isValidDate(value)
            value = !isDateValue && isNumber(value) && !Number.isInteger(value) && !isBoolean(value) ? (value as number).toLocaleString(i18n.locale) : value
            return {
                id: key,
                value: value,
                error: formState.errors[key],
                mandatory: isFieldMandatory(key),
                onValueChanged: (val: any, error?: string) => {
                    inputChangeHandler(val, key, error)
                }
            }
        },
        [formState]
    )

    const onValidateInputs = (): boolean => {
        let isValid = true

        mandatoryFields?.forEach(field => {
            const value = formState.values[field]
            if (!value || (Array.isArray(value) && value.length === 0)) {
                inputChangeHandler(value, field, i18n.t('common.errors.empty'))
                isValid = false
            }
        })
        return isValid && formState.formIsValid
    }

    const isFieldMandatory = (key: keyof T & string) => {
        if (!mandatoryFields) {
            return false
        }
        return mandatoryFields.includes(key)
    }

    const isDataChanged = useMemo(() => {
        if (!initialValue) {
            return true
        }
        let extraFieldsChanged = false
        checkExtraFields?.forEach(key => {
            const isArray = Array.isArray(initialValue[key]) && Array.isArray(formState.values[key])
            extraFieldsChanged =
                extraFieldsChanged ||
                (isArray
                    ? !areArraysEqualsWithExclude(initialValue[key] as any, formState.values[key] as any)
                    : !areObjectsEqualsWithExclude(initialValue[key], formState.values[key]))
        })
        return extraFieldsChanged || !areObjectsEqualsWithExclude(initialValue, formState.values)
    }, [initialValue, formState.values])

    return [formState, extractProps, onValidateInputs, inputChangeHandler, isDataChanged]
}
