import CrudService from './crud_service'
import {DoctorDto} from '../utils/types'
import {IDoctorsService} from './utils/types'
import {EntityPathEnum} from '../utils/enums'

class DoctorService extends CrudService<DoctorDto> implements IDoctorsService {
    constructor() {
        super(EntityPathEnum.DOCTOR)
    }
    // add additional methods
}


export default DoctorService;
