import {AddressInfo, ChangePassDto, ProfileDto} from "./types";

export enum EditAddressKey {
    country = 'country',
    street = 'street',
    postalCode = 'postalCode',
    city = 'city',
    state = 'state'
}

export enum EditProfileKey {
    firstName = 'firstName',
    lastName = 'lastName',
    gender = 'gender',
    eMail = 'eMail',
    telephone = 'telephone',
    mobilePhone = 'mobilePhone'
}

export enum ChangePassKey {
    oldPassword = 'oldPassword',
    newPassword = 'newPassword',
    confirmNewPass = 'confirmNewPass'
}

export const profileMandatoryFields: (keyof ProfileDto & string)[] = [EditProfileKey.eMail, EditProfileKey.firstName, EditProfileKey.lastName]
export const changePassMandatoryFields: (keyof ChangePassDto & string)[] = [ChangePassKey.oldPassword, ChangePassKey.newPassword, ChangePassKey.confirmNewPass]
export const addressMandatoryFields: (keyof AddressInfo & string)[] = [EditAddressKey.street, EditAddressKey.postalCode]
