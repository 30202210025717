import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useStores} from '../../store'
import {ErrorComponent, FullLoader} from '../common'
import {i18n}  from '../../i18n'
import {generateVitalDataColumns} from '../side_effects/utils/observation_helper'
import {Table, TablePaginationConfig} from 'antd'
import {Routes} from '../../utils/routes'
import {observer} from 'mobx-react'
import EditVitalData from './components/EditVitalData'
import ListPageLayout from '../common/layout/ListPageLayout'
import {usePagination} from '../../utils/hooks/usePagination'
import {AnyObj, SearchDto, VitalDataTableEntryType} from '../../utils/types'
import {SearchComponentType} from '../../utils/enums'
import SearchComponent from '../common/SearchComponent'

const VitalDataPage = observer(() => {
    const {vitalDataStore} = useStores()
    const [searchList, setSearchList] = useState<AnyObj>({})
    const [sortField, setSortField] = useState<string>()
    const [sortOrder, setSortOrder] = useState<string>()
    const [loading, paginationConfig] = usePagination(vitalDataStore, searchList, sortField, sortOrder)
    const navigate = useNavigate()

    if (vitalDataStore.dataList === null) {
        return <FullLoader />
    }

    if (vitalDataStore.dataList === undefined) {
        return <ErrorComponent status={404} />
    }

    const searchParams: SearchDto[] = [
        {
            type: SearchComponentType.INPUT,
            searchParam: 'vitalDataName',
            placeholder: i18n.t('vitalData.name')
        }
    ]

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSortField(sorter.field)
        setSortOrder(sorter.order)
    }

    const dataSource: VitalDataTableEntryType[] = vitalDataStore.dataList.map(vital => {
        return {
            key: vital.id,
            id: vital.id,
            name: vital.name,
            parameterType: i18n.t(`observation.paramType.${vital.parameterType}`),
            fhirCode: vital.fhirCode || '',
            description: vital.description || ''
        }
    })

    const columns = generateVitalDataColumns()

    return (
        <ListPageLayout title={i18n.t('vitalData.pageTitle')} createModalTitle={i18n.t('vitalData.create')} createModal={EditVitalData}>
            <SearchComponent searchParams={searchParams} onChangeFilter={setSearchList} />
            <Table
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                onRow={(record: VitalDataTableEntryType) => ({
                    onClick: () => navigate(`${Routes.VITAL_DATA}/${record.id}`)
                })}
                pagination={paginationConfig}
                onChange={handleTableChange}
            />
        </ListPageLayout>
    )
})

export default VitalDataPage
