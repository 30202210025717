import React from 'react'
import {TitleText} from '../../common'
import {Card} from 'antd'
import {Routes} from '../../../utils/routes'
import {
    AlertOutlined,
    BgColorsOutlined,
    ContactsOutlined,
    DisconnectOutlined,
    ExperimentOutlined,
    HddOutlined,
    MedicineBoxOutlined,
    NodeIndexOutlined,
    PartitionOutlined,
    ProfileOutlined,
    SkinOutlined,
    SnippetsOutlined,
    SolutionOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    UserSwitchOutlined,
    WarningOutlined
} from '@ant-design/icons'
import {Color} from '../../../style/colors'
import {styles} from '../CardMenu.style'
import {useNavigate} from 'react-router-dom'

const CardMenu: React.FC<CardMenuProps> = (props: CardMenuProps) => {
    const navigate = useNavigate()
    const Icon = extractIcon(props.link)
    return (
        <Card
            hoverable={true}
            style={styles.card}
            onClick={() => navigate(props.link)}
            cover={<Icon style={{fontSize: '400%'}} color={Color.active} />}
            bodyStyle={styles.body}>
            <div style={{textAlign: 'center'}}>
                <TitleText text={props.title} noUpperCase={true} color={Color.neutral5} />
            </div>
        </Card>
    )
}

const extractIcon = (link: Routes): React.ComponentType<any> => {
    switch (link) {
        case Routes.SIDE_EFFECTS:
            return WarningOutlined
        case Routes.VITAL_DATA:
            return BgColorsOutlined
        case Routes.INGREDIENTS:
            return MedicineBoxOutlined
        case Routes.PRODUCTS:
            return ExperimentOutlined
        case Routes.DOCTORS:
            return ContactsOutlined
        case Routes.ICD10:
            return ProfileOutlined
        case Routes.PRAXIS:
            return SolutionOutlined
        case Routes.PRODUCT_INGREDIENT:
            return PartitionOutlined
        case Routes.USER:
            return UserOutlined
        case Routes.THERAPY_NAMES:
            return SnippetsOutlined
        case Routes.TITLE:
            return UsergroupAddOutlined
        case Routes.UNIT_MEASURE:
            return DisconnectOutlined
        case Routes.DOSAGE_FORMS:
            return HddOutlined
        case Routes.FORM_OF_ADDRESS:
            return UserSwitchOutlined
        case Routes.GENDER:
            return SkinOutlined
        case Routes.FEDERAL_STATE:
            return NodeIndexOutlined
        default:
            return AlertOutlined
    }
}

type CardMenuProps = {
    title: string
    link: Routes
}

export default CardMenu
