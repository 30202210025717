import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Header} from 'antd/es/layout/layout'
import {Menu, Spin} from 'antd'
import {CaretDownFilled, HomeFilled} from '@ant-design/icons'
import {Routes} from '../../utils/routes'
import SubMenu from 'antd/es/menu/SubMenu'
import {i18n} from '../../i18n'
import {observer} from 'mobx-react'
import {useStores} from '../../store'
import {styles} from './styles/AppHeader.style'

const AppHeader: React.FC = observer(() => {
    const {profileStore} = useStores()

    const navigate = useNavigate()

    return (
        <Header style={{paddingLeft: '10%'}}>
            <Menu theme='dark' mode='horizontal'>
                {/* Left */}
                <Menu.Item key={'home'} style={styles.navLeft} onClick={() => navigate(Routes.HOME)}>
                    <HomeFilled />
                </Menu.Item>

                {/* Right - the elements are reverse rendered due to the float property */}
                <SubMenu
                    key={'UserSubMenu'}
                    style={styles.navRight}
                    title={
                        <span>
                            {profileStore.userLoggedName ? profileStore.userLoggedName : <Spin />} <CaretDownFilled />
                        </span>
                    }>
                    <Menu.Item key={Routes.HOME} onClick={() => navigate(Routes.HOME)}>
                        {i18n.t('header.pages.home')}
                    </Menu.Item>
                    <Menu.Item key={Routes.PROFILE} onClick={() => navigate(Routes.PROFILE)}>
                        {i18n.t('header.pages.profile')}
                    </Menu.Item>
                    <Menu.Item key={Routes.ABOUT} onClick={() => navigate(Routes.ABOUT)}>
                        {i18n.t('header.pages.aboutUs')}
                    </Menu.Item>
                    <Menu.Item key={'logout'} onClick={() => profileStore.logout()}>
                        {i18n.t('header.pages.logout')}
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </Header>
    )
})

export default AppHeader
