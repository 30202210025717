import React from 'react';
import {Button, Result, ResultProps} from "antd";
import {i18n} from '../../../i18n'

const ErrorComponent: React.FC<ErrorComponentProps> = (props: ErrorComponentProps) => {
    return (
        <Result
            status={'500'}
            title={i18n.t('common.errors.general')}
            extra={
                !props.hideRefresh &&
                <Button type='primary' onClick={() => window.location.reload()}>
                    {i18n.t('button.refresh')}
                </Button>
            }
            {...props}
        />
    )
}

interface ErrorComponentProps extends ResultProps {
    hideRefresh?: boolean
}

export default ErrorComponent
