import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useStores} from '../../../store'
import {i18n} from '../../../i18n'
import {Button, Result} from 'antd'
import {Role} from '../../../utils/enums'

const ProtectedPage: React.FC<ProtectedPageProps> = (props: ProtectedPageProps) => {
    const {
        profileStore: {userRoles}
    } = useStores()

    const navigate = useNavigate()

    return (
        <>
            {props.role && !userRoles.includes(props.role) ? (
                <Result
                    title={i18n.t('common.errors.notAuthorized')}
                    status={'403'}
                    extra={
                        <Button type='primary' onClick={() => navigate(-1)}>
                            {i18n.t('button.back')}
                        </Button>
                    }
                />
            ) : (
                props.element
            )}
        </>
    )
}

type ProtectedPageProps = {
    role: Role
    element: any
}

export default ProtectedPage
