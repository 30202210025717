import BaseService from './base_service'
import {ApiMessages, ICRUDService} from './utils/types'
import {EntityPathEnum} from '../utils/enums'
import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {AnyObj, CommonType, PageDto, PageRequest} from '../utils/types'
import {encodeObjectValues} from '../components/common/utils/searchHelper'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class CrudService<T extends CommonType> extends BaseService implements ICRUDService<T> {
    resourcePath: EntityPathEnum

    constructor(resourcePath: EntityPathEnum) {
        super()
        this.resourcePath = resourcePath
    }

    async getAllData(searchList?: AnyObj, apiMes?: ApiMessages): Promise<AxiosResponse<T[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler(
            {
                method: 'GET',
                url: `/api/private/${this.resourcePath}/`,
                params: searchList,
                headers: {
                    ...authHeader
                }
            },
            apiMes
        )
    }

    async getPaginatedData(pageRequest: PageRequest, apiMes?: ApiMessages): Promise<AxiosResponse<PageDto<T>>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler(
            {
                method: 'GET',
                url: `/api/private/${this.resourcePath}/`,
                params: {
                    pageRequest: {
                        ...pageRequest,
                        searchList: encodeObjectValues(pageRequest.searchList)
                    }
                },
                headers: {
                    ...authHeader
                }
            },
            apiMes
        )
    }

    async getDataById(id: string, apiMes?: ApiMessages): Promise<AxiosResponse<T | undefined>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler(
            {
                method: 'GET',
                url: `/api/private/${this.resourcePath}/${id}`,
                headers: {
                    ...authHeader
                }
            },
            apiMes
        )
    }

    async createOrUpdateData(data: T, apiMes?: ApiMessages): Promise<AxiosResponse<string>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        if (data.id) {
            return this.requestHandler(
                {
                    method: 'PATCH',
                    url: `/api/private/${this.resourcePath}/`,
                    data,
                    headers: {
                        ...authHeader
                    }
                },
                apiMes
            )
        }
        return this.requestHandler(
            {
                method: 'POST',
                url: `/api/private/${this.resourcePath}/`,
                data,
                headers: {
                    ...authHeader
                }
            },
            apiMes
        )
    }

    async deleteData(id: string, apiMes?: ApiMessages): Promise<AxiosResponse<string>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler(
            {
                method: 'DELETE',
                url: `/api/private/${this.resourcePath}/${id}`,
                headers: {
                    ...authHeader
                }
            },
            apiMes
        )
    }

    get getResourcePath() {
        return this.resourcePath
    }
}

export default CrudService
