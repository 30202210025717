import React from 'react'
import {useParams} from 'react-router'
import {useStores} from '../../../store'
import {DetailsPageHeader, ErrorComponent, FullLoader} from '../../common'
import {i18n}  from '../../../i18n'
import {Tabs} from 'antd'
import GenericTable from '../../tabs/GenericTable'
import EditVitalData from './EditVitalData'
import {observer} from 'mobx-react'
import {useSimpleGetDelete} from '../../relations/utils/hooks'
import IngredientsTable from '../../ingredient/components/IngredientsTable'

const VitalDataDetails = observer(() => {
    const {vitalDataId} = useParams<{vitalDataId: string}>()

    const {vitalDataStore, ingredientStore} = useStores()
    const {loading, loadingDelete, currentData, onDelete} = useSimpleGetDelete(vitalDataId, vitalDataStore)

    if (loading) {
        return <FullLoader />
    }

    if (!currentData) {
        return <ErrorComponent status={404} title={i18n.t('vitalData.errors.notFound')} />
    }

    return (
        <>
            <DetailsPageHeader
                title={currentData.name}
                onDelete={onDelete}
                messageDelete={i18n.t('vitalData.messages.delete')}
                loadingDelete={loadingDelete}
                data={currentData}
                footer={
                    <Tabs defaultActiveKey='details'>
                        <Tabs.TabPane tab={i18n.t('tabs.details.title')} key='details'>
                            <EditVitalData vitalData={currentData} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={i18n.t('tabs.ingredients.title')} key='ingredients'>
                            <GenericTable
                                getDataServer={async () => ingredientStore.getIngredientsFoVitalData(currentData.id)}
                                skipColumns={['url']}
                                dataTable={IngredientsTable}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                }
            />
        </>
    )
})

export default VitalDataDetails
