import {LocalStyleSheet} from '../../utils/types'
import {Color} from '../../style/colors'

const radius = 20

export const styles: LocalStyleSheet = {
    card: {
        flex: 1,
        margin: '0px 5px 10px 5px',
        paddingTop: 20,
        backgroundColor: Color.neutral3,
        borderRadius: radius,
        border: `0px`,
        width: '16%',
        maxWidth: '33%',
        height: '10%'
    },
    body: {
        height: '100%',
        backgroundColor: Color.black,
        marginTop: 10,
        padding: '15px 5px 15px 5px',
        borderRadius: `0px 0px ${radius}px ${radius}px`,
    },

    listWrapper: {
        backgroundColor: Color.neutral5,
        padding: 10,
        borderRadius: 20,
        marginTop: 20,
        border: `1px solid ${Color.neutral2}`
    },

    title: {
        margin: '10px 0px 20px 0px'
    },

    titleText: {
        fontSize: 25,
        color: Color.active,
        fontWeight: 'bold'
    }
}
