import React, {useEffect, useMemo, useState} from 'react'
import {sharedStyles} from '../../../style/shared_styles'
import {ActionButtons, CustomModal, CustomSelect, ErrorModal, Input, TextAreaInput, TitleText} from '../../common'
import {EditModalProps, OptionItem, SideEffectType} from '../../../utils/types'
import {useForm, useLoading} from '../../../utils/hooks'
import {ObservationKeys, sideEffectMandatoryFields} from '../../../utils/forms/keys'
import {i18n}  from '../../../i18n'
import {ParameterType} from '../../../utils/enums'
import {areArraysEqualsWithExclude, buildEnumOptions, buildSelectOptions, deepCopyArray, generateNumberArray} from '../../../utils/helper'
import {DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {extractPossibleValuesErrors, hasAnyValues, hasLevels} from '../utils/observation_helper'
import {styles} from '../../observations/style/Observation.style'
import {useNavigate} from 'react-router-dom'

const EditSideEffect: React.FC<EditSideEffectProps> = observer((props: EditSideEffectProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler, isDataChanged] = useForm(props.sideEffect, sideEffectMandatoryFields)
    const [editLoading, setEditIsLoading] = useLoading(false)
    const [possibleValuesErrors, setPossibleValuesErrors] = useState<Record<string, string>>({})
    const [errorMessages, setErrorMessages] = useState<string[]>([])

    const parameterType: OptionItem[] = buildEnumOptions(ParameterType, 'observation.paramType')
    const possibleValueOptions: OptionItem[] = buildSelectOptions(generateNumberArray(4).map(it => it.toString()))

    const {sideEffectStore} = useStores()

    const navigate = useNavigate()

    const onSubmit = async () => {
        const newErrors = extractPossibleValuesErrors(possibleValuesErrors, formState.values.possibleValues)
        setPossibleValuesErrors(newErrors)
        const possValuesHasErrors = hasAnyValues(newErrors)

        const isValid = onValidateInputs()
        const data = formState.values as SideEffectType
        if (!isValid || !data || possValuesHasErrors) {
            return
        }

        // additional checks, need to have at least lvl 0 and lvl 2
        if (!hasLevels(data.possibleValues, [0, 2])) {
            setErrorMessages([i18n.t('sideEff.possValues.minLevels')])
            return
        }

        setEditIsLoading(true)
        await sideEffectStore.createOrUpdateData(data)
        setEditIsLoading(false)

        if (!props.sideEffect) {
            props.onCloseModal?.()
        }
    }

    useEffect(() => {
        if (!props.sideEffect) {
            inputChangeHandler(ParameterType.OPTIONAL, ObservationKeys.parameterType)
            inputChangeHandler([{label: '', value: 0}], ObservationKeys.possibleValues)
        }
        return () => {
            sideEffectStore.setCurrentData(null)
        }
    }, [])

    const onValueChange =
        (index: number) =>
        (value: string, isValueInput = true) => {
            const copy = deepCopyArray(formState.values.possibleValues || [])
            if (isValueInput) {
                copy[index].value = Number(value)
            } else {
                copy[index].label = value
            }
            inputChangeHandler(copy, ObservationKeys.possibleValues)
            deleteError(index)
        }

    const onAddPossibleValue = () => {
        const newPossibleValues = formState.values.possibleValues || []
        inputChangeHandler([...newPossibleValues, {label: '', value: 0}], ObservationKeys.possibleValues)
    }

    const onDeleteItem = (index: number) => {
        const copy = deepCopyArray(formState.values.possibleValues || [])
        copy.splice(index, 1)
        inputChangeHandler(copy, ObservationKeys.possibleValues)

        // delete errors
        deleteError(index)
    }

    const deleteError = (index: number) => {
        const newErrors = {...possibleValuesErrors}
        delete newErrors[ObservationKeys.possibleValues + index]
        setPossibleValuesErrors(newErrors)
    }
    const possibleValues = formState.values.possibleValues || []

    const isPossibleValuesChanged = useMemo(() => {
        if (props.sideEffect) {
            return !areArraysEqualsWithExclude(possibleValues, props.sideEffect.possibleValues)
        }
        return true
    }, [formState, props.sideEffect])

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={sharedStyles.leftColumn}>
                    <TitleText text={i18n.t('tabs.details.overview')} size={15} />
                    <Input label={i18n.t('observation.title')} {...extractProps(ObservationKeys.title)} />
                    <CustomSelect label={i18n.t('observation.type')} items={parameterType} {...extractProps(ObservationKeys.parameterType)} />
                    <TextAreaInput label={i18n.t('observation.description')} {...extractProps(ObservationKeys.description)} />
                </div>
                <div style={styles.paramColWrapper}>
                    <TitleText text={i18n.t('tabs.details.possValues')} size={15} />
                    {possibleValues.length === 0 && <p>{i18n.t('common.errors.noData')}</p>}

                    {possibleValues.map((se, index) => {
                        return (
                            <div key={index} style={{...styles.paramDefItem, alignItems: 'center'}}>
                                <CustomSelect
                                    label={i18n.t('sideEff.possValues.value')}
                                    wrapperStyle={{width: undefined}}
                                    items={possibleValueOptions}
                                    silentError={
                                        possibleValuesErrors[ObservationKeys.possibleValues + index] == i18n.t('sideEff.errors.duplicateValue')
                                    }
                                    onValueChanged={val => onValueChange(index)(val)}
                                    value={se.value}
                                />
                                <div style={styles.label}>
                                    <Input
                                        label={i18n.t('sideEff.possValues.label')}
                                        error={possibleValuesErrors[ObservationKeys.possibleValues + index]}
                                        discreteError={
                                            possibleValuesErrors[ObservationKeys.possibleValues + index] == i18n.t('sideEff.errors.duplicateValue')
                                        }
                                        onValueChanged={val => onValueChange(index)(val, false)}
                                        value={se.label}
                                    />
                                </div>
                                <div style={styles.delWrapper}>
                                    <DeleteOutlined style={styles.deleteIcon} onClick={() => onDeleteItem(index)} />
                                </div>
                            </div>
                        )
                    })}

                    {possibleValues.length < 4 && (
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <PlusCircleOutlined style={styles.addIcon} onClick={onAddPossibleValue} />
                        </div>
                    )}
                </div>
            </div>
            <ActionButtons
                onSave={onSubmit}
                loadingSave={editLoading}
                disabledSave={!isDataChanged && !isPossibleValuesChanged}
                onCancel={props.sideEffect ? () => navigate(-1) : props.onCloseModal}
            />
            <CustomModal visible={errorMessages.length !== 0} title={i18n.t('sideEff.possValues.errorTitle')} closable={false}>
                <ErrorModal errorMessages={errorMessages} onCancel={() => setErrorMessages([])} />
            </CustomModal>
        </div>
    )
})

interface EditSideEffectProps extends EditModalProps {
    sideEffect?: SideEffectType
}

export default EditSideEffect
