import {ApiMessages, IIngredientService} from './utils/types'
import {IngredientDto, SideEffectType, VitalDataType} from '../utils/types'
import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {EntityPathEnum} from '../utils/enums'
import UploadService from './upload_service'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class IngredientService extends UploadService<IngredientDto> implements IIngredientService {
    constructor() {
        super(EntityPathEnum.INGREDIENT)
    }

    async getIngredientsForSideEffect(id: string, apiMes?: ApiMessages): Promise<AxiosResponse<IngredientDto[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'GET',
            url: `/api/private/side-effect-ingredient/${id}`,
            headers: {
                ...authHeader
            }
        }, apiMes)
    }

    async getIngredientsForVitalData(id: string, apiMes?: ApiMessages): Promise<AxiosResponse<IngredientDto[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'GET',
            url: `/api/private/vital-data-ingredient/${id}`,
            headers: {
                ...authHeader
            }
        }, apiMes)
    }

    async getVitalDataForIngredient(id: string, apiMes?: ApiMessages): Promise<AxiosResponse<VitalDataType[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'GET',
            url: `/api/private/ingredient/${id}/vital-data`,
            headers: {
                ...authHeader
            }
        }, apiMes)
    }

    async getSideEffectForIngredient(id: string, apiMes?: ApiMessages): Promise<AxiosResponse<SideEffectType[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'GET',
            url: `/api/private/ingredient/${id}/side-effect`,
            headers: {
                ...authHeader
            }
        }, apiMes)
    }
}

export default IngredientService
