import React, {useEffect, useMemo, useState} from 'react'
import {AnyObj, SearchDto} from '../../utils/types'
import {SearchComponentType, SearchTypes} from '../../utils/enums'
import {Input} from './index'
import LiveSearch from './LiveSearch'
import MultiLiveSearch from './MultiLiveSearch'
import {styles} from './style/Search.style'
import CustomSelect from './CustomSelect'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

const SearchComponent: React.FC<SearchComponentProps> = (props: SearchComponentProps) => {
    const [searchList, setSearchList] = useState<AnyObj>({})

    useEffect(() => {
        if (!isEmpty(searchList)) {
            props.onChangeFilter(searchList)
        }
    }, [searchList])

    const handleSearchListChange = (searchParam: string, value: string) => {
        if (!isNil(value)) {
            setSearchList({...searchList, [searchParam]: value})
        }
    }

    const parsedInputs = useMemo(() => {
        return props.searchParams.map((e, index) => {
            let input: React.ReactNode
            switch (e.type) {
                case SearchComponentType.INPUT: {
                    input = <Input onValueChanged={value => handleSearchListChange(e.searchParam, value)} placeholder={e.placeholder} />
                    break
                }
                case SearchComponentType.LIVE_SEARCH: {
                    input = (
                        <LiveSearch
                            onValueChanged={value => handleSearchListChange(e.searchParam, value)}
                            searchType={e.searchType || SearchTypes.ingredient}
                            placeholder={e.placeholder}
                        />
                    )
                    break
                }
                case SearchComponentType.MULTI_LIVE_SEARCH: {
                    input = (
                        <MultiLiveSearch
                            onValueChanged={value => handleSearchListChange(e.searchParam, value.map(v => v.id).toString())}
                            searchType={e.searchType || SearchTypes.ingredient}
                            placeholder={e.placeholder}
                        />
                    )
                    break
                }
                case SearchComponentType.CUSTOM_SELECT: {
                    input = (
                        <CustomSelect
                            items={e.selectItems || []}
                            onValueChanged={value => handleSearchListChange(e.searchParam, value)}
                            nullOption={true}
                            placeholder={e.placeholder}
                        />
                    )
                    break
                }
            }
            return (
                <div key={index} style={styles.inputWrapper}>
                    {input}
                </div>
            )
        })
    }, [props.searchParams])

    const groupInputs = useMemo(() => {
        const groupedItems = []
        parsedInputs.forEach((e, i) => {
            if ((i + 1) % 3 === 0) {
                groupedItems.push(
                    <div key={i} style={styles.searchRowStyle}>
                        {parsedInputs.slice(i - 2, i + 1)}
                    </div>
                )
            }
        })
        groupedItems.push(
            <div key={'other'} style={styles.searchRowStyle}>
                {parsedInputs.slice(parsedInputs.length - (parsedInputs.length % 3), parsedInputs.length)}
            </div>
        )
        return groupedItems
    }, [parsedInputs])

    return <div>{groupInputs.map(e => e)}</div>
}

type SearchComponentProps = {
    searchParams: SearchDto[]
    onChangeFilter: (searchList: AnyObj) => void
}

export default SearchComponent
