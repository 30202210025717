import React from 'react';
import {Color} from '../../style/colors'

const TitleText: React.FC<TitleTextProps> = (props: TitleTextProps) => {
    return (
        <span style={{color: props.color || Color.active , fontSize: props.size || 18, fontWeight: 'bold'}}>
            {props.noUpperCase ? props.text : props.text.toUpperCase()}
        </span>
    )
}

type TitleTextProps = {
    text: string,
    size?: number,
    color?: string
    noUpperCase?: boolean
}

export default TitleText
