import React from 'react';
import {SideEffectType} from "../../../utils/types";

const PossibleValuesEntry: React.FC<PossibleValuesEntryProps> = (props: PossibleValuesEntryProps) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 30}}>
            {props.sideEffect.possibleValues.map(item => {
                return <span key={props.sideEffect.title + item.value}>{`${item.value} - ${item.label}`}</span>
            })}
        </div>
    )
}

type PossibleValuesEntryProps = {
    sideEffect: SideEffectType
}

export default PossibleValuesEntry
