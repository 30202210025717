import React from 'react';
import {Color} from '../../style/colors'

const ErrorField: React.FC<ErrorFieldProps> = (props: ErrorFieldProps) => {
    if (props.hideErrorField) {
        return <></>
    }
    return (
        <span
            style={{
                color: Color.warningStrong,
                fontSize: 13,
                visibility: props.error ? 'visible' : 'hidden'
            }}>
                {props.error || '-'}
            </span>
    )
}

export interface ErrorFieldProps {
    discreteError?: boolean,
    silentError?: boolean,
    error?: string,
    hideErrorField?: boolean
}

export default ErrorField
