import {IProfileStore} from './utils/types'
import Keycloak, {KeycloakProfile} from 'keycloak-js'
import {autorun, IReactionDisposer, makeAutoObservable} from 'mobx'
import {IPatientService} from '../service/utils/types'
import {ConfigTypes, Role} from '../utils/enums'
import {AxiosResponse} from 'axios'
import {isObject, isResponseSuccessful} from '../utils/helper'
import {AnyObj, ChangePassDto, ProfileDto} from '../utils/types'
import {TOKEN_MIN_VALIDITY} from '../utils/constants'

class ProfileStore implements IProfileStore {
    keycloak: Keycloak.KeycloakInstance | null = null
    keycloakUserInfo: KeycloakProfile | null = null
    userConfigs: Map<ConfigTypes, any> = new Map<ConfigTypes, any>()
    userProfile: ProfileDto | null = null // user data from server

    autorunCleaners: IReactionDisposer[] = []
    patientService: IPatientService

    constructor(patientService: IPatientService) {
        makeAutoObservable(this, {
            getUserConfigsServer: false,
            getUserDataServer: false,
        })
        this.patientService = patientService
    }

    initialize(): void {
        const disposerKeycloakUser = autorun(this.getUserDataServer)
        const disposerUserConfigs = autorun(this.getUserConfigsServer)
        this.autorunCleaners = [disposerKeycloakUser, disposerUserConfigs]
    }

    async logout() {
        this.keycloak?.logout()
    }

    get userLoggedName() {
        return this.keycloakUserInfo ? this.keycloakUserInfo.firstName + ' ' + this.keycloakUserInfo.lastName : ''
    }

    get userRoles() {
        if (!this.keycloak) {
            return []
        }
        // todo: extract correct roles either from keycloak token or config
        return Object.keys(Role) as Role[]
        // return [Role.SE, Role.INGR, Role.INGR_SE]
    }

    get languages(): string[] {
        return this.userConfigs.get(ConfigTypes.LANG) || []
    }

    get titles(): string[] {
        return this.userConfigs.get(ConfigTypes.TITLE) || []
    }

    get dosageForms(): AnyObj {
        return this.userConfigs.get(ConfigTypes.DOSAGE_FORMS) || {}
    }

    get unitOfMeasures(): AnyObj {
        return this.userConfigs.get(ConfigTypes.UNIT_MEASURE) || {}
    }

    /**
     * Used to get i18n text based on i18n configs key.
     * @param configType the config type
     * @param key the value of the key
     */
    getI18nText(configType: ConfigTypes, key: string): string {
        const i18nValues = this.userConfigs.get(configType)
        if (isObject(i18nValues)) {
            return i18nValues[key] || key
        }
        return key
    }

    get federalStates(): string[] {
        return []
    }

    // setters
    setKeycloak(instance: Keycloak.KeycloakInstance | null) {
        this.keycloak = instance
    }

    setUserConfigs(configs: Map<any, string[]>) {
        this.userConfigs = configs
    }

    setUserProfile(profile: ProfileDto | null) {
        this.userProfile = profile
    }

    setKeycloakUserInfo(user: KeycloakProfile | null) {
        this.keycloakUserInfo = user
    }

    // get data from server
    getUserConfigsServer = async () => {
        if (!this.keycloak) {
            return
        }
        const response: AxiosResponse<Record<string, string[]>> = await this.patientService.getUserConfigs()
        if (!isResponseSuccessful(response)) {
            console.error('Failed to get user configs')
            return
        }
        this.setUserConfigs(new Map(Object.entries(response.data)))
    }

    getConfig = (config : ConfigTypes) : any => {
        return this.userConfigs.get(config);
    }

    // todo: fetch this from our backend when there is a proper api
    getUserDataServer = async () => {
        if (!this.keycloak) {
            return
        }
        this.keycloak.loadUserProfile().then((userInfo) => {
            this.setKeycloakUserInfo(userInfo)
            this.setUserProfile({
                id: userInfo.id || '',
                firstName: userInfo.firstName || '',
                lastName: userInfo.lastName || '',
                gender: '',
                eMail: userInfo.email || '',
            })
        })
    }

    async updateProfile(profile: ProfileDto): Promise<boolean> {
        const response: AxiosResponse<void> = await this.patientService.updateProfile(profile)
        if (!isResponseSuccessful(response)) {
            console.error('Failed to update profile')
            return Promise.resolve(false)
        }
        await this.getUserDataServer()
        return Promise.resolve(true)
    }

    async changePassword(changePass: ChangePassDto): Promise<boolean> {
        const response: AxiosResponse<void> = await this.patientService.changePassword(changePass)
        if (!isResponseSuccessful(response)) {
            console.error('Failed to change password')
            return Promise.resolve(false)
        }
        this.logout()
        return Promise.resolve(true)
    }

    async cleanup() {
        this.keycloak = null
        this.autorunCleaners.forEach((disposer) => disposer())
    }

    /**
     * Update the access token if its validity time in seconds is less than TOKEN_MIN_VALIDITY.
     * The new keycloak instance will be persisted in the store.
     */
    async refreshAccessToken(): Promise<void> {
        await this.keycloak?.updateToken(TOKEN_MIN_VALIDITY).then((updated) => {
            if (updated) {
                this.setKeycloak(this.keycloak)
            }
        }).catch(error => {
            console.error('Failed to refresh token because ', error)
        })
    }
}

export default ProfileStore
