import { IUIStore } from "./utils/types";
import { makeAutoObservable } from "mobx";

class UIStore implements IUIStore {
  showLoading = false;

  constructor() {
    makeAutoObservable(this)
  }

  setShowLoading(showLoading: boolean) {
    this.showLoading = showLoading;
  }

  async cleanup() {
    this.showLoading = false
    return Promise.resolve(undefined)
  }

}

export default UIStore;
