import React, {useEffect} from 'react'
import {EditModalProps, UserDto} from '../../../utils/types'
import {useForm} from '../../../utils/hooks'
import {userMandatoryFields, UsersDtoKeys} from '../../../utils/forms/keys'
import {useStores} from '../../../store'
import {useSubmit} from '../../relations/utils/hooks'
import {sharedStyles} from '../../../style/shared_styles'
import {Input} from '../../common'
import {i18n}  from '../../../i18n'
import ActionButtons from '../../common/ActionButtons'
import {observer} from 'mobx-react'
import RoleSelection from './RoleSelection'
import {Role} from '../../../utils/enums'
import {EMAIL_REGEX} from '../../../utils/constants'
import {useNavigate} from 'react-router-dom'

const EditUser: React.FC<EditUserProps> = observer((props: EditUserProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler, isDataChanged] = useForm(props.user, userMandatoryFields, [
        UsersDtoKeys.roles
    ])
    const {usersStore} = useStores()
    const {loading, onDataSubmit} = useSubmit(usersStore, onValidateInputs, formState)
    const onSubmit = async () => {
        const isSuccess: boolean = await onDataSubmit()

        if (!props.user && isSuccess) {
            props.onCloseModal?.()
        }
    }

    const navigate = useNavigate()

    const onChangeRoleSelection = (selectedKeys: Role[]) => {
        inputChangeHandler(selectedKeys, UsersDtoKeys.roles)
    }

    useEffect(() => {
        // add default roles
        inputChangeHandler(props.user?.roles || [], UsersDtoKeys.roles)
    }, [])

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn}}>
                    <Input label={i18n.t('user.firstName')} {...extractProps(UsersDtoKeys.firstName)} />
                    <Input label={i18n.t('user.lastName')} {...extractProps(UsersDtoKeys.lastName)} />
                    <Input label={i18n.t('user.email')} regex={EMAIL_REGEX} {...extractProps(UsersDtoKeys.email)} />
                    <Input label={i18n.t('user.username')} {...extractProps(UsersDtoKeys.username)} />
                    <Input label={i18n.t('user.active')} type={'checkbox'} {...extractProps(UsersDtoKeys.active)} />
                </div>
                <div style={{...sharedStyles.rightColumn, maxWidth: ''}}>
                    <RoleSelection roles={props.user?.roles || []} onChangeSelection={onChangeRoleSelection} disabled={props.disabled} />
                </div>
            </div>
            <ActionButtons
                onSave={onSubmit}
                loadingSave={loading}
                disabledSave={!isDataChanged}
                onCancel={props.user ? () => navigate(-1) : props.onCloseModal}
            />
        </div>
    )
})

interface EditUserProps extends EditModalProps {
    user?: UserDto
    disabled?: boolean
}

export default EditUser
