import {IRelationsStore, IUIStore} from './utils/types'
import {ApiMessages, IRelationsService} from '../service/utils/types'
import {makeAutoObservable} from 'mobx'
import {isResponseSuccessful} from '../utils/helper'
import {AnyObj} from "../utils/types";

/**
 * handle ingredient relations with other entities: VitalData, SideEffects, Products
 */
class RelationsStore<T> implements IRelationsStore<T> {
    dataList: T[] | null | undefined = null /*if undefined => failed to get data from server*/

    relationService: IRelationsService<T>
    uiStore: IUIStore

    constructor(relationService: IRelationsService<T>, uiStore: IUIStore) {
        makeAutoObservable(this)
        this.relationService = relationService
        this.uiStore = uiStore
    }

    async getAllData(searchList?: AnyObj, apiMes?: ApiMessages): Promise<void> {
        const response = await this.relationService.getAllData(searchList, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get data, ${response}`)
            this.setDataList(undefined)
            return Promise.resolve()
        }
        this.setDataList(response.data)
        return Promise.resolve()
    }

    async createData(otherId: string, ingredientId: string, apiMes?: ApiMessages): Promise<boolean> {
        const response = await this.relationService.createData(otherId, ingredientId, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to create data, ${response}`)
            return Promise.resolve(false)
        }
        return Promise.resolve(true)
    }

    async deleteData(otherId: string, ingredientId: string, apiMes?: ApiMessages): Promise<boolean> {
        const response = await this.relationService.deleteData(otherId, ingredientId, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to delete data, ${response}`)
            return Promise.resolve(false)
        }
        return Promise.resolve(true)
    }

    setDataList(dataList: T[] | null | undefined) {
        this.dataList = dataList
    }
}

export default RelationsStore
