import React from 'react'
import {DoctorTypes, PackSize, ParameterType, Role, SearchComponentType, SearchTypes, VitalParameterType} from './enums'
import {TablePaginationConfig} from 'antd/lib/table/interface'

export type ConfigType = {
    dummy: string
}

export interface LocalStyleSheet {
    [key: string]: React.CSSProperties
}

// To have intellij recognize the import
export type OptionItem = any
export type Options = any

export type PatientSearchEntry = {
    id: string
    name: string
}

export type DoctorSearchEntry = {
    id: string
    name: string
    praxisId: string
    praxisName: string
}

export type ICD10SearchEntry = {
    code: string
    officialName: string
    symedisName: string
}

export type IngredientSearchDto = {
    id: string
    name: string
    note: string // used to prefill medication notes
    keySto: string
}

export type ProductSearchDto = {
    id: string // medication id
    name: string
    producer: string
    pzn: string
}

export type SideEffectSearchDto = {
    id: string
    title: string
}

export type VitalDataSearchDto = {
    id: string
    name: string
}

export type PraxisSearchDto = {
    id: string
    name: string
}

export type GenericSearch =
    | PatientSearchEntry
    | DoctorSearchEntry
    | ICD10SearchEntry
    | IngredientSearchDto
    | ProductSearchDto
    | SideEffectSearchDto
    | VitalDataSearchDto

// side effects

export type SideEffectType = {
    id: string
    title: string
    parameterType: ParameterType
    description: string | null
    possibleValues: PossibleValue[]
}
export type PossibleValue = {
    label: string
    value: number
}

export type UpdateObservationType = {
    id: string
    isSystem?: boolean
    hcpEnabled: boolean
    alarmThreshold: {
        min?: number
        max?: number
    }
}

// common

export type CommonType = {
    id: string
    createdAt?: Date
    createdBy?: string
    modifiedAt?: Date
    modifiedBy?: string
}

// vital data

export type VitalDataType = {
    name: string
    fhirCode: string
    description: string
    parameterType: VitalParameterType
    parameterDefinitions: VitalParameterDefinition[]
} & CommonType

export type VitalParameterDefinition = {
    max: number | null
    min: number | null
    unit: string
    label: string
    isDecimal: boolean
    definitionId: string
    parameterId: string
    fhirCode: string
}

// product
type ProdIngredientCommonDto = {
    name: string
    active: boolean
} & CommonType

export type ProductDto = {
    pzn: string
    packSize: PackSize
    packQuantity: string
    producer: string
    unit: string
    dosageForms: string
    pznSmallerPack: number
    pznSuccessor: number
    ingredients: IngredientDto[]
} & ProdIngredientCommonDto

// ingredients

export type IngredientDto = {
    note: string
    url: string
    keySto: string
} & ProdIngredientCommonDto

export type ObservationType = SideEffectType | VitalDataType

// product-ingredient relation

export type ProductIngredientDto = {
    product: ProductSearchDto
    ingredient: IngredientSearchDto
    dose: number
    unit: string
    rang: number
    active: boolean
    valid: boolean
} & CommonType

// sideEffects-ingredient relation

export type SideEffectIngredientDto = {
    sideEffect: SideEffectSearchDto
    ingredient: IngredientSearchDto
}

// sideEffects-ingredient relation

export type VitalDataIngredientDto = {
    vitalData: VitalDataSearchDto
    ingredient: IngredientSearchDto
}

// doctors
export type DoctorDto = {
    firstName: string
    lastName: string
    title: string
    praxis: PraxisSearchDto
    doctorType: DoctorTypes
    active: boolean
    email: string
} & CommonType

// praxis
export type PraxisDto = {
    name: string
    description: string
    doctors: DoctorDto[]
    active: boolean
} & CommonType

// profile

export type ChangePassDto = {
    oldPassword: string
    newPassword: string
    confirmNewPass: string
}

export type ProfileDto = {
    firstName: string
    lastName: string
    gender: string
    eMail: string
    // don't have values
    address?: AddressInfo
    telephone?: string
    mobilePhone?: string
} & CommonType

export type AddressInfo = {
    country: string
    street: string
    postalCode: string
    city: string
    state: string
}

// users

export type UserDto = {
    email: string
    firstName: string
    lastName: string
    username: string
    active: boolean
    roles: Role[]
} & CommonType

// planner
export type I18nEntity = {
    id: string
    type: I18nType
    key: string
    index: number
    translations: AnyObj
}

export enum I18nType {
    THERAPY_NAME = 'THERAPY_NAME',
    TITLE = 'TITLE',
    UNIT_MEASURE = 'UNIT_MEASURE',
    DOSAGE_FORMS = 'DOSAGE_FORMS',
    FORM_OF_ADDRESS = 'FORM_OF_ADDRESS',
    GENDER = 'GENDER',
    FEDERAL_STATE = 'FEDERAL_STATE'
}

// UI

export type I18nEnumPrefix =
    | 'observation.paramType'
    | 'product.packSizeType'
    | 'common.doctor.type'
    | 'common.doctor.title'
    | 'product.unitMeasureType'

export type AnyObj = {
    [key: string]: string
}

export interface EditModalProps {
    onCloseModal?: (isSuccess?: boolean) => void
}

export interface CustomActionProps {
    action: () => void
    labelKey: string
}

export interface TableProps<T> {
    data?: T[]
    loading?: boolean
    skipColumns?: (keyof T)[]
    onRefreshData?: () => void
    pagination?: TablePaginationConfig
    onTableChange?: (pagination: TablePaginationConfig, filters: any, sorter: any) => void
}

export type DoubleSelectorType = {
    key: string
    title: string
    chosen: boolean
}

// table types

interface TableCommonAttributes {
    key: string
    id: string
}

export interface SideEffectTableEntryType extends TableCommonAttributes {
    title: string
    parameterType: string
    description: string
    possibleValues: React.ReactNode
}

export interface VitalDataTableEntryType extends TableCommonAttributes {
    name: string
    parameterType: string
    fhirCode: string
    description: string
}

interface ProductIngredientCommonTable {
    name: string
}

export interface ProductTableEntryType extends TableCommonAttributes, ProductIngredientCommonTable {
    pzn: string
    packSize: string
    packQuantity: string
    producer: string
    active: boolean
}

export interface IngredientTableEntryType extends TableCommonAttributes, ProductIngredientCommonTable {
    keySto: string
    note: string
    url: string
    active: boolean
}

export interface DoctorTableEntryType extends TableCommonAttributes {
    firstName: string
    lastName: string
    title: string
    praxis: string
    doctorType: string
    active: boolean
    email: string
}

export interface PraxisTableEntryType extends TableCommonAttributes {
    name: string
    description: string
    active: boolean
}

export interface ProductIngrTableType extends TableCommonAttributes {
    product: string
    productId: string
    ingredient: string
    ingredientId: string
    dose: number | string
    unit: string
}

export interface SideEffectIngTableType extends TableCommonAttributes {
    sideEffect: SideEffectSearchDto
    ingredient: IngredientSearchDto
}

export interface VitalDataIngTableType extends TableCommonAttributes {
    vitalData: VitalDataSearchDto
    ingredient: IngredientSearchDto
}

export interface Icd10Dto {
    id: string
    externalId: string
    name: string
    code: string
    medipolisName: string
    active: boolean
}

export interface AbdaDto {
    id: string
    abdaFile: string
    validFrom: Date
    createdAt: Date
    inserts?: number
    updates?: number
    relevantUpdates?: number
    deletes?: number
}

export type UsersTableEntryTable = UserDto & TableCommonAttributes

export interface Icd10TableEntryType extends TableCommonAttributes {
    externalId: string
    name: string
    code: string
    medipolisName: string
    active: boolean
}

export interface AbdaTableEntryType extends TableCommonAttributes {
    abdaFile: string
    validFrom: string
    createdAt: string
    inserts: string
    updates: string
    relevantUpdates: string
    deletes: string
}

// same but keep different because new keys can be added
export type I18nTableEntryType = I18nEntity

export interface SearchDto {
    type: SearchComponentType
    searchParam: string
    placeholder: string
    searchType?: SearchTypes
    selectItems?: OptionItem[]
}

export type PageDto<T> = {
    dataList: T[] | null | undefined
    count: number
}

export type PageRequest = {
    page?: number
    pageSize?: number
    searchList?: AnyObj,
    sortField?: string,
    sortOrder?: string
}


