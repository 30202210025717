import CrudService from "./crud_service";
import {PraxisDto} from "../utils/types";
import {IPraxisService} from "./utils/types";
import {EntityPathEnum} from "../utils/enums";

class PraxisService extends CrudService<PraxisDto> implements IPraxisService {
    constructor() {
        super(EntityPathEnum.PRAXIS)
    }

    // add additional methods
}


export default PraxisService;
