import React, {useMemo} from 'react'
import {TableProps, UserDto, UsersTableEntryTable} from '../../../utils/types'
import {useNavigate} from 'react-router-dom'
import {filterColumns} from '../../../utils/helper'
import {Table} from 'antd'
import {Routes} from '../../../utils/routes'
import {generateUsersColumns} from '../utils/users_helper'
import {ColumnsType} from 'antd/es/table'

const UsersTable: React.FC<UsersTableProps> = (props: UsersTableProps) => {
    const navigate = useNavigate()

    const dataSource: UsersTableEntryTable[] =
        props.data?.map(pr => {
            return {
                key: pr.id,
                ...pr
            }
        }) || []

    const columns: ColumnsType<UsersTableEntryTable> = useMemo(() => {
        return filterColumns(generateUsersColumns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={props.loading}
            pagination={props.pagination}
            onRow={(record: UsersTableEntryTable) => {
                return {
                    onClick: () => navigate(`${Routes.USER}/${record.id}`)
                }
            }}
            onChange={props.onTableChange}
        />
    )
}

interface UsersTableProps extends TableProps<UserDto> {}

export default UsersTable
