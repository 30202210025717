import React, {useCallback, useState} from 'react'
import {Upload} from 'antd'
import {InboxOutlined} from '@ant-design/icons'
import {RcFile, UploadProps} from 'antd/lib/upload/interface'
import {i18n} from '../../i18n'
import {IUploadStore} from '../../store/utils/types'
import {ConfigTypes, FileExtension} from '../../utils/enums'
import {useStores} from '../../store'
import {onNotificationError} from '../../service/utils/notificationHelper'
import {formatBytes} from '../../utils/helper'
import {ActionButtons} from './index'
import {observer} from 'mobx-react'

const {Dragger} = Upload

const GenericUploadDragger = observer(<T extends unknown>(props: GenericUploadDraggerProps<T>) => {
    const [currentFile, setCurrentFile] = useState<RcFile>()
    const [uploadResult, setUploadResult] = useState<string>()
    const {profileStore} = useStores()
    const [loading, setLoading] = useState(false)

    const onSubmit = async () => {
        let uploadResponse: string | undefined
        if (currentFile) {
            setLoading(true)
            uploadResponse = await props.store.uploadFile(currentFile, {
                success: {doNotShow: true},
                error: {doNotShow: true}
            })
            setLoading(false)
        }
        if (uploadResponse) {
            setUploadResult(uploadResponse)
        } else {
            props.onCloseModal?.()
        }
    }

    const convertMaxFileSizeToNumber = useCallback((received: string): number => {
        return parseInt(received.replaceAll('\\D+', ''))
    }, [])

    const uploadProps: UploadProps = {
        name: 'file',
        accept: props.acceptedExtensions.join(','),
        onDrop(e: React.DragEvent<HTMLDivElement>) {
            console.log('Dropped files', e.dataTransfer.files)
        },
        onRemove: () => {
            setCurrentFile(undefined)
        },
        beforeUpload: (file: RcFile) => {
            const receiveSize = profileStore.getConfig(ConfigTypes.MAX_FILE_SIZE)
            const maxFileSize = receiveSize ? convertMaxFileSizeToNumber(receiveSize) : 1
            if (file.size / Math.pow(1024, 2) > maxFileSize) {
                onNotificationError({
                    description: i18n.t('upload.messages.maxFileSize', {
                        maxSize: receiveSize,
                        userSize: formatBytes(file.size)
                    })
                })
            } else {
                if (!currentFile) {
                    setCurrentFile(file)
                }
            }
            return false
        },
        fileList: currentFile ? [{uid: currentFile.uid || '', name: currentFile.name || ''}] : [],
        maxCount: 1
    }
    if (uploadResult) {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <a href={uploadResult} target='new' onClick={props.onCloseModal} style={{fontWeight: 'bold'}}>
                    {i18n.t('download.displayLinkName')}
                </a>
            </div>
        )
    }
    return (
        <div>
            <Dragger {...uploadProps}>
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                </p>
                <p className='ant-upload-text'>{i18n.t('upload.uploadInfoText')}</p>
                <p className='ant-upload-text'>{i18n.t('upload.uploadInfoSubText')}</p>
            </Dragger>

            <ActionButtons
                onSave={onSubmit}
                loadingSave={loading}
                disabledSave={!currentFile}
                onCancel={props.onCloseModal}
                saveText={i18n.t('upload.uploadButton')}
            />
        </div>
    )
})
export type GenericUploadDraggerProps<T> = {
    onCloseModal?: () => void
    store: IUploadStore<T>
    //comma separated extensions
    acceptedExtensions: FileExtension[]
}

export default GenericUploadDragger
