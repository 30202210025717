import {AbdaDto} from '../utils/types'
import {IAbdaStore, IUIStore} from './utils/types'
import {IAbdaService} from '../service/utils/types'
import UploadStore from './subStores/upload_store'
import {action, makeObservable} from "mobx";
import {isResponseSuccessful} from "../utils/helper";
import {onNotificationError, onNotificationSuccess} from "../service/utils/notificationHelper";
import {i18n} from '../i18n'
import {AxiosResponse} from "axios";

class AbdaStore extends UploadStore<AbdaDto> implements IAbdaStore {

    crudService: IAbdaService

    constructor(service: IAbdaService, uiStore: IUIStore) {
        super(service, uiStore)
        this.crudService = service;
        makeObservable(this, {export: action})
    }

    async export(): Promise<void> {
        const response: AxiosResponse<string> = await this.crudService.export()
        if (isResponseSuccessful(response)) {
            onNotificationSuccess({
                description: i18n.t('abda.export.success')
            })
        } else {
            onNotificationError({
                description: i18n.t('abda.export.fail')
            })
        }
        return Promise.resolve()
    }
}

export default AbdaStore
