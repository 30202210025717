import Tag from 'antd/lib/tag'
import {i18n}  from '../../../i18n'
import {Color} from '../../../style/colors'
import {Checkbox} from 'antd'

export const generateDoctorsColumns = () => [
    {
        title: i18n.t('doctor.lastName'),
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: true,
    },
    {
        title: i18n.t('doctor.firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: true,
    },
    {
        title: i18n.t('doctor.title'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
    },

    {
        title: i18n.t('doctor.email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true,
    },

    {
        title: i18n.t('doctor.praxis'),
        dataIndex: 'praxis',
        key: 'praxis',
        sorter: false,
        render: (praxis: string) => <Tag color={Color.active}>{praxis}</Tag>,
    },

    {
        title: i18n.t('doctor.doctorType'),
        dataIndex: 'doctorType',
        key: 'doctorType',
        sorter: true,
    },

    {
        title: i18n.t('doctor.active'),
        dataIndex: 'active',
        key: 'active',
        sorter: false,
        render: (active: boolean) => <Checkbox checked={active} disabled={true} />,
    },
]
