import {Icd10Dto} from '../utils/types'
import {IIcd10Store, IUIStore} from './utils/types'
import {IIcd10Service} from '../service/utils/types'
import UploadStore from './subStores/upload_store'

class Icd10Store extends UploadStore<Icd10Dto> implements IIcd10Store {

    constructor(service: IIcd10Service, uiStore: IUIStore) {
        super(service, uiStore)
    }
}

export default Icd10Store
