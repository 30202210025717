import React from "react";
import {useForm, useLoading} from "../../utils/hooks";
import {useStores} from "../../store";
import {i18n} from '../../i18n'
import {ActionButtons, Input} from "../common";
import {PASSWORD_REGEX} from "../../utils/constants";
import {ChangePassDto} from "../../utils/types";
import {ChangePassKey, changePassMandatoryFields} from "../../utils/keys";


const ChangePassword: React.FC<ChangePasswordProps> = (props: ChangePasswordProps) => {
    const [editLoading, setEditIsLoading] = useLoading(false)

    const {profileStore} = useStores()

    const [formState, extractProps, onValidateInputs, inputChangeHandler] = useForm(
        {
            oldPassword: '',
            newPassword: '',
            confirmNewPass: ''
        } as ChangePassDto,
        changePassMandatoryFields
    )

    const onSave = async () => {
        const isValid = onValidateInputs()
        if (!isValid) {
            return
        }

        if (formState.values.newPassword !== formState.values.confirmNewPass) {
            inputChangeHandler(formState.values.confirmNewPass, ChangePassKey.confirmNewPass, i18n.t('profile.changePass.dontMatch'))
            return
        }
        const data: ChangePassDto = formState.values as ChangePassDto

        setEditIsLoading(true)
        const isSuccess =  await profileStore.changePassword(data)
        setEditIsLoading(false)

        if (isSuccess) {
            props.closeModal()
        }
    }

    const commonProps = {
        type: 'password',
        regex: PASSWORD_REGEX
    }

    return (
        <div>
            <div>
                <p>{i18n.t('profile.changePass.rules.title')}</p>
                <ul>
                    <li>{i18n.t('profile.changePass.rules.1')}</li>
                    <li>{i18n.t('profile.changePass.rules.2')}</li>
                </ul>
            </div>

            <Input label={i18n.t('profile.changePass.currentPass')} {...commonProps} {...extractProps(ChangePassKey.oldPassword)} />
            <Input label={i18n.t('profile.changePass.newPass')} {...commonProps} {...extractProps(ChangePassKey.newPassword)} />
            <Input label={i18n.t('profile.changePass.confNewPass')} {...commonProps} {...extractProps(ChangePassKey.confirmNewPass)} />

            <ActionButtons onCancel={props.closeModal} onSave={onSave} loadingSave={editLoading} />
        </div>
    )
}

type ChangePasswordProps = {
    closeModal: () => void
}

export default ChangePassword
