import React, {useEffect} from 'react'
import {VitalParameterDefinition} from '../../../utils/types'
import {sharedStyles} from '../../../style/shared_styles'
import {i18n}  from '../../../i18n'
import {ParamDefinitionKeys, paramDefMandatoryFields} from '../../../utils/forms/keys'
import {Input} from '../../common'
import {useForm} from '../../../utils/hooks'
import {styles} from '../../observations/style/Observation.style'
import {DeleteOutlined} from '@ant-design/icons'

const EditParamDefinitions: React.FC<EditParamDefinitionsProps> = (props: EditParamDefinitionsProps) => {
    const [formState, extractProps, onValidateInputs] = useForm(props.params, paramDefMandatoryFields)

    useEffect(() => {
        props.onChangeParam(formState.values as VitalParameterDefinition)
    }, [formState])

    useEffect(() => {
        props.onSaveValidator(onValidateInputs)
    }, [onValidateInputs])

    const isUpdate = !!props.params

    const extraStyleDelete = !props.showDelete ? {color: 'transparent'} : {}

    return (
        <div style={{display: 'flex'}}>
            <div style={styles.paramDefItem}>
                <div style={sharedStyles.leftColumn}>
                    <Input
                        label={i18n.t('vitalData.paramDef.definitionId')}
                        disabled={isUpdate}
                        {...extractProps(ParamDefinitionKeys.definitionId)}
                    />
                    <Input label={i18n.t('vitalData.paramDef.min')} {...extractProps(ParamDefinitionKeys.min)} />
                    <Input label={i18n.t('vitalData.paramDef.unit')} {...extractProps(ParamDefinitionKeys.unit)} />
                    <Input label={i18n.t('vitalData.paramDef.isDecimal')} type={'checkbox'} {...extractProps(ParamDefinitionKeys.isDecimal)} />
                </div>
                <div style={sharedStyles.rightColumn}>
                    <Input label={i18n.t('vitalData.paramDef.parameterId')} disabled={isUpdate} {...extractProps(ParamDefinitionKeys.parameterId)} />
                    <Input label={i18n.t('vitalData.paramDef.max')} {...extractProps(ParamDefinitionKeys.max)} />
                    <Input label={i18n.t('vitalData.paramDef.fhirCode')} {...extractProps(ParamDefinitionKeys.fhirCode)} />
                    <Input label={i18n.t('vitalData.paramDef.label')} {...extractProps(ParamDefinitionKeys.label)} />
                </div>
            </div>
            <div style={styles.delWrapper}>
                {<DeleteOutlined style={{...styles.deleteIcon, marginTop: 0, ...extraStyleDelete}} onClick={props.onDeleteItem} />}
            </div>
        </div>
    )
}

type EditParamDefinitionsProps = {
    params: VitalParameterDefinition
    onDeleteItem: () => void
    onChangeParam: (param: VitalParameterDefinition) => void
    onSaveValidator: (validator: () => boolean) => void
    showDelete?: boolean
}

export default EditParamDefinitions
