import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {isResponseSuccessful} from '../utils/helper'
import {ApiMessages, ISearchService, SearchOptions} from './utils/types'
import {GenericSearch} from '../utils/types'
import {SearchTypes} from '../utils/enums'
import BaseService from './base_service'
import {encodeSquareBrackets} from '../components/common/utils/searchHelper'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class SearchService extends BaseService implements ISearchService {
    async genericSearch(searchStr: string, type: SearchTypes, opt?: SearchOptions, apiMes?: ApiMessages): Promise<GenericSearch[]> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()

        let filterData = opt?.filter ? `&filter=${opt?.filter.join(',')}` : ''
        filterData += type === SearchTypes.product ? `&strict=${!!opt?.strict}` : ''

        const response: AxiosResponse<GenericSearch[]> = await this.requestHandler({
            method: 'GET',
            url: `/api/private/search/${type}?searchStr=${encodeSquareBrackets(searchStr)}${filterData}`,
            headers: {
                ...authHeader
            }
        }, apiMes)
        if (!isResponseSuccessful(response)) {
            console.error(`Failed to get search values for: ${type}`)
            return Promise.reject()
        }
        return Promise.resolve(response.data)
    }
}

export default SearchService
