import {useLoading} from '../../../utils/hooks'
import {useEffect} from 'react'
import {ICRUDStore, IRelationsStore} from '../../../store/utils/types'
import {FormStateInterface} from '../../../utils/forms/formReducer'
import {ApiMessages} from '../../../service/utils/types'

/**
 * delete operation for relations
 * @param store
 * @param extractIds
 * @param apiMesDelete
 */
export const useDeleteRelations = <T>(store: IRelationsStore<T>, extractIds: (data: T) => [string, string], apiMesDelete?: ApiMessages) => {
    const [loadingDelete, setLoadingDelete] = useLoading(false)

    const onDelete = async (data: T) => {
        setLoadingDelete(true)
        const isSuccess = await store.deleteData(...extractIds(data), apiMesDelete)
        setLoadingDelete(false)
        return isSuccess
    }

    return {loadingDelete, onDelete}
}

/**
 * CRUD operations for simple entities
 * @param id
 * @param store
 * @param apiMesDelete
 * @param apiMesGet
 */
export const useSimpleGetDelete = <T>(id: string | undefined, store: ICRUDStore<T>, apiMesDelete?: ApiMessages, apiMesGet?: ApiMessages) => {
    const [loading, setLoading] = useLoading(false)
    const [loadingDelete, setLoadingDelete] = useLoading(false)

    const {currentData} = store

    useEffect(() => {
        const getDataServer = async () => {
            if (id) {
                setLoading(true)
                await store.getDataById(id, apiMesGet)
                setLoading(false)
            }
        }
        getDataServer()
    }, [id])

    const onDelete = async () => {
        if (!currentData) {
            return
        }
        setLoadingDelete(true)
        // @ts-ignore
        const isSuccess = await store.deleteData(currentData.id, apiMesDelete)
        setLoadingDelete(false)
        if (isSuccess) {
            window.history.back()
        }
    }

    return {loading, loadingDelete, currentData, onDelete}
}

/**
 * Submit data for simple entities
 * @param store
 * @param validateInput
 * @param formState
 * @param apiMes
 */
export const useSubmit = <T>(store: ICRUDStore<T>, validateInput: () => boolean, formState: FormStateInterface<Partial<T>>, apiMes?: ApiMessages) => {
    const [loading, setLoading] = useLoading(false)

    useEffect(() => {
        return () => {
            store.setCurrentData(null)
        }
    }, [])

    const onDataSubmit = async (): Promise<boolean> => {
        const isValid = validateInput()
        const data = formState.values as T

        if (!isValid || !data) {
            return false
        }

        setLoading(true)
        await store.createOrUpdateData(data, apiMes)
        setLoading(false)

        return true
    }

    return {loading, onDataSubmit}
}

/**
 * Submit data for relational entities
 * @param store
 * @param validateInput
 * @param formState
 * @param extractIds - will return [otherId, ingredientId]
 * @param apiMes
 */
export const useRelationSubmit = <T>(
    store: IRelationsStore<T>,
    validateInput: () => boolean,
    formState: FormStateInterface<Partial<T>>,
    extractIds: (value: T) => [string, string],
    apiMes?: ApiMessages
) => {
    const [loading, setLoading] = useLoading(false)

    const onDataSubmit = async () => {
        const isValid = validateInput()
        const data = formState.values as T

        if (!isValid || !data) {
            return false
        }

        setLoading(true)
        const [otherId, ingredientId] = extractIds(data)
        await store.createData(otherId, ingredientId, apiMes)
        setLoading(false)

        return true
    }

    return {loading, onDataSubmit}
}
