import CrudService from './crud_service'
import {VitalDataType} from '../utils/types'
import {IVitalDataService} from './utils/types'
import {EntityPathEnum} from '../utils/enums'

class VitalDataService extends CrudService<VitalDataType> implements IVitalDataService {
    constructor() {
        super(EntityPathEnum.VITAL_DATA)
    }
    // add additional methods
}


export default VitalDataService;
