import CrudService from './crud_service'
import {ApiMessages, IUploadService} from './utils/types'
import {EntityPathEnum} from '../utils/enums'
import {AxiosError, AxiosRequestHeaders, AxiosResponse} from 'axios'
import {RcFile} from 'antd/lib/upload/interface'
import {CommonType} from '../utils/types'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class UploadService<T extends CommonType> extends CrudService<T> implements IUploadService<T> {
    constructor(resourcePath: EntityPathEnum) {
        super(resourcePath)
    }

    async uploadFile(file: RcFile, apiMes?: ApiMessages): Promise<AxiosResponse<string>|AxiosError<{errorMessage: string, errorCode: number}>> {
        const formData = new FormData()
        formData.append('file', file)

        const authHeader: AxiosRequestHeaders = await useAuthHeader()

        return this.requestHandler(
            {
                method: 'POST',
                url: `/api/private/${this.resourcePath}/upload`,
                data: formData,
                headers: {
                    'content-type': 'multipart/form-data',
                    ...authHeader
                }
            },
            apiMes
        )
    }
}

export default UploadService
