import {IngredientDto, IngredientSearchDto, SideEffectIngredientDto, VitalDataIngredientDto} from '../utils/types'
import {ApiMessages, IIngredientService} from '../service/utils/types'
import {IIngredientStore, IUIStore} from './utils/types'
import {action, makeObservable} from 'mobx'
import {isResponseSuccessful} from '../utils/helper'
import UploadStore from './subStores/upload_store'

class IngredientStore extends UploadStore<IngredientDto> implements IIngredientStore {
    crudService: IIngredientService

    constructor(ingredientService: IIngredientService, uiStore: IUIStore) {
        super(ingredientService, uiStore)
        this.crudService = ingredientService
        makeObservable(this, {
            getIngredientsForSideEffect: action,
            getIngredientsFoVitalData: action
        })
    }

    async getIngredientsForSideEffect(id: string, apiMes?: ApiMessages): Promise<IngredientDto[] | undefined> {
        const response = await this.crudService.getIngredientsForSideEffect(id, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get ingredients for side effect, ${response}`)
            return Promise.resolve(undefined)
        }
        return Promise.resolve(response.data)
    }

    async getIngredientsFoVitalData(id: string, apiMes?: ApiMessages): Promise<IngredientDto[] | undefined> {
        const response = await this.crudService.getIngredientsForVitalData(id, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get ingredients for vital data, ${response}`)
            return Promise.resolve(undefined)
        }
        return Promise.resolve(response.data)
    }

    async getVitalDataForIngredient(ingredient: IngredientDto, apiMes?: ApiMessages): Promise<VitalDataIngredientDto[] | undefined> {
        const response = await this.crudService.getVitalDataForIngredient(ingredient.id, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get  vital data for ingredient, ${response}`)
            return Promise.resolve(undefined)
        }
        const {id, name, note, keySto} = ingredient
        const ingrSearch: IngredientSearchDto = {
            id,
            name,
            note,
            keySto
        }

        const data: VitalDataIngredientDto[] = response.data.map(it => ({
            vitalData: {
                id: it.id,
                name: it.name
            },
            ingredient: ingrSearch
        }))
        return Promise.resolve(data)
    }

    async getSideEffectForIngredient(ingredient: IngredientDto, apiMes?: ApiMessages): Promise<SideEffectIngredientDto[] | undefined> {
        const response = await this.crudService.getSideEffectForIngredient(ingredient.id, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get side effect for ingredient, ${response}`)
            return Promise.resolve(undefined)
        }
        const {id, name, note, keySto} = ingredient
        const ingrSearch: IngredientSearchDto = {
            id,
            name,
            note,
            keySto
        }

        const data: SideEffectIngredientDto[] = response.data.map(it => ({
            sideEffect: {
                id: it.id,
                title: it.title
            },
            ingredient: ingrSearch
        }))
        return Promise.resolve(data)
    }
}

export default IngredientStore
