import React, {useEffect, useMemo, useState} from 'react'
import {Empty, Select, Spin, Tag} from 'antd'
import {GenericSearch, OptionItem} from '../../utils/types'
import {convertSearchResponse} from './utils/searchHelper'
import {useServices} from '../../store'
import {SearchTypes} from '../../utils/enums'
import {Color} from '../../style/colors'
import {fromJson, toJson} from '../../utils/helper'
import {InputLabelProps} from './InputLabel'
import ErrorField, {ErrorFieldProps} from './ErrorField'
import {InputLabel} from './index'
import {sharedStyles} from '../../style/shared_styles'
import {useExceptMount, usePrevious} from '../../utils/hooks'
import {useDefaultValueSet} from './utils/useDefaultValueSet'
import {CustomTagProps} from 'rc-select/lib/BaseSelect'

const MultiLiveSearch: React.FC<MultiLiveSearchProps<any>> = props => {
    useDefaultValueSet(props.defaultValues, props.onValueChanged, [], props.skipInitialize)
    const [searchValue, setSearchValue] = useState<string>('')
    const [options, setOptions] = useState<OptionItem[]>([])
    const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>([])
    const [isPrefill, setIsPrefill] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const prevDefault = usePrevious(props.defaultValues)
    const {searchService} = useServices()

    const triggerAfterNChar: number = useMemo(() => {
        return props.searchThreshold || 2
    }, [props.searchThreshold])

    useEffect(() => {
        if (toJson(prevDefault) === toJson(props.defaultValues)) {
            // otherwise infinite loop
            return
        }
        const prefilledOptions: OptionItem[] = props.defaultValues ? convertSearchResponse(props.defaultValues, props.searchType) : []
        setIsPrefill(true)
        setSelectedOptions(prefilledOptions)
        setOptions(prefilledOptions)
    }, [props.defaultValues])

    useEffect(() => {
        if (searchValue.length >= triggerAfterNChar) {
            search()
        }
    }, [searchValue])

    const search = async () => {
        setLoadingData(true)
        const response: GenericSearch[] = await searchService.genericSearch(searchValue, props.searchType)
        const newEntries = convertSearchResponse(response, props.searchType).filter(entry => !selectedOptions.map(o => o.key).includes(entry.key))
        setOptions([...selectedOptions, ...newEntries])
        setLoadingData(false)
    }

    // avoid executing this on mount - infinite loop when prefilled
    useExceptMount(() => {
        props.onValueChanged(
            selectedOptions.map(opt => fromJson(opt.value)),
            undefined,
            isPrefill
        )
        if (isPrefill) {
            // only call it with prefill flag once
            setIsPrefill(false)
        }
    }, [selectedOptions])

    const tagRender = (tagProps: CustomTagProps) => {
        const selectedOpt = options.find(el => el.value === tagProps.value)
        if (!selectedOpt) {
            return <React.Fragment />
        }
        return (
            <Tag
                key={selectedOpt.key}
                color={Color.active}
                style={{marginRight: 3}}
                closable={!props.disabled}
                onClose={() => onTagDelete(selectedOpt)}>
                {selectedOpt.label}
            </Tag>
        )
    }

    const onTagDelete = (opt: OptionItem) => {
        setSelectedOptions(selectedOptions.filter(o => o.value !== opt.value))
        setOptions(options.filter(o => o.value !== opt.value))
        setSearchValue('')
    }

    const onClear = () => {
        setOptions([])
        setSelectedOptions([])
        setSearchValue('')
        props.onValueChanged([])
    }

    const showOptions = searchValue.length >= triggerAfterNChar
    const inputStyle = props.error ? sharedStyles.border : {}
    const {id, ...labelProps} = props

    return (
        <div>
            <InputLabel {...labelProps} />
            <Select
                id={id}
                mode='multiple'
                showSearch={true}
                showArrow={false}
                allowClear={true}
                onDeselect={(val: string, opt: OptionItem) => onTagDelete(opt)}
                dropdownMatchSelectWidth={false}
                onClear={onClear}
                placeholder={props.placeholder}
                tagRender={tagRender}
                onSearch={setSearchValue}
                onSelect={(val: string, opt: OptionItem) => {
                    setSelectedOptions([...selectedOptions, opt])
                }}
                value={selectedOptions.map(opt => opt.value)}
                style={{...inputStyle, width: '100%'}}
                notFoundContent={
                    searchValue.length >= triggerAfterNChar ? (
                        loadingData ? (
                            <div style={sharedStyles.center}>
                                <Spin />
                            </div>
                        ) : (
                            <Empty />
                        )
                    ) : null
                }
                options={showOptions ? options : undefined}
                disabled={props.disabled}
            />
            <ErrorField error={props.error} hideErrorField={props.hideErrorField} />
        </div>
    )
}

interface MultiLiveSearchProps<T> extends InputLabelProps, ErrorFieldProps {
    searchType: SearchTypes
    searchThreshold?: number
    defaultValues?: T[]
    placeholder?: string
    onValueChanged: (value: T[], error?: string, isPrefillCallback?: boolean) => void
    id?: string
    disabled?: boolean
}

export default MultiLiveSearch
