import React from 'react'
import {Checkbox, Input as AntInput, InputProps as AntInputProps} from 'antd'
import {ErrorField, InputLabel} from './index'
import {InputLabelProps} from './InputLabel'
import {sharedStyles} from '../../style/shared_styles'
import {ErrorFieldProps} from './ErrorField'
import {inputErrorMessage} from './utils/inputHelper'
import {useDefaultValueSet} from './utils/useDefaultValueSet'

const Input: React.FC<InputProps> = (props: InputProps) => {
    // need to do this because cannot pass custom attributes to html <input/> tag
    const {
        onValueChanged,
        label,
        mandatory,
        extraInfo,
        pixelAlignTop,
        pixelAlignBottom,
        hideErrorField,
        regex,
        skipInitialize,
        wrapperStyle,
        ...inputProps
    } = props
    useDefaultValueSet(props.value, onValueChanged, props.defaultValue, skipInitialize)
    const inputLabelProps: InputLabelProps = {label, mandatory, extraInfo}

    const isCheckbox = props.type === 'checkbox'

    const inputStyle = props.discreteError ? {} : props.error ? sharedStyles.border : isCheckbox ? {width: '8%'} : {}
    let divStyle: React.CSSProperties = {
        ...sharedStyles.column,
        marginTop: pixelAlignTop ? 2 : 0,
        marginBottom: pixelAlignBottom ? 2 : 0,
    }
    divStyle = wrapperStyle ? {...divStyle, ...wrapperStyle} : divStyle

    // delete error when input value is changed
    const onChange = (value: any) => {
        onValueChanged(value)
    }

    // validate input validity on blur event
    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const {value, error} = inputErrorMessage(props.value, props.mandatory, isCheckbox, props.min, props.max, regex, props.onBlurValueProcess)
        if (error || props.onBlurValueProcess) {
            onValueChanged(value, error)
        }
        props.onBlur?.(event)
    }

    const renderInput = () => {
        if (isCheckbox) {
            // @ts-ignore
            return <Checkbox{...inputProps} style={inputStyle} onChange={(e) => onChange(e.target.checked)} checked={props.value === true} />

        } else {
            return <AntInput {...inputProps} style={inputStyle} onChange={(e) => onChange(e.target.value)} onBlur={onBlur} />
        }
    }

    return (
        <div style={divStyle}>
            <InputLabel {...inputLabelProps} />
            {renderInput()}
            <ErrorField error={props.error} hideErrorField={hideErrorField} />
        </div>
    )
}

interface InputProps extends AntInputProps, InputLabelProps, ErrorFieldProps {
    onValueChanged: (val: any, error?: string) => void
    regex?: RegExp
    // inputs and selects seem to have a height difference of 2 pixels, visible when put side by side
    pixelAlignTop?: boolean
    pixelAlignBottom?: boolean
    wrapperStyle?: React.CSSProperties
    onBlurValueProcess?: (val: any) => any | undefined
}

export default Input
