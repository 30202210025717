import CrudStore from './crud_store'
import {SideEffectType} from '../utils/types'
import {ISideEffectStore, IUIStore} from './utils/types'
import {ISideEffectService} from '../service/utils/types'

class SideEffectStore extends CrudStore<SideEffectType> implements ISideEffectStore {
    constructor(service: ISideEffectService, uiStore: IUIStore) {
        super(service, uiStore)
    }
}

export default SideEffectStore
