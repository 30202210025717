import React from 'react'
import {useParams} from 'react-router'
import {useStores} from '../../../store'
import {useSimpleGetDelete} from '../../relations/utils/hooks'
import {DetailsPageHeader, ErrorComponent, FullLoader} from '../../common'
import {i18n}  from '../../../i18n'
import {Tabs} from 'antd'
import {observer} from 'mobx-react'
import EditUser from './EditUser'

const UserDetails = observer(() => {
    const {userId} = useParams<{userId: string}>()

    const {usersStore, profileStore} = useStores()
    const {loading, loadingDelete, currentData, onDelete} = useSimpleGetDelete(userId, usersStore)

    if (loading) {
        return <FullLoader />
    }

    if (!currentData) {
        return <ErrorComponent status={404} title={i18n.t('user.errors.notFound')} />
    }

    const isSameUser = currentData.email === profileStore.keycloakUserInfo?.email

    return (
        <>
            <DetailsPageHeader
                title={currentData?.email}
                onDelete={!isSameUser ? onDelete : undefined}
                messageDelete={i18n.t('user.messages.delete')}
                loadingDelete={loadingDelete}
                data={currentData}
                footer={
                    <Tabs defaultActiveKey='details'>
                        <Tabs.TabPane tab={i18n.t('tabs.details.title')} key='details'>
                            <EditUser user={currentData} disabled={isSameUser} />
                        </Tabs.TabPane>
                    </Tabs>
                }
            />
        </>
    )
})

export default UserDetails
