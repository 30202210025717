import React, {useState} from 'react'
import {useStores} from '../../store'
import {ErrorComponent, FullLoader} from '../common'
import ListPageLayout from '../common/layout/ListPageLayout'
import {i18n} from '../../i18n'
import DoctorTable from './componenents/DoctorTable'
import EditDoctor from './componenents/EditDoctor'
import {observer} from 'mobx-react'
import {usePagination} from '../../utils/hooks/usePagination'
import {TablePaginationConfig} from 'antd'
import {AnyObj, SearchDto} from "../../utils/types";
import {SearchComponentType} from "../../utils/enums";
import SearchComponent from "../common/SearchComponent";

const DoctorPage = observer(() => {
    const {doctorStore} = useStores()
    const [searchList, setSearchList] = useState<AnyObj>({})
    const [sortField, setSortField] = useState<string>()
    const [sortOrder, setSortOrder] = useState<string>()
    const [loading, paginationConfig] = usePagination(doctorStore, searchList, sortField, sortOrder)

    if (doctorStore.dataList === null) {
        return <FullLoader />
    }

    if (doctorStore.dataList === undefined) {
        return <ErrorComponent status={404} />
    }

    const searchParams: SearchDto[] = [
        {
            type: SearchComponentType.INPUT,
            searchParam: 'doctorName',
            placeholder: i18n.t('doctor.name')
        }
    ]

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSortField(sorter.field)
        setSortOrder(sorter.order)
    }

    return (
        <ListPageLayout title={i18n.t('doctor.pageTitle')} createModalTitle={i18n.t('ingredient.create')} createModal={EditDoctor}>
            <SearchComponent searchParams={searchParams} onChangeFilter={setSearchList}/>
            <DoctorTable data={doctorStore.dataList} loading={loading} pagination={paginationConfig} onTableChange={handleTableChange} />
        </ListPageLayout>
    )
})

export default DoctorPage
