// security

// todo: get the correct set
export enum Role {
    SE = 'SE',
    VD = 'VD',
    INGR = 'INGR',
    PROD = 'PROD',
    PROD_INGR = 'PROD_INGR',
    DOC = 'DOC',
    ICD10 = 'ICD10',
    PRAX = 'PRAX',
    USER = 'USER',
    TER_NAME = 'TER_NAME',
    TITLE = 'TITLE',
    UNIT_MEASURE = 'UNIT_MEASURE',
    DOSAGE_FORMS = 'DOSAGE_FORMS',
    FORM_OF_ADDRESS = 'FORM_OF_ADDRESS',
    GENDER = 'GENDER',
    FEDERAL_STATE = 'FEDERAL_STATE',
    ABDA = 'ABDA'
}

//side effects

export enum ParameterType {
    HOWDY = 'HOWDY',
    DOCTOR = 'DOCTOR',
    MANDATORY = 'MANDATORY',
    OPTIONAL = 'OPTIONAL',
    CONDITIONAL = 'CONDITIONAL',
}

// vital data

export enum VitalParameterType {
    HOWDY = 'HOWDY',
    DOCTOR = 'DOCTOR',
    MANDATORY = 'MANDATORY',
    OPTIONAL = 'OPTIONAL',
    CONDITIONAL = 'CONDITIONAL',
}

// products

export enum PackSize {
    NOT_DEFINED = 'NOT_DEFINED',
    NOT_REQUIRED = 'NOT_REQUIRED',
    NO_SUITABLE_SIZING = 'NO_SUITABLE_SIZING',
    N1 = 'N1',
    N2 = 'N2',
    N3 = 'N3',
}

// doctors

export enum DoctorTypes {
    DOCTOR = 'DOCTOR',
    NURSE = 'NURSE',
}

// configs

export enum ConfigTypes {
    LANG = 'LANG',
    TITLE = 'TITLE',
    MAX_FILE_SIZE = 'MAX_FILE_SIZE',
    DOSAGE_FORMS = 'DOSAGE_FORMS',
    UNIT_MEASURE = 'UNIT_MEASURE',
}

// only in JS
export enum SearchTypes {
    patient = 'patient',
    doctor = 'doctor',
    icd10 = 'icd10',
    ingredient = 'ingredient',
    product = 'product',
    vitalData = 'vital-data',
    sideEffects = 'side-effect',
    praxis = 'praxis',
}

export enum EntityPathEnum {
    PROD_INGREDIENT = 'product-ingredient',
    SIDE_EFFECT_INGREDIENT = 'side-effect-ingredient',
    VITAL_DATA_INGREDIENT = 'vital-data-ingredient',
    DOCTOR = 'doctor',
    INGREDIENT = 'ingredient',
    PRODUCT = 'product',
    ICD10 = 'icd10',
    SIDE_EFFECT = 'side-effect',
    VITAL_DATA = 'vital-data',
    PRAXIS = 'praxis',
    USER = 'user',
    ABDA = 'abda'
}

export enum StorageKey {
    therapyId = 'therapyId',
}

export enum SearchComponentType {
    INPUT = 'INPUT',
    LIVE_SEARCH = 'LIVE_SEARCH',
    MULTI_LIVE_SEARCH = 'MULTI_LIVE_SEARCH',
    CUSTOM_SELECT = 'CUSTOM_SELECT'
}

export enum FileExtension {
    CSV = '.csv',
    TXT = '.txt',
    ZIP = '.zip'
}
