import React, {ReactNode, useState} from "react";
import {Tooltip} from "antd";
import {EditFilled, InfoCircleOutlined} from "@ant-design/icons";
import { Color } from "../../style/colors";
import {i18n} from '../../i18n'
import {styles} from "./styles/ProfilePage.style";


const DisplayDetailsEntry: React.FC<EditDetailsEntryProps> = (props: EditDetailsEntryProps) => {
    const [iconColor, setIconColor] = useState(Color.neutral4)
    const isReactElement = React.isValidElement(props.value)

    const wrapperStyle = props.removeTopPadding ? {...styles.wrapper, paddingTop: 0} : styles.wrapper

    return (
        <div style={wrapperStyle}>
            <div>
                <span style={styles.title}>{props.title}</span>
                {props.extraInfo && (
                    <Tooltip title={props.extraInfo}>
                        <InfoCircleOutlined style={{...styles.icon, marginLeft: 5}} />
                    </Tooltip>
                )}
            </div>
            <div style={styles.valueWrapper}>
                {!isReactElement ? <span style={styles.value}>{props.value || '-'}</span> : props.value}

                {props.onEdit && (
                    <Tooltip title={i18n.t('button.edit')}>
                        <EditFilled
                            style={{...styles.icon, color: iconColor}}
                            onClick={props.onEdit}
                            onMouseOver={() => setIconColor(Color.neutral2)}
                            onMouseOut={() => setIconColor(Color.neutral4)}
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

type EditDetailsEntryProps = {
    title: string
    value: string | number | ReactNode | undefined
    onEdit?: () => void
    extraInfo?: string
    removeTopPadding?: boolean
}

export default DisplayDetailsEntry
