import CrudStore from './crud_store'
import {UserDto} from '../utils/types'
import {IUIStore, IUsersStore} from './utils/types'
import {IUserService} from '../service/utils/types'

class UsersStore extends CrudStore<UserDto> implements IUsersStore {
    constructor(service: IUserService, uiStore: IUIStore) {
        super(service, uiStore)
    }
}

export default UsersStore
