import React, {useState} from 'react'
import {useStores} from '../../../store'
import {ErrorComponent, FullLoader} from '../../common'
import ListPageLayout from '../../common/layout/ListPageLayout'
import {i18n}  from '../../../i18n'
import {observer} from 'mobx-react'
import ProdIngredientTable from './components/ProdIngredientTable'
import EditProdIngredient from './components/EditProdIngredient'
import {useNavigate} from 'react-router-dom'
import {Routes} from '../../../utils/routes'
import {usePagination} from '../../../utils/hooks/usePagination'
import {FileExtension, SearchComponentType} from '../../../utils/enums'
import {TablePaginationConfig} from 'antd'
import SearchComponent from '../../common/SearchComponent'
import {AnyObj, SearchDto} from '../../../utils/types'

const ProdIngredientPage = observer(() => {
    const {productIngStore} = useStores()
    const [searchList, setSearchList] = useState<AnyObj>({})
    const [sortField, setSortField] = useState<string>()
    const [sortOrder, setSortOrder] = useState<string>()
    const [loading, paginationConfig] = usePagination(productIngStore, searchList, sortField, sortOrder)

    const navigate = useNavigate()

    if (productIngStore.dataList === null) {
        return <FullLoader />
    }

    if (productIngStore.dataList === undefined) {
        return <ErrorComponent status={404} />
    }

    const searchParams: SearchDto[] = [
        {
            type: SearchComponentType.INPUT,
            searchParam: 'productName',
            placeholder: i18n.t('product.productName')
        }
    ]

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSortField(sorter.field)
        setSortOrder(sorter.order)
    }

    return (
        <ListPageLayout
            title={i18n.t('prodIng.pageTitle')}
            createModalTitle={i18n.t('prodIng.create')}
            createModal={EditProdIngredient}
            modalWidth={'30%'}
            onBack={() => navigate(`${Routes.HOME}`)}
            showUploadButton={true}
            draggerProps={{store: productIngStore, acceptedExtensions: [FileExtension.CSV]}}>
            <SearchComponent searchParams={searchParams} onChangeFilter={setSearchList} />
            <ProdIngredientTable data={productIngStore.dataList} loading={loading} pagination={paginationConfig} onTableChange={handleTableChange} />
        </ListPageLayout>
    )
})

export default ProdIngredientPage
