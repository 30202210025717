import {LocalStyleSheet} from '../utils/types'
import {Color} from './colors'

const activeButton = {
    color: Color.active,
    fontWeight: 'bold' as const,
    borderColor: Color.active,
    textTransform: 'uppercase' as const,
    margin: '10px 10px'
}

export const ButtonStyle: LocalStyleSheet = {
    primary: {
        ...activeButton
    },
    save: {
        ...activeButton,
        color: Color.white,
    },
    disableButton: {
        ...activeButton,
        color: Color.neutral4,
        borderColor: Color.neutral4
    },
    delete: {
        ...activeButton,
        color: Color.warningStrong,
        borderColor: Color.warningStrong,
    },

    float: {
        position: 'fixed',
        bottom: 50,
        right: 50
    }
}
