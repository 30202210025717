import React from 'react'
import {useParams} from 'react-router'
import {observer} from 'mobx-react'
import {useStores} from '../../../../store'
import {DetailsPageHeader, ErrorComponent, FullLoader} from '../../../common'
import {i18n} from '../../../../i18n'
import {Tabs} from 'antd'
import EditProdIngredient from './EditProdIngredient'
import {useSimpleGetDelete} from '../../utils/hooks'

const ProdIngredientDetails = observer(() => {
    const {productIngredientId} = useParams<{productIngredientId: string}>()

    const {productIngStore} = useStores()
    const {loading, loadingDelete, currentData, onDelete} = useSimpleGetDelete(productIngredientId, productIngStore)

    if (loading) {
        return <FullLoader />
    }

    if (!currentData) {
        return <ErrorComponent status={404} title={i18n.t('prodIng.errors.notFound')} />
    }

    return (
        <>
            <DetailsPageHeader
                title={currentData.product.name + ' - ' + currentData.ingredient.name}
                onDelete={onDelete}
                messageDelete={i18n.t('prodIng.messages.delete')}
                loadingDelete={loadingDelete}
                data={currentData}
                footer={
                    <Tabs defaultActiveKey='details'>
                        <Tabs.TabPane tab={i18n.t('tabs.details.title')} key='details'>
                            <EditProdIngredient data={currentData} />
                        </Tabs.TabPane>
                    </Tabs>
                }
            />
        </>
    )
})
export default ProdIngredientDetails
