import React from 'react'
import {Spin} from 'antd'

const FullLoader = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '25%'}}>
            <Spin size={'large'} />
        </div>
    )
}

export default FullLoader
