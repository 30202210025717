import {IProductService} from './utils/types'
import {ProductDto} from '../utils/types'
import {EntityPathEnum} from '../utils/enums'
import UploadService from './upload_service'

class ProductService extends UploadService<ProductDto> implements IProductService {
    constructor() {
        super(EntityPathEnum.PRODUCT)
    }

    // add additional methods
}

export default ProductService
