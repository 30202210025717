import {i18n}  from '../../../i18n'
import {Checkbox} from 'antd'

export const generateProductColumns = () => [
    {
        title: i18n.t('product.pzn'),
        dataIndex: 'pzn',
        key: 'pzn',
        sorter: true,
    },
    {
        title: i18n.t('prodIngCommon.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: i18n.t('product.producer'),
        dataIndex: 'producer',
        key: 'producer',
        sorter: true,
    },
    {
        title: i18n.t('product.packSize'),
        dataIndex: 'packSize',
        key: 'packSize',
        sorter: true,
    },
    {
        title: i18n.t('product.packQuantity'),
        dataIndex: 'packQuantity',
        key: 'packQuantity',
        sorter: true,
    },
    {
        title: i18n.t('prodIngCommon.active'),
        dataIndex: 'active',
        key: 'active',
        sorter: false,
        render: (active: boolean) => <Checkbox checked={active} disabled={true} />,
    },
]
