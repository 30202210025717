import React, {useEffect, useState} from 'react'
import {Button, Table} from 'antd'
import {MenuOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import {arrayMoveImmutable} from 'array-move'
import {CustomModal, ErrorComponent, FullLoader, Input, PopUpDelete} from '../../common'
import {AnyObj, I18nEntity, I18nTableEntryType, I18nType} from '../../../utils/types'
import {deepCopyArray} from '../../../utils/helper'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {styles} from '../../observations/style/Observation.style'
import NewI18nEntry from './NewI18nEntry'
import {i18n}  from '../../../i18n'
import {useLoading} from '../../../utils/hooks'
import {Color} from '../../../style/colors'
import {convertI18nEntity} from '../utils/planner_helper'
import {ButtonStyle} from '../../../style/button'
import {useNavigate} from 'react-router-dom'
import {styles as i18nStyles} from '../style/I18nCRUD.style'

const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: Color.neutral2}} />)
const SortableItem = SortableElement((props: any) => <tr {...props} />)
const SortableContainer1 = SortableContainer((props: any) => <tbody {...props} />)

const I18nCRUD: React.FC<I18nCRUDType> = observer((props: I18nCRUDType) => {
    const [dataSource, setDataSource] = useState<I18nTableEntryType[]>([])
    const [loading, setLoading] = useLoading(true)
    const [loadingEdit, setLoadingEdit] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [i18nData, setI18nData] = useState<I18nEntity[] | undefined>()

    // handle edit statement
    const [dataWasModified, setDataWasModified] = useState(false)
    const [editInputId, setEditInputId] = useState('')

    const {profileStore, i18nStore} = useStores()

    const navigate = useNavigate()

    useEffect(() => {
        const getServerData = async () => {
            setLoading(true)
            const data = await i18nStore.getI18nEntities(props.type)
            setLoading(false)
            setI18nData(data)
        }
        getServerData()
    }, [])

    useEffect(() => {
        if (i18nData) {
            setDataSource(convertI18nEntity(i18nData, profileStore.languages, props.noTranslations))
            if (editInputId) {
                document.getElementById(`#${editInputId}`)?.focus()
            }
        }
    }, [i18nData, profileStore.languages])

    const onEditTranslations = (id: string, lang: string, val: string) => {
        if (!i18nData) {
            return
        }
        // need to update both data source and original i18n entries
        const item = dataSource.find(it => it.id === id)
        if (item) {
            item.translations[lang] = val
        }

        const index = i18nData.findIndex(it => it.id === id)
        if (index !== -1) {
            i18nData[index].translations[lang] = val
        }
        setEditInputId(id + lang)
        setDataWasModified(true)
    }

    const onEditKey = (id: string, val: string) => {
        if (!i18nData) {
            return
        }
        // need to update both data source and original i18n entries
        const item = dataSource.find(it => it.id === id)
        if (item) {
            item.key = val
        }

        const index = i18nData.findIndex(it => it.id === id)
        if (index !== -1) {
            i18nData[index].key = val
        }
        setEditInputId(id)
        setDataWasModified(true)
    }

    const onDelete = (id: string) => {
        if (!i18nData) {
            return
        }
        const copy = deepCopyArray(i18nData)
        const index = copy.findIndex(it => it.id === id)
        copy.splice(index, 1)
        setI18nData(copy)

        setDataWasModified(true)
    }

    const onAddNewItem = (value: I18nEntity) => {
        if (!i18nData) {
            return
        }
        const copy = deepCopyArray(i18nData)
        copy.push(value)
        setI18nData(copy)
        setModalVisible(false)

        setDataWasModified(true)
    }

    const onSubmitData = async () => {
        setLoadingEdit(true)
        const response = await i18nStore.updateI18nEntities(dataSource, props.type, {
            error: props.failUpdate
        })
        setLoadingEdit(false)
        if (response) {
            setI18nData(response)
            setDataWasModified(false)
            await profileStore.getUserConfigsServer()
        }
    }

    const onSortEnd = (params: any) => {
        const {oldIndex, newIndex} = params
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(([] as I18nTableEntryType[]).concat(dataSource), oldIndex, newIndex)
                .filter(el => !!el)
                .map((item, index) => ({
                    ...item,
                    index
                }))
            setDataSource(newData)
            setDataWasModified(true)
        }
    }

    const columns = [
        {
            title: i18n.t('planner.sort'),
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />
        },
        {
            title: i18n.t('planner.translations'),
            dataIndex: 'translations',
            className: 'drag-visible',
            render: (translations: AnyObj, record: I18nTableEntryType) => {
                return (
                    <div>
                        <div style={i18nStyles.inputWrapper}>
                            <span style={i18nStyles.spanLabel}>{i18n.t('planner.key')}:</span>
                            <Input
                                wrapperStyle={i18nStyles.input}
                                id={record.id}
                                autoFocus={record.id === editInputId}
                                defaultValue={record.key}
                                onValueChanged={value => onEditKey(record.id, value)}
                                hideErrorField={true}
                                skipInitialize={true}
                            />
                        </div>
                        {!props.noTranslations &&
                            profileStore.languages.map(lang => (
                                <div key={record.id + lang} style={i18nStyles.inputWrapper}>
                                    <span style={i18nStyles.spanLabel}>{lang}:</span>
                                    <Input
                                        wrapperStyle={i18nStyles.input}
                                        autoFocus={record.id + lang === editInputId}
                                        id={record.id + lang}
                                        defaultValue={translations[lang]}
                                        onValueChanged={value => onEditTranslations(record.id, lang, value)}
                                        hideErrorField={true}
                                        skipInitialize={true}
                                    />
                                </div>
                            ))}
                    </div>
                )
            }
        },
        {
            title: '',
            width: 30,
            dataIndex: 'operation',
            render: (_: any, record: I18nTableEntryType) => (
                <PopUpDelete onDelete={() => onDelete(record.id)} messageDelete={props.deleteTitle} isIcon={true} />
            )
        }
    ]

    const DraggableContainer = (draggableProps: any) => (
        <SortableContainer1 useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd} {...draggableProps} />
    )

    const DraggableBodyRow = (params: any) => {
        const copyParams = {...params}
        delete copyParams['className']
        delete copyParams['style']
        // const {className, style, ...restProps} = params
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(x => x.index === copyParams['data-row-key'])
        return <SortableItem key={'sort' + index} index={index} {...copyParams} />
    }

    if (loading) {
        return <FullLoader />
    }

    if (!i18nData) {
        return <ErrorComponent status={500} title={props.failGet} />
    }

    const saveStyle = !dataWasModified ? ButtonStyle.disableButton : ButtonStyle.save

    const defaultData = {
        key: '',
        translations: {},
        id: '',
        index: dataSource.length > 0 ? dataSource.length : 0,
        type: props.type
    }

    return (
        <div style={i18nStyles.tableWrapper}>
            <div style={{flex: 4}}>
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey='index'
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow
                        }
                    }}
                />
            </div>

            <div style={styles.addIconWrapper}>
                <PlusCircleOutlined style={styles.addIcon} onClick={() => setModalVisible(true)} />
            </div>
            <Button
                type={'primary'}
                shape={'round'}
                loading={loadingEdit}
                disabled={!dataWasModified}
                style={{...saveStyle, ...ButtonStyle.float}}
                onClick={onSubmitData}>
                {i18n.t('button.save')}
            </Button>
            <Button type={'default'} shape={'round'} style={{...ButtonStyle.primary, ...ButtonStyle.float, right: 200}} onClick={() => navigate(-1)}>
                {i18n.t('button.cancel')}
            </Button>

            <CustomModal title={props.newTitle} visible={modalVisible} onCancel={() => setModalVisible(false)}>
                <NewI18nEntry
                    languages={profileStore.languages}
                    onSave={onAddNewItem}
                    defaultData={defaultData}
                    noTranslations={props.noTranslations}
                />
            </CustomModal>
        </div>
    )
})

type I18nCRUDType = {
    type: I18nType
    newTitle: string
    deleteTitle: string
    failGet: string
    failUpdate: string
    noTranslations?: boolean
}

export default I18nCRUD
