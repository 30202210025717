import React from 'react'
import {useParams} from 'react-router'
import {useStores} from '../../../store'
import {useSimpleGetDelete} from '../../relations/utils/hooks'
import {DetailsPageHeader, ErrorComponent, FullLoader} from '../../common'
import {i18n}  from '../../../i18n'
import EditIcd10 from './EditIcd10'
import {observer} from 'mobx-react'

const Icd10Details = observer(() => {
    const {icd10Id} = useParams<{icd10Id: string}>()
    const {icd10Store} = useStores()
    const {loading, loadingDelete, currentData, onDelete} = useSimpleGetDelete(icd10Id, icd10Store)

    if (loading) {
        return <FullLoader />
    }
    if (!currentData) {
        return <ErrorComponent status={404} title={i18n.t('icd10.errors.notFound')} />
    }
    return (
        <>
            <DetailsPageHeader
                title={currentData.name}
                onDelete={onDelete}
                messageDelete={i18n.t('icd10.messages.delete')}
                loadingDelete={loadingDelete}
                data={currentData}
                footer={<EditIcd10 icd10s={currentData} />}
            />
        </>
    )
})

export default Icd10Details
