import {AnyObj, I18nEntity} from '../../../utils/types'

/**
 * convert i18n entities to data needed for table data source
 * @param data
 * @param languages
 * @param noTranslations
 */
export const convertI18nEntity = (data: I18nEntity[], languages: string[], noTranslations?: boolean): I18nEntity[] => {
    if (noTranslations) {
        return data
    }

    return data.map((item, index) => {
        const {id, type, key} = item
        let translations: AnyObj = {}
        languages.forEach(lang => {
            translations = {
                ...translations,
                [lang]: item.translations[lang] || ''
            }
        })

        return {
            id,
            key,
            translations,
            index,
            type
        }
    })
}
