import CrudStore from './crud_store'
import {VitalDataType} from '../utils/types'
import {IUIStore, IVitalDataStore} from './utils/types'
import {IVitalDataService} from '../service/utils/types'

class VitalDataStore extends CrudStore<VitalDataType> implements IVitalDataStore {
    constructor(service: IVitalDataService, uiStore: IUIStore) {
        super(service, uiStore)
    }
}

export default VitalDataStore
