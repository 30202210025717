import React from 'react'
import {useParams} from 'react-router'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {useSimpleGetDelete} from '../../relations/utils/hooks'
import {DetailsPageHeader, ErrorComponent, FullLoader} from '../../common'
import {i18n}  from '../../../i18n'
import EditPraxis from './EditPraxis'
import {Tabs} from 'antd'
import GenericTable from '../../tabs/GenericTable'
import DoctorTable from '../../doctors/componenents/DoctorTable'

const PraxisDetails = observer(() => {
    const {praxisId} = useParams<{praxisId: string}>()

    const {praxisStore} = useStores()
    const {loading, loadingDelete, currentData, onDelete} = useSimpleGetDelete(praxisId, praxisStore)

    if (loading) {
        return <FullLoader />
    }

    if (!currentData) {
        return <ErrorComponent status={404} title={i18n.t('praxis.errors.notFound')} />
    }

    return (
        <>
            <DetailsPageHeader
                title={currentData?.name}
                onDelete={onDelete}
                messageDelete={i18n.t('praxis.messages.delete')}
                loadingDelete={loadingDelete}
                data={currentData}
                footer={
                    <Tabs defaultActiveKey='details'>
                        <Tabs.TabPane tab={i18n.t('tabs.details.title')} key='details'>
                            <EditPraxis praxis={currentData} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={i18n.t('tabs.doctors.title')} key='doctors'>
                            <GenericTable data={currentData.doctors} dataTable={DoctorTable} skipColumns={['praxis', 'doctorType', 'active']} />
                        </Tabs.TabPane>
                    </Tabs>
                }
            />
        </>
    )
})

export default PraxisDetails
