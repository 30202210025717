import React, {useState} from 'react'
import {useStores} from '../../store'
import {ErrorComponent, FullLoader} from '../common'
import ListPageLayout from '../common/layout/ListPageLayout'
import {i18n}  from '../../i18n'
import UsersTable from './components/UsersTable'
import {observer} from 'mobx-react'
import EditUser from './components/EditUser'
import {usePagination} from '../../utils/hooks/usePagination'
import {TablePaginationConfig} from 'antd'

const UserPage = observer(() => {
    const {usersStore} = useStores()
    const [sortField, setSortField] = useState<string>()
    const [sortOrder, setSortOrder] = useState<string>()
    const [loading, paginationConfig] = usePagination(usersStore, undefined, sortField, sortOrder)

    if (usersStore.dataList === null) {
        return <FullLoader />
    }

    if (usersStore.dataList === undefined) {
        return <ErrorComponent status={404} />
    }

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSortField(sorter.field)
        setSortOrder(sorter.order)
    }

    return (
        <ListPageLayout title={i18n.t('user.pageTitle')} modalWidth={'90%'} createModalTitle={i18n.t('user.create')} createModal={EditUser}>
            <UsersTable data={usersStore.dataList} loading={loading} pagination={paginationConfig} onTableChange={handleTableChange} />
        </ListPageLayout>
    )
})

export default UserPage
