import {LocalStyleSheet} from "../../../utils/types";
import {sharedStyles} from "../../../style/shared_styles";
import { Color } from "../../../style/colors";

export const styles: LocalStyleSheet = {
    addressLine: {
        display: 'block'
    },
    wrapper: {
        ...sharedStyles.column,
        paddingRight: 10,
        paddingTop: 20
    },
    title: {
        color: Color.neutral3,
        fontSize: 14
    },
    valueWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: Color.neutral3
    },
    value: {
        color: Color.neutral1,
        fontSize: 15
    },
    icon: {
        color: Color.neutral2,
        fontSize: '110%',
        alignSelf: 'center',
        cursor: 'pointer'
    }
}
