import {i18n}  from '../../../i18n'
import {Checkbox} from 'antd'

export const generatePraxisColumns = () => [
    {
        title: i18n.t('praxis.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: i18n.t('praxis.description'),
        dataIndex: 'description',
        key: 'description',
        sorter: true,
    },
    {
        title: i18n.t('praxis.active'),
        dataIndex: 'active',
        key: 'active',
        sorter: false,
        render: (active: boolean) => <Checkbox checked={active} disabled={true} />,
    },
]
