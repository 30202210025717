import React, {ErrorInfo} from 'react'
import ErrorComponent from './ErrorComponent'

interface ErrorBoundaryComponentProps {
    children: any
}

class ErrorBoundary extends React.Component<React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryComponentProps>>> {
    state = {error: false, errorMessage: ''}

    static getDerivedStateFromError(error: any) {
        // Update state to render the fallback UI
        return {error: true, errorMessage: error.toString()}
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log error to an error reporting service like Sentry
        console.log({error, errorInfo})
    }

    render() {
        const {error, errorMessage} = this.state
        const {children} = this.props

        if (error) {
            return <ErrorComponent subTitle={errorMessage} />
        }

        return children
    }
}

export default ErrorBoundary
