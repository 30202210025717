import {i18n} from '../../../i18n'
import {isNumber, matchRegex} from '../../../utils/helper'
import {endOfDay, isAfter, isBefore, isWithinInterval, startOfDay} from 'date-fns'

/**
 * validate input values
 * @param value - current value
 * @param mandatory - if the input is mandatory
 * @param isCheckbox - if current input is checkbox type
 * @param min - min value for number input
 * @param max - max value for number input
 * @param regex - regular expression to validate the input
 * @param onBlurValueProcess - additional processing function for value inserted
 */
export const inputErrorMessage = (
    value: any,
    mandatory: boolean | undefined,
    isCheckbox: boolean,
    min: number | string | undefined,
    max: number | string | undefined,
    regex: RegExp | undefined,
    onBlurValueProcess?: (val: any) => any | undefined
): {value: any; error: string} => {
    if (!mandatory && !value) {
        return {value, error: ''}
    }
    let errorMessage = mandatory && !isCheckbox && !value ? i18n.t('common.errors.empty') : ''

    let convertedValue = value
    // convert the value if converter is available, after that use converted value
    if (onBlurValueProcess && !errorMessage) {
        const onBlurValue = onBlurValueProcess(value)
        errorMessage = !onBlurValue ? i18n.t('common.errors.invalid') : ''
        convertedValue = onBlurValue || value
    }

    if (isNumber(min) && isNumber(max) && !errorMessage) {
        errorMessage = !(convertedValue >= min! && convertedValue <= max!)
            ? i18n.t('common.errors.interval', {
                  min,
                  max
              })
            : ''
    }

    if (isNumber(min) && !errorMessage) {
        errorMessage = !(convertedValue >= min!) ? i18n.t('common.errors.higher', {min}) : ''
    }

    if (isNumber(max) && !errorMessage) {
        errorMessage = !(convertedValue <= max!) ? i18n.t('common.errors.lower', {max}) : ''
    }

    if (regex && !errorMessage) {
        errorMessage = !matchRegex(convertedValue, regex) ? i18n.t('common.errors.invalid') : ''
    }

    return {value: convertedValue, error: errorMessage}
}
/**
 * set values that can be selected from date picker
 * @param date - current date
 * @param min - min of the interval
 * @param max - max of the interval
 */
export const disableDateSelection = (date?: Date, min?: Date, max?: Date): boolean => {
    if (!date) {
        return true
    }
    if (min && max) {
        return !isWithinInterval(date, {
            start: startOfDay(min),
            end: endOfDay(max)
        })
    }
    if (min) {
        return isBefore(date, startOfDay(min))
    }
    if (max) {
        return isAfter(date, endOfDay(max))
    }
    return false
}
