import {DoctorDto} from '../utils/types'
import {IDoctorsService} from '../service/utils/types'
import {IDoctorStore, IUIStore} from './utils/types'
import CrudStore from './crud_store'

class DoctorStore extends CrudStore<DoctorDto> implements IDoctorStore {
    constructor(doctorsService: IDoctorsService, uiStore: IUIStore) {
        super(doctorsService, uiStore)
    }
}

export default DoctorStore
