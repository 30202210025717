import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import {loadTranslations} from './i18n'
import en from './i18n/translations_en.json'
import de from './i18n/translations_de.json'
import RootApp from './RootApp'

export const publicUrl = process.env.PUBLIC_URL || ''

loadTranslations(en, de)

ReactDOM.render(
    <React.StrictMode>
        <RootApp />
    </React.StrictMode>,
    document.getElementById('root')
)
