import {ProductDto} from '../utils/types'
import {IProductService} from '../service/utils/types'
import {IProductStore, IUIStore} from './utils/types'
import UploadStore from './subStores/upload_store'

class ProductStore extends UploadStore<ProductDto> implements IProductStore {
    // IF ADD NEW FIELDS ADD TO makeObservable!!!!!

    constructor(productService: IProductService, uiStore: IUIStore) {
        super(productService, uiStore)
    }
}

export default ProductStore
