import {i18n}  from '../../../i18n'
import {Checkbox} from 'antd'

export const generateUsersColumns = () => [
    {
        title: i18n.t('user.email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true,
    },
    {
        title: i18n.t('user.firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: true,
    },
    {
        title: i18n.t('user.lastName'),
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: true,
    },
    {
        title: i18n.t('user.username'),
        dataIndex: 'username',
        key: 'username',
        sorter: true,
    },
    {
        title: i18n.t('user.active'),
        dataIndex: 'active',
        key: 'active',
        sorter: false,
        render: (active: boolean) => <Checkbox checked={active} disabled={true}/>,
    },
]
