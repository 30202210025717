import React, {useEffect} from 'react'
import {useForm} from '../../../../utils/hooks'
import {ProdIngredientCommonKeys, productIngredientMandatoryFields, RelationsDtoKeys} from '../../../../utils/forms/keys'
import {EditModalProps, OptionItem, ProductIngredientDto} from '../../../../utils/types'
import {buildSelectOptionsObject, convertFloatNumber} from '../../../../utils/helper'
import {SearchTypes} from '../../../../utils/enums'
import {useStores} from '../../../../store'
import {sharedStyles} from '../../../../style/shared_styles'
import {ActionButtons, CustomSelect, Input, LiveSearch} from '../../../common'
import {i18n} from '../../../../i18n'
import {observer} from 'mobx-react'
import {useSubmit} from '../../utils/hooks'
import {FLOATING_NUMBER, INTEGER_NUMBER} from '../../../../utils/constants'
import {useNavigate} from 'react-router-dom'
import {styles} from '../../../observations/style/Observation.style'

const EditProdIngredient = observer((props: EditProductIngredientProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler, isDataChanged] = useForm(props.data, productIngredientMandatoryFields, [
        RelationsDtoKeys.product,
        RelationsDtoKeys.ingredient
    ])
    const {productIngStore, profileStore} = useStores()

    const unitOptions: OptionItem[] = buildSelectOptionsObject(profileStore.unitOfMeasures)
    const navigate = useNavigate()
    const {loading, onDataSubmit} = useSubmit(productIngStore, onValidateInputs, formState)

    const onSubmit = async () => {
        formState.values.dose = convertFloatNumber(formState.values.dose)
        const isSuccess: boolean = await onDataSubmit()

        if (!props.data && isSuccess) {
            props.onCloseModal?.()
        }
    }

    useEffect(() => {
        if (!props.data) {
            inputChangeHandler(unitOptions[0].value, ProdIngredientCommonKeys.packSize)
        }
    }, [])

    useEffect(() => {
        if (!props.data) {
            inputChangeHandler(unitOptions[0].value, RelationsDtoKeys.unit)
        }
    }, [])

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn}}>
                    <LiveSearch
                        label={i18n.t('prodIng.product')}
                        searchType={SearchTypes.product}
                        defaultValue={formState.values.product}
                        {...extractProps(RelationsDtoKeys.product)}
                    />
                    <LiveSearch
                        label={i18n.t('prodIng.ingredient')}
                        searchType={SearchTypes.ingredient}
                        defaultValue={formState.values.ingredient}
                        {...extractProps(RelationsDtoKeys.ingredient)}
                    />
                    <Input type={'number'} label={i18n.t('prodIng.rang')} regex={INTEGER_NUMBER} min={0} {...extractProps(RelationsDtoKeys.rang)} />
                </div>
                <div style={styles.paramColWrapper}>
                    <Input
                        label={i18n.t('prodIng.dose')}
                        regex={FLOATING_NUMBER}
                        {...extractProps(RelationsDtoKeys.dose)}
                        onBlurValueProcess={convertFloatNumber}
                    />
                    <CustomSelect label={i18n.t('prodIng.unit')} nullOption={true} items={unitOptions} {...extractProps(RelationsDtoKeys.unit)} />
                    <Input label={i18n.t('prodIngCommon.active')} type={'checkbox'} {...extractProps(ProdIngredientCommonKeys.active)} />
                    <Input label={i18n.t('prodIngCommon.valid')} type={'checkbox'} {...extractProps(ProdIngredientCommonKeys.valid)} />
                </div>
            </div>
            <ActionButtons
                onSave={onSubmit}
                loadingSave={loading}
                disabledSave={!isDataChanged}
                onCancel={props.data ? () => navigate(-1) : props.onCloseModal}
            />
        </div>
    )
})

interface EditProductIngredientProps extends EditModalProps {
    data?: ProductIngredientDto
}

export default EditProdIngredient
