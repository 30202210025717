import CrudStore from './crud_store'
import {PraxisDto} from '../utils/types'
import {IPraxisStore, IUIStore} from './utils/types'
import {IPraxisService} from '../service/utils/types'

class PraxisStore extends CrudStore<PraxisDto> implements IPraxisStore {
    constructor(service: IPraxisService, uiStore: IUIStore) {
        super(service, uiStore)
    }
}

export default PraxisStore
