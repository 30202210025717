import {ProductIngredientDto} from '../utils/types'
import {IProductIngredientService} from '../service/utils/types'
import {IProductIngredientStore, IUIStore} from './utils/types'
import UploadStore from './subStores/upload_store'

class ProductIngredientStore extends UploadStore<ProductIngredientDto> implements IProductIngredientStore {
    // IF ADD NEW FIELDS ADD TO makeObservable!!!!!

    constructor(productIngredientService: IProductIngredientService, uiStore: IUIStore) {
        super(productIngredientService, uiStore)
    }
}

export default ProductIngredientStore
