import {i18n}  from '../../../i18n'
import {IngredientTableEntryType} from '../../../utils/types'
import {ColumnType} from 'antd/lib/table/interface'
import {Checkbox} from 'antd'

export const generateIngredientsColumns = (): ColumnType<IngredientTableEntryType>[] => [
    {
        title: i18n.t('ingredient.keySto'),
        dataIndex: 'keySto',
        key: 'keySto',
        sorter: true
    },
    {
        title: i18n.t('prodIngCommon.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true
    },
    {
        title: i18n.t('ingredient.url'),
        dataIndex: 'url',
        key: 'url',
        sorter: true
    },
    {
        title: i18n.t('prodIngCommon.active'),
        dataIndex: 'active',
        key: 'active',
        sorter: false,
        render: (active: boolean) => <Checkbox checked={active} disabled={true} />
    }
]
