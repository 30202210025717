import React, {useState} from 'react'
import {i18n}  from '../../i18n'
import {observer} from 'mobx-react'
import {CustomModal, TitleText} from '../common'
import ChangePassword from './ChangePassword'
import {useStores} from '../../store'
import {sharedStyles} from '../../style/shared_styles'
import {Button} from 'antd'
import {EditFilled} from '@ant-design/icons'
import DisplayDetailsEntry from './DisplayDetailsEntry'
import {formatAddress} from '../../utils/uiHelper'
import EditGeneralProfile from './EditGeneralProfile'

const ProfilePage: React.FC = observer(() => {
    const {
        profileStore: {userProfile}
    } = useStores()

    const [editModalVisible, setEditModalVisible] = useState(false)
    const [changePassVisible, setChangePassVisible] = useState(false)

    if (!userProfile) {
        return <p>{i18n.t('common.errors.noData')}</p>
    }
    const fullName = userProfile.firstName + ' ' + userProfile.lastName
    return (
        <div>
            <div style={{...sharedStyles.inlineContainer, width: '98%', marginRight: 10}}>
                <TitleText size={20} text={i18n.t('profile.title')} />
                <EditFilled style={{fontSize: 30}} onClick={() => setEditModalVisible(true)} />
            </div>
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <DisplayDetailsEntry title={i18n.t('profile.general.name')} value={fullName} />
                    <DisplayDetailsEntry
                        title={i18n.t('profile.contact.email')}
                        value={<a href={`mailto:${userProfile.eMail}`}>{userProfile.eMail}</a>}
                    />
                    <DisplayDetailsEntry
                        title={i18n.t('profile.edit.password')}
                        value={
                            <Button type={'link'} size={'small'} style={{paddingLeft: 0}} onClick={() => setChangePassVisible(true)}>
                                {i18n.t('profile.edit.changePass')}
                            </Button>
                        }
                    />
                </div>

                <div style={sharedStyles.rightColumn}>
                    <DisplayDetailsEntry title={i18n.t('profile.contact.phone')} value={userProfile.telephone} />
                    <DisplayDetailsEntry title={i18n.t('profile.contact.cellphone')} value={userProfile.mobilePhone} />
                    <DisplayDetailsEntry title={i18n.t('profile.contact.address')} value={formatAddress(userProfile.address)} />
                </div>
            </div>
            <CustomModal title={i18n.t('profile.edit.title')} visible={editModalVisible} onCancel={() => setEditModalVisible(false)}>
                <EditGeneralProfile closeModal={() => setEditModalVisible(false)} profile={userProfile} />
            </CustomModal>
            <CustomModal title={i18n.t('profile.changePass.title')} visible={changePassVisible} onCancel={() => setChangePassVisible(false)}>
                <ChangePassword closeModal={() => setChangePassVisible(false)} />
            </CustomModal>
        </div>
    )
})

export default ProfilePage
