import React, {useEffect} from 'react'
import {EditModalProps, OptionItem, ProductDto} from '../../../utils/types'
import {useForm} from '../../../utils/hooks'
import {ProdIngredientCommonKeys, productMandatoryFields} from '../../../utils/forms/keys'
import {buildEnumOptions, buildSelectOptionsObject} from '../../../utils/helper'
import {PackSize} from '../../../utils/enums'
import {useStores} from '../../../store'
import {sharedStyles} from '../../../style/shared_styles'
import {ActionButtons, CustomSelect, Input} from '../../common'
import {i18n}  from '../../../i18n'
import {styles} from '../../observations/style/Observation.style'
import {observer} from 'mobx-react'
import {useSubmit} from '../../relations/utils/hooks'
import {useNavigate} from 'react-router-dom'

const EditProduct: React.FC<EditProductProps> = observer((props: EditProductProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler, isDataChanged] = useForm(props.product, productMandatoryFields)
    const packSizeOptions: OptionItem[] = buildEnumOptions(PackSize, 'product.packSizeType')
    const {productStore, profileStore} = useStores()
    const unitOptions: OptionItem[] = buildSelectOptionsObject(profileStore.unitOfMeasures)
    const dosageFormsOptions: OptionItem[] = buildSelectOptionsObject(profileStore.dosageForms)
    const {loading, onDataSubmit} = useSubmit(productStore, onValidateInputs, formState)

    const onSubmit = async () => {
        const isSuccess: boolean = await onDataSubmit()

        if (!props.product && isSuccess) {
            props.onCloseModal?.()
        }
    }

    const navigate = useNavigate()

    useEffect(() => {
        if (!props.product) {
            inputChangeHandler(packSizeOptions[0].value, ProdIngredientCommonKeys.packSize)
        }
    }, [])

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn}}>
                    <Input label={i18n.t('prodIngCommon.name')} {...extractProps(ProdIngredientCommonKeys.name)} />
                    <Input label={i18n.t('product.pzn')} {...extractProps(ProdIngredientCommonKeys.pzn)} />
                    <Input label={i18n.t('product.packQuantity')} {...extractProps(ProdIngredientCommonKeys.packQuantity)} />
                    <Input type={'number'} label={i18n.t('product.pznSmallerPack')} {...extractProps(ProdIngredientCommonKeys.pznSmallerPack)} />
                    <Input type={'number'} label={i18n.t('product.pznSuccessor')} {...extractProps(ProdIngredientCommonKeys.pznSuccessor)} />
                </div>
                <div style={styles.paramColWrapper}>
                    <Input label={i18n.t('product.producer')} {...extractProps(ProdIngredientCommonKeys.producer)} />
                    <CustomSelect label={i18n.t('product.packSize')} items={packSizeOptions} {...extractProps(ProdIngredientCommonKeys.packSize)} />
                    <CustomSelect label={i18n.t('product.unit')} items={unitOptions} {...extractProps(ProdIngredientCommonKeys.unit)} />
                    <CustomSelect
                        label={i18n.t('product.dosageForms')}
                        items={dosageFormsOptions}
                        {...extractProps(ProdIngredientCommonKeys.dosageForms)}
                    />
                    <Input label={i18n.t('prodIngCommon.active')} type={'checkbox'} {...extractProps(ProdIngredientCommonKeys.active)} />
                </div>
            </div>
            <ActionButtons
                onSave={onSubmit}
                loadingSave={loading}
                disabledSave={!isDataChanged}
                onCancel={props.product ? () => navigate(-1) : props.onCloseModal}
            />
        </div>
    )
})

interface EditProductProps extends EditModalProps {
    product?: ProductDto
}

export default EditProduct
