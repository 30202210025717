import {Icd10Dto} from '../utils/types'
import {IIcd10Service} from './utils/types'
import {EntityPathEnum} from '../utils/enums'
import UploadService from './upload_service'

class Icd10Service extends UploadService<Icd10Dto> implements IIcd10Service {
    constructor() {
        super(EntityPathEnum.ICD10)
    }
}

export default Icd10Service
