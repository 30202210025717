import {LocalStyleSheet} from "../../../utils/types";

export const styles: LocalStyleSheet = {
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    padding: {
        paddingLeft: 5,
        paddingRight: 5,
    },
    tableEntry:{
        display: 'flex',
        flexDirection: 'row',
        width: '50%'
    },
    textSection:{
        display: 'flex',
        flexDirection: 'column',
    }
};
