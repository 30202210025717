// if add a new ROUTE add in ReactController also
export enum Routes {
    HOME = '/',
    ABOUT = '/about',
    PROFILE = '/profile',
    PATIENT = '/patient',
    SIDE_EFFECTS = '/side-effects',
    VITAL_DATA = '/vital-data',
    PRODUCTS = '/product',
    ICD10 = '/icd10',
    INGREDIENTS = '/ingredients',
    DOCTORS = '/doctor',
    PRAXIS = '/praxis',
    USER = '/user',
    PRODUCT_INGREDIENT = '/product-ingredient',
    ABDA = '/abda',
    /*planner*/
    THERAPY_NAMES = '/therapy-names',
    TITLE = '/title',
    DOSAGE_FORMS = '/dosage-forms',
    UNIT_MEASURE = '/unit-measure',
    FORM_OF_ADDRESS = '/form-of-address',
    GENDER = '/gender',
    FEDERAL_STATE = '/federal-state'
}
