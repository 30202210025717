import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {PraxisDto, PraxisTableEntryType, TableProps} from '../../../utils/types'
import {Table} from 'antd'
import {Routes} from '../../../utils/routes'
import {generatePraxisColumns} from '../utils/praxis_helper'
import {filterColumns} from '../../../utils/helper'
import {ColumnsType} from 'antd/es/table'

const PraxisTable: React.FC<PraxisTableProps> = (props: PraxisTableProps) => {
    const navigate = useNavigate()

    const dataSource: PraxisTableEntryType[] =
        props.data?.map(pr => {
            return {
                key: pr.id,
                id: pr.id,
                name: pr.name,
                description: pr.description,
                active: pr.active
            }
        }) || []

    const columns: ColumnsType<PraxisTableEntryType> = useMemo(() => {
        return filterColumns(generatePraxisColumns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={props.loading}
            pagination={props.pagination}
            onRow={(record: PraxisTableEntryType) => {
                return {
                    onClick: () => navigate(`${Routes.PRAXIS}/${record.id}`)
                }
            }}
            onChange={props.onTableChange}
        />
    )
}

interface PraxisTableProps extends TableProps<PraxisDto> {}

export default PraxisTable
