import BaseService from './base_service'
import {ApiMessages, IRelationsService} from './utils/types'
import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {EntityPathEnum} from '../utils/enums'
import {AnyObj} from '../utils/types'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class RelationsService<T> extends BaseService implements IRelationsService<T> {
    resourcePath: EntityPathEnum

    constructor(resourcePath: EntityPathEnum) {
        super()
        this.resourcePath = resourcePath
    }

    async getAllData(searchList?: AnyObj, apiMes?: ApiMessages): Promise<AxiosResponse<T[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'GET',
            url: `/api/private/${this.resourcePath}/`,
            headers: {
                ...authHeader
            }
        }, apiMes)
    }

    async createData(otherId: string, ingredientId: string, apiMes?: ApiMessages): Promise<AxiosResponse<string>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'POST',
            url: `/api/private/${this.resourcePath}/${otherId}/${ingredientId}`,
            headers: {
                ...authHeader
            }
        }, apiMes)
    }

    async deleteData(otherId: string, ingredientId: string, apiMes?: ApiMessages): Promise<AxiosResponse<string>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'DELETE',
            url: `/api/private/${this.resourcePath}/${otherId}/${ingredientId}`,
            headers: {
                ...authHeader
            }
        }, apiMes)
    }
}

export default RelationsService
