import CrudService from './crud_service'
import {UserDto} from '../utils/types'
import {IUserService} from './utils/types'
import {EntityPathEnum} from '../utils/enums'

class UserService extends CrudService<UserDto> implements IUserService {
    constructor() {
        super(EntityPathEnum.USER)
    }
}

export default UserService;
