import React, {useMemo, useState} from 'react'
import {IngredientDto, SideEffectIngredientDto, SideEffectIngTableType, SideEffectSearchDto, TableProps} from '../../../utils/types'
import {Table} from 'antd'
import {filterColumns} from '../../../utils/helper'
import {i18n}  from '../../../i18n'
import {CustomModal, PopUpDelete} from '../../common'
import {useStores} from '../../../store'
import {useDeleteRelations} from '../utils/hooks'
import {PlusCircleOutlined} from '@ant-design/icons'
import {styles} from '../../observations/style/Observation.style'
import EditSideEffectIng from './EditSideEffectIng'
import {Routes} from '../../../utils/routes'
import {useNavigate} from 'react-router-dom'

const SideEffectIngTable: React.FC<SideEffectIngTableProps> = (props: SideEffectIngTableProps) => {
    const navigate = useNavigate()

    const [modalVisible, setModalVisible] = useState(false)
    const {sideEffectIngStore} = useStores()

    const {loadingDelete, onDelete} = useDeleteRelations(sideEffectIngStore, data => [data.sideEffect.id, data.ingredient.id])

    const onDeleteHandler = async (deleteData: SideEffectIngTableType) => {
        const isSuccess = await onDelete(deleteData)
        if (isSuccess) {
            props.onRefreshData?.()
        }
    }

    const onAddNewItem = (isSuccess?: boolean) => {
        setModalVisible(false)
        if (isSuccess) {
            props.onRefreshData?.()
        }
    }

    const dataSource: SideEffectIngTableType[] = useMemo(() => {
        return (
            props.data?.map(ingr => {
                const {sideEffect, ingredient} = ingr
                return {
                    key: sideEffect.id + ingredient.id,
                    id: sideEffect.id + ingredient.id,
                    sideEffect: sideEffect,
                    ingredient: ingredient
                }
            }) || []
        )
    }, [props.data])

    const generateColumns = useMemo(() => {
        return [
            {
                title: i18n.t('sideEffectIng.sideEffect'),
                dataIndex: 'sideEffect',
                key: 'sideEffect',
                sorter: (a: SideEffectIngTableType, b: SideEffectIngTableType) => a.sideEffect.title.localeCompare(b.sideEffect.title),
                render: (sideEffect: SideEffectSearchDto) => sideEffect.title,
                onCell: (record: SideEffectIngTableType) => {
                    return {
                        onClick: () => navigate(`${Routes.SIDE_EFFECTS}/${record.sideEffect.id}`)
                    }
                }
            },
            {
                title: '',
                dataIndex: 'operation',
                width: 30,
                render: (_: any, record: SideEffectIngTableType) => (
                    <PopUpDelete
                        onDelete={async () => onDeleteHandler(record)}
                        messageDelete={i18n.t('sideEffectIng.messages.delete')}
                        isIcon={true}
                        loading={loadingDelete}
                    />
                )
            }
        ]
    }, [])

    const columns = useMemo(() => {
        return filterColumns(generateColumns, props.skipColumns)
    }, [props.skipColumns])

    const data: Partial<SideEffectIngredientDto> | undefined = useMemo(() => {
        if (!props.ingredient) {
            return undefined
        }
        const {id, name, note, keySto} = props.ingredient
        return {
            ingredient: {
                id,
                name,
                note,
                keySto
            }
        }
    }, [props.ingredient])

    return (
        <div style={{display: 'flex', width: '50%', justifyContent: 'space-between'}}>
            <div style={{flex: 4}}>
                <Table dataSource={dataSource} columns={columns} loading={props.loading} pagination={false} />
            </div>

            <div style={styles.addIconWrapper}>
                <PlusCircleOutlined style={styles.addIcon} onClick={() => setModalVisible(true)} />
            </div>
            <CustomModal title={i18n.t('sideEffectIng.create')} visible={modalVisible} onCancel={() => setModalVisible(false)}>
                <EditSideEffectIng data={data} onCloseModal={onAddNewItem} />
            </CustomModal>
        </div>
    )
}

interface SideEffectIngTableProps extends TableProps<SideEffectIngredientDto> {
    ingredient?: IngredientDto
}

export default SideEffectIngTable
