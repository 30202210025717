import React, {useMemo} from 'react'
import {generateAbdaColumns} from '../utils/abda_helper'
import {AbdaDto, AbdaTableEntryType, TableProps} from '../../../utils/types'
import {Table} from 'antd'
import {filterColumns} from '../../../utils/helper'
import {ColumnsType} from 'antd/es/table'
import {format} from 'date-fns'
import {Format} from "../../../utils/constants";

const AbdaTable = (props: AbdaTableProps) => {

    const dataSource: AbdaTableEntryType[] = useMemo(() => {
        return (  props.data?.map((dto) => {
            return {
                key: dto.id,
                id: dto.id,
                abdaFile: dto.abdaFile,
                validFrom: format(dto.validFrom, Format.DateFormat),
                createdAt: format(dto.createdAt, Format.DateTimeFormat),
                inserts: dto.inserts?.toString() || '0',
                updates: dto.updates?.toString() || '0',
                relevantUpdates: dto.relevantUpdates?.toString() || '0',
                deletes: dto.deletes?.toString() || '0',
            }
        }) || [])
    }, [props.data])

    const columns: ColumnsType<AbdaTableEntryType> = useMemo(() => {
        return filterColumns(generateAbdaColumns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={props.loading}
            pagination={props.pagination}
            onChange={props.onTableChange}
        />
    )
}

interface AbdaTableProps extends TableProps<AbdaDto> {
}

export default AbdaTable
