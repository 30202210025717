import React from 'react'
import {PageHeader} from 'antd'
import {i18n}  from '../../../i18n'
import I18nCRUD from '../components/I18nCRUD'
import {I18nType} from '../../../utils/types'

const TitlesPage = () => {
    return (
        <div>
            <PageHeader className='site-page-header-responsive' title={i18n.t('planner.title.title')} onBack={() => window.history.back()}>
                <I18nCRUD
                    type={I18nType.TITLE}
                    newTitle={i18n.t('planner.title.new')}
                    failUpdate={i18n.t('planner.title.failUpdate')}
                    failGet={i18n.t('planner.title.failGet')}
                    deleteTitle={i18n.t('planner.title.delete')}
                />
            </PageHeader>
        </div>
    )
}


export default TitlesPage
