import {useLoading} from './useLoading'
import {useEffect} from 'react'
import {ICRUDStore, IRelationsStore} from '../../store/utils/types'
import {AnyObj, PageRequest} from '../types'

/**
 * get initial data for each list page
 * @param store
 * @param searchList
 */
export const useGetData = <T>(store: ICRUDStore<T> | IRelationsStore<T>, searchList?: AnyObj) => {
    const [loading, setLoading] = useLoading(false)

    useEffect(() => {
        const getServerData = async () => {
            setLoading(true)
            await store.getAllData(searchList || {})
            setLoading(false)
        }
        getServerData()
    }, [searchList])
    return [loading]
}

/**
 * get pagination data for each list page
 * @param store
 * @param pageRequest
 */
export const useGetPaginationData = <T>(store: ICRUDStore<T>, pageRequest: PageRequest) => {
    const [loading, setLoading] = useLoading(false)
    const {searchList, page, sortField, sortOrder} = pageRequest
    useEffect(() => {
        const getServerData = async () => {
            setLoading(true)
            await store.getPaginatedData(pageRequest)
            setLoading(false)
        }

        getServerData()
    }, [searchList, page, sortField, sortOrder])

    return [loading]
}
