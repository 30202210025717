import axios, {AxiosResponse, Method} from 'axios'
import {i18n}  from '../../i18n'
import {MessageType} from './types'
import {useLogout} from '../../store'
import {onNotificationError, onNotificationSuccess} from './notificationHelper'
import {AnyObj} from '../../utils/types'

export const createClient = () => {
    return axios.create()
}

export const errorHandler = (message?: MessageType | string) => async (error: any) => {
    if (error?.response && (error.response.status === 401 || error.response.status === 403)) {
        useLogout?.()
    }
    if (message && typeof message !== 'string' && message.doNotShow) {
        return error
    }
    const messageCustom = typeof message === 'string' ? message : message?.text
    onNotificationError({
        description: error?.response?.data?.errorMessage || messageCustom || error?.response?.statusText
    })
    return error
}

export const onSuccessHandler = (message?: MessageType | string) => (response: AxiosResponse) => {
    const method: Method | string | undefined = response?.config?.method
    if (showSuccessMessage(method)) {
        if (message && typeof message !== 'string' && message.doNotShow) {
            return response
        }

        let type = 'create'
        if (method === 'put' || method === 'patch') {
            type = 'update'
        }
        if (method === 'delete') {
            type = 'delete'
        }

        const messageCustom = typeof message === 'string' ? message : message?.text
        onNotificationSuccess({
            description: messageCustom || i18n.t(`notification.success.${type}`)
        })
    }
    return response
}

export const showSuccessMessage = (method?: Method | string) => {
    if (method && method !== 'get') {
        return true
    }
    return false
}

export const deleteEmptyEntryFromObj = (obj?: AnyObj) => {
    if (obj) {
        Object.keys(obj).forEach(e => {
            if (!obj[e]) {
                delete obj[e]
            }
        })
    }
}
