import React, {useState} from 'react'
import {observer} from 'mobx-react'
import {useStores} from '../../store'
import {ErrorComponent, FullLoader} from '../common'
import {i18n}  from '../../i18n'
import ListPageLayout from '../common/layout/ListPageLayout'
import EditIngredient from './components/EditIngredient'
import IngredientsTable from './components/IngredientsTable'
import {FileExtension, SearchComponentType} from '../../utils/enums'
import {usePagination} from '../../utils/hooks/usePagination'
import {TablePaginationConfig} from 'antd'
import SearchComponent from '../common/SearchComponent'
import {AnyObj, SearchDto} from '../../utils/types'

const IngredientPage = observer(() => {
    const {ingredientStore} = useStores()
    const [searchList, setSearchList] = useState<AnyObj>({})
    const [sortField, setSortField] = useState<string>()
    const [sortOrder, setSortOrder] = useState<string>()
    const [loading, paginationConfig] = usePagination(ingredientStore, searchList, sortField, sortOrder)

    if (ingredientStore.dataList === null) {
        return <FullLoader />
    }

    if (ingredientStore.dataList === undefined) {
        return <ErrorComponent status={404} />
    }

    const searchParams: SearchDto[] = [
        {
            type: SearchComponentType.INPUT,
            searchParam: 'ingredientName',
            placeholder: i18n.t('ingredient.ingredientName')
        }
    ]

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSortField(sorter.field)
        setSortOrder(sorter.order)
    }

    return (
        <ListPageLayout
            title={i18n.t('ingredient.pageTitle')}
            createModalTitle={i18n.t('ingredient.create')}
            createModal={EditIngredient}
            showUploadButton={true}
            draggerProps={{store: ingredientStore, acceptedExtensions: [FileExtension.CSV]}}>
            <SearchComponent searchParams={searchParams} onChangeFilter={setSearchList} />
            <IngredientsTable data={ingredientStore.dataList} loading={loading} pagination={paginationConfig} onTableChange={handleTableChange} />
        </ListPageLayout>
    )
})

export default IngredientPage
