import {i18n}  from '../../../i18n'
import Tag from 'antd/lib/tag'
import {Color} from '../../../style/colors'

export const generateProdIngredientColumns = () => [
    {
        title: i18n.t('prodIng.product'),
        dataIndex: 'product',
        key: 'product',
        sorter: false,
        render: (product: string) => <Tag color={Color.active}>{product}</Tag>,
    },

    {
        title: i18n.t('prodIng.ingredient'),
        dataIndex: 'ingredient',
        key: 'ingredient',
        sorter: false,
        render: (ingredient: string) => <Tag color={Color.active}>{ingredient}</Tag>,
    },

    {
        title: i18n.t('prodIng.unit'),
        dataIndex: 'unit',
        key: 'unit',
        sorter: true,
    },
    {
        title: i18n.t('prodIng.rang'),
        dataIndex: 'rang',
        key: 'rang',
        sorter: true,
    },
    {
        title: i18n.t('prodIng.dose'),
        dataIndex: 'dose',
        key: 'dose',
        sorter: true,
    },
]
