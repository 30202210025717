import React from 'react'
import {useParams} from 'react-router'
import {useStores} from '../../../store'
import {useSimpleGetDelete} from '../../relations/utils/hooks'
import {DetailsPageHeader, ErrorComponent, FullLoader} from '../../common'
import {i18n} from '../../../i18n'
import {Tabs} from 'antd'
import EditDoctor from './EditDoctor'
import {observer} from 'mobx-react'

const DoctorDetails = observer(() => {
    const {doctorId} = useParams<{doctorId: string}>()

    const {doctorStore} = useStores()
    const {loading, loadingDelete, currentData, onDelete} = useSimpleGetDelete(doctorId, doctorStore)

    if (loading) {
        return <FullLoader />
    }

    if (!currentData) {
        return <ErrorComponent status={404} title={i18n.t('doctor.errors.notFound')} />
    }

    return (
        <>
            <DetailsPageHeader
                title={currentData.lastName + ' ' + currentData.firstName}
                onDelete={onDelete}
                messageDelete={i18n.t('doctor.messages.delete')}
                loadingDelete={loadingDelete}
                data={currentData}
                footer={
                    <Tabs defaultActiveKey='details'>
                        <Tabs.TabPane tab={i18n.t('tabs.details.title')} key='details'>
                            <EditDoctor data={currentData} />
                        </Tabs.TabPane>
                    </Tabs>
                }
            />
        </>
    )
})

export default DoctorDetails
