import React, {useState} from 'react'
import {observer} from 'mobx-react'
import ListPageLayout from '../common/layout/ListPageLayout'
import {i18n}  from '../../i18n'
import EditProduct from '../product/components/EditProduct'
import {useStores} from '../../store'
import {ErrorComponent, FullLoader} from '../common'
import Icd10Table from './components/Icd10Table'
import {FileExtension, SearchComponentType} from '../../utils/enums'
import {usePagination} from '../../utils/hooks/usePagination'
import {TablePaginationConfig} from 'antd'
import {AnyObj, SearchDto} from '../../utils/types'
import SearchComponent from '../common/SearchComponent'

const Icd10Page = observer(() => {
    const {icd10Store} = useStores()
    const [searchList, setSearchList] = useState<AnyObj>({})
    const [sortField, setSortField] = useState<string>()
    const [sortOrder, setSortOrder] = useState<string>()
    const [loading, paginationConfig] = usePagination(icd10Store, searchList, sortField, sortOrder)

    if (icd10Store.dataList === null) {
        return <FullLoader />
    }

    if (icd10Store.dataList === undefined) {
        return <ErrorComponent status={404} />
    }

    const searchParams: SearchDto[] = [
        {
            type: SearchComponentType.INPUT,
            searchParam: 'icd10Name',
            placeholder: i18n.t('icd10.name'),
        },
    ]

    const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setSortField(sorter.field)
        setSortOrder(sorter.order)
    }

    return (
        <ListPageLayout
            title={i18n.t('icd10.pageTitle')}
            showUploadButton={true}
            hideCreateButton={true}
            createModal={EditProduct}
            draggerProps={{store: icd10Store, acceptedExtensions: [FileExtension.CSV, FileExtension.TXT]}}>
            <SearchComponent searchParams={searchParams} onChangeFilter={setSearchList} />
            <Icd10Table data={icd10Store.dataList} loading={loading} pagination={paginationConfig} onTableChange={handleTableChange} />
        </ListPageLayout>
    )
})

export default Icd10Page
