import React from 'react'
import {i18n} from '../../i18n'
import {Button, Popconfirm, PopconfirmProps, Spin} from 'antd'
import {ButtonStyle} from '../../style/button'
import {styles} from '../observations/style/Observation.style'
import {DeleteOutlined} from '@ant-design/icons'
import {Color} from '../../style/colors'

const PopUpDelete: React.FC<PopUpDeleteProps> = (props: PopUpDeleteProps) => {
    return (
        <Popconfirm
            key={'deleteConf'}
            title={props.messageDelete || i18n.t('common.messages.delete')}
            onConfirm={props.onDelete}
            okText={i18n.t('button.yes')}
            cancelText={i18n.t('button.no')}>
            {props.isIcon ?
                !props.loading ? <DeleteOutlined style={{...styles.deleteIcon, marginLeft: 0}}/> : <Spin style={{color: Color.warningStrong}}/>
                : (
                    <Button key='delete' type={'default'} shape={'round'} style={ButtonStyle.delete} loading={props.loading}>
                        {i18n.t('button.delete')}
                    </Button>
                )}
        </Popconfirm>
    )
}

interface PopUpDeleteProps extends Partial<PopconfirmProps> {
    onDelete: () => void
    messageDelete?: string
    loading?: boolean
    isIcon?: boolean
}

export default PopUpDelete
