import CrudService from './crud_service'
import {SideEffectType} from '../utils/types'
import {ISideEffectService} from './utils/types'
import {EntityPathEnum} from '../utils/enums'

class SideEffectService extends CrudService<SideEffectType> implements ISideEffectService {
    constructor() {
        super(EntityPathEnum.SIDE_EFFECT)
    }
    // add additional methods
}


export default SideEffectService;
