import React from "react";
import {observer} from "mobx-react";
import {useForm, useLoading} from "../../utils/hooks";
import {useStores} from "../../store";
import {addressMandatoryFields, EditAddressKey, EditProfileKey, profileMandatoryFields} from "../../utils/keys";
import {AddressInfo, OptionItem, ProfileDto} from "../../utils/types";
import {buildSelectOptions} from "../../utils/helper";
import {ActionButtons, CustomSelect, Input, TextAreaInput, TitleText} from "../common";
import {i18n} from '../../i18n'
import {sharedStyles} from "../../style/shared_styles";


const EditGeneralProfile: React.FC<EditGeneralProfileProps> = observer((props: EditGeneralProfileProps) => {
    const [editLoading, setEditIsLoading] = useLoading(false)

    const {profileStore} = useStores()

    const [formState, extractProps, onValidateInputs] = useForm(props.profile, profileMandatoryFields)
    const [addressState, extractAddressProps, onValidateInputsAddress] = useForm(props.profile.address, addressMandatoryFields)

    const onSave = async () => {
        const isValid = onValidateInputs()
        const isValidAddress = onValidateInputsAddress()

        if (!isValid || !isValidAddress) {
            return
        }

        setEditIsLoading(true)
        const finalProfile: ProfileDto = {
            ...(formState.values as ProfileDto),
            address: addressState.values as AddressInfo
        }
        const isSuccess = await profileStore.updateProfile(finalProfile)
        setEditIsLoading(false)

        if (isSuccess) {
            props.closeModal()
        }
    }

    const countryOptions: OptionItem[] = buildSelectOptions(['Germany'])
    const federalStatesOptions: OptionItem[] = buildSelectOptions(profileStore.federalStates)

    return (
        <div>
            <div>
                <TitleText text={i18n.t('profile.header.general')} />
                <Input label={i18n.t('profile.general.firstName')} {...extractProps(EditProfileKey.firstName)} />
                <Input label={i18n.t('profile.general.lastName')} {...extractProps(EditProfileKey.lastName)} />
            </div>

            <TitleText text={i18n.t('profile.header.contact')} />
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <CustomSelect
                        label={i18n.t('profile.contact.country')}
                        nullOption={true}
                        items={countryOptions}
                        {...extractAddressProps(EditAddressKey.country)}
                    />
                    <TextAreaInput label={i18n.t('profile.contact.street')} {...extractAddressProps(EditAddressKey.street)} />
                    <Input label={i18n.t('profile.contact.postalCode')} {...extractAddressProps(EditAddressKey.postalCode)} />
                    <Input label={i18n.t('profile.contact.city')} {...extractAddressProps(EditAddressKey.city)} />
                    <CustomSelect
                        label={i18n.t('profile.contact.state')}
                        disabled={!addressState.values.country}
                        nullOption={true}
                        items={federalStatesOptions}
                        {...extractAddressProps(EditAddressKey.state)}
                    />
                </div>
                <div style={sharedStyles.rightColumn}>
                    <Input label={i18n.t('profile.contact.email')} {...extractProps(EditProfileKey.eMail)} />
                    <Input label={i18n.t('profile.contact.phone')} {...extractProps(EditProfileKey.telephone)} />
                    <Input label={i18n.t('profile.contact.cellphone')} {...extractProps(EditProfileKey.mobilePhone)} />
                </div>
            </div>

            <ActionButtons onCancel={props.closeModal} onSave={onSave} loadingSave={editLoading} />
        </div>
    )
})

type EditGeneralProfileProps = {
    closeModal: () => void
    profile: ProfileDto
}

export default EditGeneralProfile
