import {ProductIngredientDto} from '../utils/types'
import {IProductIngredientService} from './utils/types'
import {EntityPathEnum} from '../utils/enums'
import UploadService from './upload_service'

class ProductIngredientService extends UploadService<ProductIngredientDto> implements IProductIngredientService {
    constructor() {
        super(EntityPathEnum.PROD_INGREDIENT)
    }

    // add additional methods
}

export default ProductIngredientService
