import {notification} from 'antd'
import {ArgsProps} from 'antd/lib/notification'
import { generateRandomComponentKey } from "../../utils/helper";
import {i18n} from '../../i18n'

export const onNotificationError = (args: Partial<ArgsProps>) => {
    notification.error({
        ...args,
        message: args.message || i18n.t('notification.error.title'),
        description: args.description || i18n.t('notification.error.description'),
        key: generateRandomComponentKey()
    })
}

export const onNotificationSuccess = (args: Partial<ArgsProps>) => {
    notification.success({
        ...args,
        message: args.message || i18n.t('notification.success.title'),
        description: args.description || i18n.t('notification.success.create'),
        key: generateRandomComponentKey()
    })
}

export const onNotificationWarning = (args: Partial<ArgsProps>) => {
    notification.warn({
        ...args,
        message: args.message || i18n.t('notification.warning.title'),
        description: args.description || i18n.t('notification.warning.partialClient'),
        key: generateRandomComponentKey()
    })
}
