import {
    AnyObj,
    DoctorSearchEntry,
    GenericSearch,
    ICD10SearchEntry,
    IngredientSearchDto,
    OptionItem,
    PatientSearchEntry,
    PraxisSearchDto,
    ProductSearchDto,
    SideEffectSearchDto,
    VitalDataSearchDto
} from '../../../utils/types'
import {toJson} from '../../../utils/helper'
import {SearchTypes} from '../../../utils/enums'

const patientConverter = (data: PatientSearchEntry): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name
    }
}

const doctorConverter = (data: DoctorSearchEntry): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name
    }
}

const icd10Converter = (data: ICD10SearchEntry): OptionItem => {
    return {
        key: data.code,
        value: toJson(data),
        label: data.code + ' ' + data.officialName
    }
}

const ingredientsConverter = (data: IngredientSearchDto): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name
    }
}

const medicationConverter = (data: ProductSearchDto): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name + '\n' + (data.producer || ''),
        displayLabel: data.name
    }
}

const sideEffectsConverter = (data: SideEffectSearchDto): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.title
    }
}

const vitalDataConverter = (data: VitalDataSearchDto): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name
    }
}

const praxisConverter = (data: PraxisSearchDto): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name
    }
}

export const convertSearchResponse = (response: GenericSearch[], type: SearchTypes): OptionItem[] => {
    let data: OptionItem[] = []

    switch (type) {
        case SearchTypes.patient:
            data = (response as PatientSearchEntry[]).map(patientConverter)
            break
        case SearchTypes.doctor:
            data = (response as DoctorSearchEntry[]).map(doctorConverter)
            break
        case SearchTypes.icd10:
            data = (response as ICD10SearchEntry[]).map(icd10Converter)
            break
        case SearchTypes.ingredient:
            data = (response as IngredientSearchDto[]).map(ingredientsConverter)
            break
        case SearchTypes.product:
            data = (response as ProductSearchDto[]).map(medicationConverter)
            break
        case SearchTypes.sideEffects:
            data = (response as SideEffectSearchDto[]).map(sideEffectsConverter)
            break
        case SearchTypes.vitalData:
            data = (response as VitalDataSearchDto[]).map(vitalDataConverter)
            break
        case SearchTypes.praxis:
            data = (response as PraxisSearchDto[]).map(praxisConverter)
            break
    }

    return data
}

/**
 * Set encoding for square brackets when these are used in url.
 * @param val the search value
 */
export const encodeSquareBrackets = (val: string | undefined) => {
    if (val) {
        return encodeURIComponent(val)
    }
    return val
}

/**
 * Used to encode object values based on keys.
 * @param searchList the search dto
 */
export const encodeObjectValues = (searchList: AnyObj | undefined) => {
    if (!searchList) {
        return searchList
    }
    Object.keys(searchList).forEach(key => (searchList[key] = encodeSquareBrackets(searchList[key]) || ''))
    return searchList
}
