import BaseService from './base_service'
import {ApiMessages, II18nService} from './utils/types'
import {I18nEntity, I18nType} from '../utils/types'
import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class I18nService extends BaseService implements II18nService {
    async updateI18nEntities(entities: I18nEntity[], i18nType: I18nType, apiMes?: ApiMessages): Promise<AxiosResponse<I18nEntity[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler(
            {
                method: 'PATCH',
                url: `/api/private/i18n/`,
                data: entities,
                params: {
                    i18nType
                },
                headers: {
                    ...authHeader
                }
            },
            apiMes
        )
    }

    async getI18nEntities(i18nType: I18nType, apiMes?: ApiMessages): Promise<AxiosResponse<I18nEntity[]>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler(
            {
                method: 'GET',
                url: `/api/private/i18n/`,
                params: {
                    i18nType
                },
                headers: {
                    ...authHeader
                }
            },
            apiMes
        )
    }
}

export default I18nService
