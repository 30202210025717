import React from 'react'
import {Locale, useLocale} from '../../i18n'
import {Affix, Button, Layout} from 'antd'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import HomePage from '../home/HomePage'
import {Content} from 'antd/es/layout/layout'
import AppHeader from './AppHeader'
import {Routes as CustomRoutes} from '../../utils/routes'
import {styles} from './styles/App.style'
import FullLoadingOverlay from '../common/datahandling/FullLoadingOverlay'
import {observer} from 'mobx-react'
import {useStores} from '../../store'
import ProductPage from '../product/ProductPage'
import ProductDetails from '../product/components/ProductDetails'
import ProtectedPage from './security/ProtectedPage'
import {Role} from '../../utils/enums'
import AboutPage from '../about/AboutPage'
import ProfilePage from '../profile/ProfilePage'
import DoctorPage from '../doctors/DoctorPage'
import DoctorDetails from '../doctors/componenents/DoctorDetails'
import PraxisPage from '../praxis/PraxisPage'
import PraxisDetails from '../praxis/components/PraxisDetails'
import IngredientPage from '../ingredient/IngredientPage'
import IngredientDetails from '../ingredient/components/IngredientDetails'
import Icd10Page from '../icd10/Icd10Page'
import Icd10Details from '../icd10/components/Icd10Details'
import SideEffectsPage from '../side_effects/SideEffectsPage'
import SideEffectDetails from '../side_effects/components/SideEffectDetails'
import VitalDataPage from '../vital_data/VitalDataPage'
import VitalDataDetails from '../vital_data/components/VitalDataDetails'
import AbdaPage from '../abda/AbdaPage'
import UserPage from '../user/UserPage'
import UserDetails from '../user/components/UserDetails'
import TherapyNamePage from '../planner/therapy_names/TherapyNamePage'
import TitlesPage from '../planner/titles/TitlesPage'
import DosageFormsPage from '../planner/dosage_forms/DosageFormsPage'
import UnitMeasurePage from '../planner/unit_measure/UnitMeasurePage'
import FormOfAddressPage from '../planner/form_of_address/FormOfAddressPage'
import GenderPage from '../planner/gender/GenderPage'
import FederalStatePage from '../planner/federal_state/FederalStatePage'
import ProdIngredientPage from '../relations/prod_ingredient/ProdIngredientPage'
import ProdIngredientDetails from '../relations/prod_ingredient/components/ProdIngredientDetails'

const App: React.FC = observer(() => {
    const [locale, setLocale] = useLocale()

    const {uiStore} = useStores()

    return (
        <div key={locale.toString()}>
            <BrowserRouter>
                <Layout style={{minHeight: '100vh'}}>
                    <AppHeader />
                    <Content style={{margin: '1% 5%', flex: 1}}>
                        <Routes>
                            <Route path='/' element={<HomePage />} />
                            <Route path={CustomRoutes.ABOUT} element={<AboutPage />} />
                            <Route path={CustomRoutes.PROFILE} element={<ProfilePage />} />

                            <Route path={CustomRoutes.PRODUCTS} element={<ProtectedPage role={Role.PROD} element={<ProductPage />} />} />
                            <Route
                                path={CustomRoutes.PRODUCTS + '/:productId'}
                                element={<ProtectedPage role={Role.PROD} element={<ProductDetails />} />}
                            />

                            <Route path={CustomRoutes.INGREDIENTS} element={<ProtectedPage role={Role.INGR} element={<IngredientPage />} />} />
                            <Route
                                path={CustomRoutes.INGREDIENTS + '/:ingredientId'}
                                element={<ProtectedPage role={Role.INGR} element={<IngredientDetails />} />}
                            />

                            <Route path={CustomRoutes.ICD10} element={<ProtectedPage role={Role.ICD10} element={<Icd10Page />} />} />
                            <Route path={CustomRoutes.ICD10 + '/:icd10Id'} element={<ProtectedPage role={Role.ICD10} element={<Icd10Details />} />} />

                            <Route path={CustomRoutes.SIDE_EFFECTS} element={<ProtectedPage role={Role.SE} element={<SideEffectsPage />} />} />
                            <Route
                                path={CustomRoutes.SIDE_EFFECTS + '/:sideEffectId'}
                                element={<ProtectedPage role={Role.SE} element={<SideEffectDetails />} />}
                            />

                            <Route path={CustomRoutes.VITAL_DATA} element={<ProtectedPage element={<VitalDataPage />} role={Role.VD} />} />
                            <Route
                                path={CustomRoutes.VITAL_DATA + '/:vitalDataId'}
                                element={<ProtectedPage role={Role.VD} element={<VitalDataDetails />} />}
                            />

                            <Route path={CustomRoutes.DOCTORS} element={<ProtectedPage element={<DoctorPage />} role={Role.DOC} />} />
                            <Route
                                path={CustomRoutes.DOCTORS + '/:doctorId'}
                                element={<ProtectedPage role={Role.DOC} element={<DoctorDetails />} />}
                            />

                            <Route path={CustomRoutes.PRAXIS} element={<ProtectedPage element={PraxisPage} role={Role.PRAX} />} />
                            <Route
                                path={CustomRoutes.PRAXIS + '/:praxisId'}
                                element={<ProtectedPage role={Role.PRAX} element={<PraxisDetails />} />}
                            />

                            <Route path={CustomRoutes.USER} element={<ProtectedPage element={<UserPage />} role={Role.USER} />} />
                            <Route path={CustomRoutes.USER + '/:userId'} element={<ProtectedPage role={Role.USER} element={<UserDetails />} />} />

                            <Route path={CustomRoutes.THERAPY_NAMES} element={<ProtectedPage element={<TherapyNamePage />} role={Role.TER_NAME} />} />
                            <Route path={CustomRoutes.TITLE} element={<ProtectedPage element={<TitlesPage />} role={Role.TITLE} />} />
                            <Route
                                path={CustomRoutes.DOSAGE_FORMS}
                                element={<ProtectedPage element={<DosageFormsPage />} role={Role.DOSAGE_FORMS} />}
                            />
                            <Route
                                path={CustomRoutes.UNIT_MEASURE}
                                element={<ProtectedPage element={<UnitMeasurePage />} role={Role.UNIT_MEASURE} />}
                            />
                            <Route
                                path={CustomRoutes.FORM_OF_ADDRESS}
                                element={<ProtectedPage element={<FormOfAddressPage />} role={Role.FORM_OF_ADDRESS} />}
                            />
                            <Route path={CustomRoutes.GENDER} element={<ProtectedPage element={<GenderPage />} role={Role.GENDER} />} />
                            <Route
                                path={CustomRoutes.FEDERAL_STATE}
                                element={<ProtectedPage element={<FederalStatePage />} role={Role.FEDERAL_STATE} />}
                            />

                            <Route
                                path={CustomRoutes.PRODUCT_INGREDIENT}
                                element={<ProtectedPage role={Role.PROD_INGR} element={<ProdIngredientPage />} />}
                            />
                            <Route
                                path={CustomRoutes.PRODUCT_INGREDIENT + '/:productIngredientId'}
                                element={<ProtectedPage role={Role.PROD_INGR} element={<ProdIngredientDetails />} />}
                            />

                            <Route path={CustomRoutes.ABDA} element={<ProtectedPage role={Role.ABDA} element={<AbdaPage />} />} />
                        </Routes>
                        <FullLoadingOverlay visible={uiStore.showLoading} key={uiStore.showLoading ? '1' : '0'} />
                    </Content>
                </Layout>

                <Affix style={styles.devWrap}>
                    <div style={styles.devLang}>
                        <Button
                            type='primary'
                            onClick={() => {
                                setLocale(Locale.EN)
                            }}>
                            EN
                        </Button>
                        <Button
                            type='primary'
                            danger={true}
                            onClick={() => {
                                setLocale(Locale.DE)
                            }}>
                            DE
                        </Button>
                    </div>
                </Affix>
            </BrowserRouter>
        </div>
    )
})

export default App
