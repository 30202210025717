import React from 'react'
import I18nCRUD from '../components/I18nCRUD'
import {I18nType} from '../../../utils/types'
import {i18n}  from '../../../i18n'
import {PageHeader} from 'antd'

const TherapyNamePage = () => {
    return (
        <div>
            <PageHeader className='site-page-header-responsive' title={i18n.t('planner.therapyName.title')} onBack={() => window.history.back()}>
                <I18nCRUD
                    type={I18nType.THERAPY_NAME}
                    newTitle={i18n.t('planner.therapyName.new')}
                    failUpdate={i18n.t('planner.therapyName.failUpdate')}
                    failGet={i18n.t('planner.therapyName.failGet')}
                    deleteTitle={i18n.t('planner.therapyName.delete')}
                />
            </PageHeader>
        </div>
    )
}


export default TherapyNamePage
