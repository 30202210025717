import React, {useMemo} from 'react'
import {DoctorDto, DoctorTableEntryType, TableProps} from '../../../utils/types'
import {observer} from 'mobx-react'
import {useNavigate} from 'react-router-dom'
import {Table} from 'antd'
import {Routes} from '../../../utils/routes'
import {i18n}  from '../../../i18n'
import {generateDoctorsColumns} from '../utils/doctor_helper'
import {filterColumns} from '../../../utils/helper'
import {ColumnsType} from 'antd/es/table'

const DoctorTable: React.FC<DoctorTableProps> = observer((props: DoctorTableProps) => {
    const navigate = useNavigate()

    const dataSource: DoctorTableEntryType[] = useMemo(() => {
        return (
            props.data?.map(doc => {
                return {
                    key: doc.id,
                    id: doc.id,
                    firstName: doc.firstName,
                    lastName: doc.lastName,
                    title: doc.title,
                    active: doc.active,
                    praxis: doc.praxis?.name || '',
                    doctorType: doc.doctorType ? i18n.t(`common.doctor.type.${doc.doctorType}`) : '',
                    email: doc.email
                }
            }) || []
        )
    }, [props.data])

    const columns: ColumnsType<DoctorTableEntryType> = useMemo(() => {
        return filterColumns(generateDoctorsColumns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={props.loading}
            pagination={props.pagination}
            onRow={(record: DoctorTableEntryType) => {
                return {
                    onClick: () => navigate(`${Routes.DOCTORS}/${record.id}`)
                }
            }}
            onChange={props.onTableChange}
        />
    )
})

interface DoctorTableProps extends TableProps<DoctorDto> {}

export default DoctorTable
