import React from 'react'
import {styles} from './styles/AboutUs.styles'
import {i18n} from '../../i18n'
import {CEImage, DetailsImage, IImage, SNImage} from '../../assets'
import {Image} from 'antd'

const AboutPage: React.FC = () => {
    return (
        <div style={styles.pageWrapper}>
            <h3 style={styles.padding}>{i18n.t('about.title')}</h3>
            {/*todo: improved by adding more informations*/}
            <div style={{display: 'flex'}}>
                <div style={styles.tableEntry}>
                    <div style={{display: 'flex', width: '50%'}}>
                        <Image src={CEImage} preview={false}/>
                        <Image src={IImage} preview={false}/>
                        <Image src={SNImage} preview={false}/>
                    </div>
                    <div style={styles.textSection}>
                        <p>{i18n.t('about.info.appVersion', {appVersion: process.env.REACT_APP_VERSION || '-'})}</p>
                        <p>{i18n.t('about.info.buildDate', {buildDate: process.env.REACT_APP_DATE_TIME || '-'})}</p>
                        <p>{i18n.t('about.info.buildNumber', {buildNumber: process.env.REACT_APP_BUILD_NUMBER || '-'})}</p>
                    </div>
                </div>
                <div style={styles.tableEntry}>
                    <div style={{display: 'flex', width: '15%'}}>
                        <Image src={DetailsImage} preview={false}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage
