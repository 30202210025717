import React, {useMemo} from 'react'
import {IngredientDto, IngredientTableEntryType, TableProps} from '../../../utils/types'
import {Routes} from '../../../utils/routes'
import {Table} from 'antd'
import {generateIngredientsColumns} from '../utils/ingredients_helper'
import {useNavigate} from 'react-router-dom'
import {filterColumns} from '../../../utils/helper'

const IngredientsTable: React.FC<IngredientsTableProps> = (props: IngredientsTableProps) => {
    const navigate = useNavigate()

    const dataSource: IngredientTableEntryType[] = useMemo(() => {
        return (
            props.data?.map(ingr => {
                return {
                    key: ingr.id,
                    id: ingr.id,
                    keySto: ingr.keySto,
                    name: ingr.name,
                    note: ingr.note,
                    url: ingr.url,
                    active: ingr.active
                }
            }) || []
        )
    }, [props.data])

    const columns = useMemo(() => {
        return filterColumns(generateIngredientsColumns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={props.loading}
            pagination={props.pagination}
            onRow={(record: IngredientTableEntryType) => {
                return {
                    onClick: () => navigate(`${Routes.INGREDIENTS}/${record.id}`)
                }
            }}
            onChange={props.onTableChange}
        />
    )
}

interface IngredientsTableProps extends TableProps<IngredientDto> {}

export default IngredientsTable
