import {LocalStyleSheet} from '../utils/types'
import {Color} from './colors'

const containerStyle = {
    backgroundColor: Color.neutral5,
    padding: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Color.neutral3,
    display: 'flex',
    alignItems: 'center'
}
const columnStyle = {
    display: 'flex',
    flexDirection: 'column' as const
}

const inlineStyle = {
    display: 'flex',
    flexDirection: 'row' as const
}

const flex1 = {
    display: 'flex',
    flex: 1
}

export const sharedStyles: LocalStyleSheet = {
    container: {
        ...containerStyle
    },
    inlineContainer: {
        ...inlineStyle,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sectionWrapper: {
        ...containerStyle,
        flexDirection: 'column',
        alignItems: 'start',
        width: '49.5%'
    },
    column: {
        ...columnStyle
    },
    inline: {
        ...inlineStyle
    },
    border: {
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: Color.warningStrong
    },
    divider: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: Color.neutral3
    },

    leftColumn: {
        ...columnStyle,
        flex: 1,
        marginRight: 5,
        maxWidth: '48%'
    },
    rightColumn: {
        ...columnStyle,
        flex: 1,
        marginLeft: 5,
        maxWidth: '50%'
    },
    actionBtnLeft: {
        ...flex1
    },
    actionBtnRight: {
        ...flex1,
        justifyContent: 'flex-end'
    },

    checkbox: {
        ...inlineStyle,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '3%'
    },

    tab: {
        display: 'flex',
        marginTop: 20
    },

    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}
