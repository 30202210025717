import {ApiMessages, HttpClientData, RequestHandler} from './utils/types'
import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {createClient, errorHandler, onSuccessHandler} from './utils/helper'
import {transformDateValues} from '../utils/helper'

class BaseService implements HttpClientData {
    client: AxiosInstance
    requestHandler: RequestHandler

    constructor() {
        this.client = createClient()
        // @ts-ignore
        this.client.defaults.transformResponse = this.client.defaults.transformResponse.concat((data: any) => transformDateValues(data))
        this.requestHandler = this.initRequestHandler(this.client)
    }

    private initRequestHandler = (client: AxiosInstance) => async (
        requestOptions: AxiosRequestConfig,
        apiMessages?: ApiMessages
    ): Promise<AxiosResponse> => {
        try {
            const response = await client(requestOptions)
            return onSuccessHandler(apiMessages?.success)(response)
        } catch (errorResponse) {
            return errorHandler(apiMessages?.error)(errorResponse)
        }
    }
}

export default BaseService
