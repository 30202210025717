import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {ProductIngredientDto, ProductIngrTableType, TableProps} from '../../../../utils/types'
import {Table} from 'antd'
import {Routes} from '../../../../utils/routes'
import {generateProdIngredientColumns} from '../../utils/relations_helper'
import {filterColumns} from '../../../../utils/helper'
import {useStores} from '../../../../store'
import {ConfigTypes} from '../../../../utils/enums'
import {observer} from 'mobx-react'
import {ColumnsType} from 'antd/es/table'
import {i18n} from '../../../../i18n'

const ProdIngredientTable: React.FC<ProdIngredientTableProps> = observer((props: ProdIngredientTableProps) => {
    const navigate = useNavigate()
    const {profileStore} = useStores()
    const dataSource: ProductIngrTableType[] = useMemo(() => {
        return (
            props.data?.map(ingr => {
                return {
                    key: ingr.id,
                    id: ingr.id,
                    product: ingr.product.name,
                    productId: ingr.product.id,
                    ingredient: ingr.ingredient.name,
                    ingredientId: ingr.ingredient.id,
                    rang: ingr.rang,
                    dose: ingr.dose?.toLocaleString(i18n.locale),
                    unit: profileStore.getI18nText(ConfigTypes.UNIT_MEASURE, ingr.unit)
                }
            }) || []
        )
    }, [props.data])

    const columns: ColumnsType<ProductIngrTableType> = useMemo(() => {
        return filterColumns(generateProdIngredientColumns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={props.loading}
            pagination={props.pagination}
            onRow={(record: ProductIngrTableType) => {
                return {
                    onClick: () => navigate(`${Routes.PRODUCT_INGREDIENT}/${record.id}`)
                }
            }}
            onChange={props.onTableChange}
        />
    )
})

interface ProdIngredientTableProps extends TableProps<ProductIngredientDto> {}

export default ProdIngredientTable
