import React from 'react'
import {sharedStyles} from '../../style/shared_styles'
import {TableProps} from '../../utils/types'
import {ErrorComponent, FullLoader} from '../common'
import {useGetServerData} from './useGetServerData'

const GenericTable = <T extends unknown>(props: GenericTableProps<T>) => {
    const [data, getServerData, loading] = useGetServerData(props.data, props.getDataServer)
    const DataTable = props.dataTable

    if (data === null) {
        return <FullLoader />
    }

    if (data === undefined) {
        return <ErrorComponent status={404} />
    }

    return (
        <div style={{marginTop: sharedStyles.tab.marginTop}}>
            <DataTable data={data} skipColumns={props.skipColumns} onRefreshData={() => getServerData?.()} loading={loading} {...props.extraProps} />
        </div>
    )
}

interface GenericTableProps<T> extends TableProps<T> {
    getDataServer?: () => Promise<T[] | undefined>
    dataTable: React.ComponentType<TableProps<T>>
    extraProps?: {[key: string]: any}
}

export default GenericTable
