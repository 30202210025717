import React, {useEffect, useState} from 'react'
import App from './App'
import Keycloak from 'keycloak-js'
import {observer} from 'mobx-react'
import {useStores} from '../../store'
import './styles/ant_overrides.css'
import {i18n} from '../../i18n'
import {ErrorBoundary, ErrorComponent, FullLoader} from '../common'

const EntryPoint: React.FC = observer(() => {
    const [failedInitKeycloak, setFailedInitKeycloak] = useState(false)

    const {profileStore} = useStores()

    useEffect(() => {
        const keycloak = Keycloak('/keycloak')
        keycloak
            .init({onLoad: 'login-required', checkLoginIframe: false})
            .then(() => {
                profileStore.setKeycloak(keycloak)
            })
            .catch(e => {
                console.error(e)
                setFailedInitKeycloak(true)
            })
    }, [])

    if (failedInitKeycloak) {
        return <ErrorComponent subTitle={i18n.t('common.errors.keycloak')} />
    }

    if (!profileStore.keycloak) {
        return <FullLoader />
    }

    return (
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    )
})

export default EntryPoint
