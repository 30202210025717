import React from 'react'
import {EditModalProps, VitalDataIngredientDto} from '../../../utils/types'
import {useForm} from '../../../utils/hooks'
import {RelationsDtoKeys, vitalDataIngredientMandatoryFields} from '../../../utils/forms/keys'
import {useStores} from '../../../store'
import {sharedStyles} from '../../../style/shared_styles'
import {ActionButtons, LiveSearch} from '../../common'
import {i18n}  from '../../../i18n'
import {SearchTypes} from '../../../utils/enums'
import {observer} from 'mobx-react'
import {useRelationSubmit} from '../utils/hooks'
import {useNavigate} from 'react-router-dom'

const EditVitalDataIng: React.FC<EditVitalDataIngProps> = observer((props: EditVitalDataIngProps) => {
    const [formState, extractProps, onValidateInputs, , isDataChanged] = useForm(
        props.data,
        vitalDataIngredientMandatoryFields,
        vitalDataIngredientMandatoryFields
    )
    const {vitalDataIngStore} = useStores()
    const {loading, onDataSubmit} = useRelationSubmit(vitalDataIngStore, onValidateInputs, formState, (data: VitalDataIngredientDto) => [
        data.vitalData.id,
        data.ingredient.id
    ])

    const onSubmit = async () => {
        const isSuccess: boolean = await onDataSubmit()
        props.onCloseModal?.(isSuccess)
    }

    const navigate = useNavigate()

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn, maxWidth: undefined}}>
                    <LiveSearch
                        label={i18n.t('vitalIng.ingredient')}
                        searchType={SearchTypes.ingredient}
                        defaultValue={formState.values.ingredient}
                        disabled={!!formState.values.ingredient}
                        {...extractProps(RelationsDtoKeys.ingredient)}
                    />
                    <LiveSearch
                        label={i18n.t('vitalIng.vitalData')}
                        searchType={SearchTypes.vitalData}
                        defaultValue={formState.values.vitalData}
                        {...extractProps(RelationsDtoKeys.vitalData)}
                    />
                    <ActionButtons onSave={onSubmit} loadingSave={loading} disabledSave={!isDataChanged} onCancel={() => navigate(-1)} />
                </div>
            </div>
        </div>
    )
})

interface EditVitalDataIngProps extends EditModalProps {
    data?: Partial<VitalDataIngredientDto>
}

export default EditVitalDataIng
