import React from 'react'
import I18nCRUD from '../components/I18nCRUD'
import {I18nType} from '../../../utils/types'
import {i18n}  from '../../../i18n'
import {PageHeader} from 'antd'

const UnitMeasurePage = () => {
    return (
        <div>
            <PageHeader className='site-page-header-responsive' title={i18n.t('planner.unitMeasure.title')} onBack={() => window.history.back()}>
                <I18nCRUD
                    type={I18nType.UNIT_MEASURE}
                    newTitle={i18n.t('planner.unitMeasure.new')}
                    failUpdate={i18n.t('planner.unitMeasure.failUpdate')}
                    failGet={i18n.t('planner.unitMeasure.failGet')}
                    deleteTitle={i18n.t('planner.unitMeasure.delete')}
                />
            </PageHeader>
        </div>
    )
}

export default UnitMeasurePage
