import {RcFile} from 'antd/es/upload'
import {action, makeObservable} from 'mobx'
import {AxiosError, AxiosResponse} from 'axios'
import {i18n} from '../../i18n'
import CrudStore, {DEFAULT_PAGE_SIZE} from '../crud_store'
import {ApiMessages, IUploadService} from '../../service/utils/types'
import {IUIStore, IUploadStore} from '../utils/types'
import {onNotificationError, onNotificationSuccess} from '../../service/utils/notificationHelper'
import {CommonType} from '../../utils/types'

class UploadStore<T extends CommonType> extends CrudStore<T> implements IUploadStore<T> {
    uploadService: IUploadService<T>

    constructor(service: IUploadService<T>, uiStore: IUIStore) {
        super(service, uiStore)
        makeObservable(this, {uploadFile: action})
        this.uploadService = service
    }

    async uploadFile(file: RcFile, apiMes?: ApiMessages): Promise<string | undefined> {
        const response: AxiosResponse<string>|AxiosError<{errorMessage: string, errorCode: number}> = await this.uploadService.uploadFile(file, apiMes)
        if (response instanceof AxiosError) {
            onNotificationError({
                message : i18n.t('upload.messages.fail'),
                description: response.response?.data.errorMessage || i18n.t('upload.messages.fail')
            })
            return Promise.resolve(undefined)
        }
        onNotificationSuccess({
            description: i18n.t('upload.messages.success')
        })
        this.getPaginatedData({page: 1, pageSize: DEFAULT_PAGE_SIZE})

        return Promise.resolve(response.data)
    }
}

export default UploadStore
