import React, {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import {generateIcd10Columns} from '../utils/icd10_helper'
import {Icd10Dto, Icd10TableEntryType, TableProps} from '../../../utils/types'
import {Table} from 'antd'
import {Routes} from '../../../utils/routes'
import {filterColumns} from '../../../utils/helper'
import {ColumnsType} from 'antd/es/table'

const Icd10Table = (props: Icd10TableProps) => {
    const navigate = useNavigate()

    const dataSource: Icd10TableEntryType[] =
        props.data?.map(pr => {
            return {
                key: pr.id,
                id: pr.id,
                externalId: pr.externalId,
                name: pr.name,
                code: pr.code,
                medipolisName: pr.medipolisName,
                active: pr.active
            }
        }) || []

    const columns: ColumnsType<Icd10TableEntryType> = useMemo(() => {
        return filterColumns(generateIcd10Columns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={props.loading}
            pagination={props.pagination}
            onRow={(record: Icd10TableEntryType) => {
                return {
                    onClick: () => navigate(`${Routes.ICD10}/${record.id}`)
                }
            }}
            onChange={props.onTableChange}
        />
    )
}

interface Icd10TableProps extends TableProps<Icd10Dto> {}

export default Icd10Table
