import React from 'react'
import {EditModalProps, IngredientDto} from '../../../utils/types'
import {useForm} from '../../../utils/hooks'
import {ingredientMandatoryFields, ProdIngredientCommonKeys} from '../../../utils/forms/keys'
import {useStores} from '../../../store'
import {sharedStyles} from '../../../style/shared_styles'
import {ActionButtons, Input, TextAreaInput} from '../../common'
import {i18n}  from '../../../i18n'
import {styles} from '../../observations/style/Observation.style'
import {observer} from 'mobx-react'
import {useSubmit} from '../../relations/utils/hooks'
import {useNavigate} from 'react-router-dom'

const EditIngredient: React.FC<EditIngredientProps> = observer((props: EditIngredientProps) => {
    const [formState, extractProps, onValidateInputs, , isDataChanged] = useForm(props.ingredient, ingredientMandatoryFields)
    const {ingredientStore} = useStores()
    const {loading, onDataSubmit} = useSubmit(ingredientStore, onValidateInputs, formState)

    const onSubmit = async () => {
        const isSuccess: boolean = await onDataSubmit()

        if (!props.ingredient && isSuccess) {
            props.onCloseModal?.()
        }
    }

    const navigate = useNavigate()

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn}}>
                    <Input label={i18n.t('prodIngCommon.name')} {...extractProps(ProdIngredientCommonKeys.name)} />
                    <Input label={i18n.t('ingredient.keySto')} {...extractProps(ProdIngredientCommonKeys.keySto)} />
                    <Input label={i18n.t('prodIngCommon.active')} type={'checkbox'} {...extractProps(ProdIngredientCommonKeys.active)} />
                </div>
                <div style={styles.paramColWrapper}>
                    <Input label={i18n.t('ingredient.url')} {...extractProps(ProdIngredientCommonKeys.url)} />
                    <TextAreaInput label={i18n.t('ingredient.note')} {...extractProps(ProdIngredientCommonKeys.note)} />
                </div>
            </div>
            <ActionButtons
                onSave={onSubmit}
                loadingSave={loading}
                disabledSave={!isDataChanged}
                onCancel={props.ingredient ? () => navigate(-1) : props.onCloseModal}
            />
        </div>
    )
})

interface EditIngredientProps extends EditModalProps {
    ingredient?: IngredientDto
}

export default EditIngredient
