import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {IPatientService} from './utils/types'
import BaseService from './base_service'
import {ChangePassDto, ProfileDto} from '../utils/types'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class PatientService extends BaseService implements IPatientService {

    async getUserConfigs(): Promise<AxiosResponse<Record<string, any>>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'GET',
            url: `/api/private/config/`,
            headers: {
                ...authHeader
            }
        })
    }

    async getUserProfile(): Promise<AxiosResponse<ProfileDto>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'GET',
            url: '/api/private/profile/',
            headers: {
                ...authHeader
            }
        })
    }

    async updateProfile(profile: ProfileDto): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'PATCH',
            url: '/api/private/profile/',
            data: profile,
            headers: {
                ...authHeader
            }
        })
    }

    async changePassword(changePass: ChangePassDto): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.requestHandler({
            method: 'PUT',
            url: '/api/private/profile/password',
            data: changePass,
            headers: {
                ...authHeader
            }
        })
    }
}

export default PatientService
