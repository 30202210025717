import React from 'react'
import {sharedStyles} from '../../../style/shared_styles'
import {ActionButtons, Input} from '../../common'
import {i18n}  from '../../../i18n'
import {Icd10Keys, icd10MandatoryFields} from '../../../utils/forms/keys'
import {Icd10Dto} from '../../../utils/types'
import {useForm} from '../../../utils/hooks'
import {useStores} from '../../../store'
import {styles} from '../../observations/style/Observation.style'
import {useSubmit} from '../../relations/utils/hooks'
import {useNavigate} from 'react-router-dom'
import {observer} from 'mobx-react'

const EditIcd10: React.FC<EditIcd10Props> = observer((props: EditIcd10Props) => {
    const [formState, extractProps, onValidateInputs, , isDataChanged] = useForm(props.icd10s, icd10MandatoryFields)
    const {icd10Store} = useStores()
    const {loading, onDataSubmit} = useSubmit(icd10Store, onValidateInputs, formState)
    const navigate = useNavigate()

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn}}>
                    <Input label={i18n.t('icd10.externalId')} {...extractProps(Icd10Keys.externalId)} disabled={true} />
                    <Input label={i18n.t('icd10.name')} {...extractProps(Icd10Keys.name)} disabled={true} />
                </div>
                <div style={styles.paramColWrapper}>
                    <Input label={i18n.t('icd10.code')} {...extractProps(Icd10Keys.code)} disabled={true} />
                    <Input label={i18n.t('icd10.medipolisName')} {...extractProps(Icd10Keys.medipolisName)} />
                    <Input label={i18n.t('icd10.active')} type={'checkbox'} {...extractProps(Icd10Keys.active)} />
                </div>
            </div>
            <ActionButtons onSave={onDataSubmit} loadingSave={loading} disabledSave={!isDataChanged} onCancel={() => navigate(-1)} />
        </div>
    )
})

interface EditIcd10Props {
    icd10s: Icd10Dto
}

export default EditIcd10
