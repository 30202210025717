import React, {useEffect, useState} from 'react'
import {Role} from '../../../utils/enums'
import {DoubleSelectorType} from '../../../utils/types'
import Typography from 'antd/lib/typography'
import {i18n}  from '../../../i18n'
import {Transfer} from 'antd'
import {Color} from '../../../style/colors'
import {observer} from "mobx-react";

export const getSelectorData = (roles: Role[]) => {
    const selectedData: string[] = []
    const allData: DoubleSelectorType[] = []

    Object.keys(Role).forEach(role => {
        const data: DoubleSelectorType = {
            key: role,
            title: i18n.t(`roles.${role}`),
            chosen: roles.findIndex(r => r === role) !== -1
        }
        if (data.chosen) {
            selectedData.push(data.key)
        }
        allData.push(data)
    })
    return {allData, selectedData}
}

const RoleSelection: React.FC<RoleSelectionProps> = observer((props: RoleSelectionProps) => {
    const [targetKeys, setTargetKeys] = useState<string[]>([])
    const [allData, setAllData] = useState<DoubleSelectorType[]>([])

    useEffect(() => {
        if (allData === undefined || allData.length == 0) {
            const {allData, selectedData} = getSelectorData(props.roles)
            setTargetKeys(selectedData)
            setAllData(allData)
        }
    }, [props.roles])

    const filterOption = (inputValue: string, option: DoubleSelectorType) => option.title.toLowerCase().includes(inputValue.toLowerCase())

    const handleChange = (newTargetKeys: string[]) => {
        props.onChangeSelection(newTargetKeys.map(key => Role[key as keyof typeof Role]))
        setTargetKeys(newTargetKeys)
    }

    return (
        <div>
            <div style={{display: 'flex'}}>
                <Transfer
                    dataSource={allData}
                    showSearch={true}
                    disabled={props.disabled}
                    render={(item: DoubleSelectorType) => (
                        <Typography.Title style={{marginBottom: 0}} keyboard={true} level={5}>
                            {item.title}
                        </Typography.Title>
                    )}
                    filterOption={filterOption}
                    onChange={handleChange}
                    listStyle={{display: 'flex', minWidth: 350, minHeight: 500}}
                    targetKeys={targetKeys}
                    pagination={{pageSize: 15}}
                    titles={[
                        <span style={{fontWeight: 'bold', color: Color.neutral2}}>{i18n.t('user.selection.available')}</span>,
                        <span style={{fontWeight: 'bold', color: Color.active}}>{i18n.t('user.selection.selected')}</span>
                    ]}
                />
            </div>
        </div>
    )
})

type RoleSelectionProps = {
    roles: Role[]
    onChangeSelection: (selectedKeys: Role[]) => void
    disabled?: boolean
}

export default RoleSelection
