import {LocalStyleSheet} from '../../../utils/types'

export const styles: LocalStyleSheet = {
    inputWrapper: {
        display: 'flex',
        alignItems: 'center',
    },

    spanLabel: {
        width: 40,
    },

    input: {
        width: '100%',
    },

    tableWrapper: {
        display: 'flex',
        width: '70%',
        justifyContent: 'space-between',
    },
}