import React from 'react'
import {observer} from 'mobx-react'
import {EditModalProps, PraxisDto} from '../../../utils/types'
import {useForm} from '../../../utils/hooks'
import {PraxisDtoKeys, praxisMandatoryFields} from '../../../utils/forms/keys'
import {useStores} from '../../../store'
import ActionButtons from '../../common/ActionButtons'
import {sharedStyles} from '../../../style/shared_styles'
import {Input} from '../../common'
import {i18n}  from '../../../i18n'
import {useSubmit} from '../../relations/utils/hooks'
import {useNavigate} from 'react-router-dom'

const EditPraxis: React.FC<EditPraxisProps> = observer((props: EditPraxisProps) => {
    const [formState, extractProps, onValidateInputs, , isDataChanged] = useForm(props.praxis, praxisMandatoryFields)
    const {praxisStore} = useStores()
    const {loading, onDataSubmit} = useSubmit(praxisStore, onValidateInputs, formState)

    const onSubmit = async () => {
        const isSuccess: boolean = await onDataSubmit()

        if (!props.praxis && isSuccess) {
            props.onCloseModal?.()
        }
    }

    const navigate = useNavigate()

    // if in modal
    const maxWidth = props.praxis ? sharedStyles.leftColumn.maxWidth : undefined
    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn, maxWidth}}>
                    <Input label={i18n.t('praxis.name')} {...extractProps(PraxisDtoKeys.name)} />
                    <Input label={i18n.t('praxis.description')} {...extractProps(PraxisDtoKeys.description)} />
                    <Input label={i18n.t('praxis.active')} type={'checkbox'} {...extractProps(PraxisDtoKeys.active)} />
                    <ActionButtons
                        onSave={onSubmit}
                        loadingSave={loading}
                        disabledSave={!isDataChanged}
                        onCancel={props.praxis ? () => navigate(-1) : props.onCloseModal}
                    />
                </div>
            </div>
        </div>
    )
})

interface EditPraxisProps extends EditModalProps {
    praxis?: PraxisDto
}

export default EditPraxis
