import {LocalStyleSheet} from '../../../utils/types'
import {Color} from '../../../style/colors'

const Icon = {
    fontSize: 25,
    color: Color.active,
    marginLeft: 20
}

export const styles: LocalStyleSheet = {
    paramColWrapper: {
        marginLeft: 20,
        flex: 1
    },
    paramDefItem: {
        display: 'flex',
        borderStyle: 'solid',
        padding: 10,
        borderWidth: 1,
        borderColor: Color.active,
        backgroundColor: Color.lightPurple,
        marginBottom: 10,
        flex: 1
    },
    delWrapper: {
        display: 'flex',
        alignItems: 'center'
    },

    label: {
        marginLeft: 20,
        width: '100%'
    },
    deleteIcon: {
        ...Icon,
        color: Color.warningStrong
    },
    addIcon: {
        ...Icon,
        color: Color.green
    },

    addIconWrapper: {
        display: 'flex',
        flex: 1,
        alignItems: 'flex-start',
        marginTop: 20
    }
}
