import React from 'react'
import I18nCRUD from '../components/I18nCRUD'
import {I18nType} from '../../../utils/types'
import {i18n}  from '../../../i18n'
import {PageHeader} from 'antd'

const FederalStatePage = () => {
    return (
        <div>
            <PageHeader className='site-page-header-responsive' title={i18n.t('planner.federalState.title')}
                        onBack={() => window.history.back()}>
                <I18nCRUD
                    type={I18nType.FEDERAL_STATE}
                    newTitle={i18n.t('planner.federalState.new')}
                    failUpdate={i18n.t('planner.federalState.failUpdate')}
                    failGet={i18n.t('planner.federalState.failGet')}
                    deleteTitle={i18n.t('planner.federalState.delete')}
                />
            </PageHeader>
        </div>
    )
}

export default FederalStatePage
