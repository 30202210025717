import React, {PropsWithChildren} from 'react'
import {Role} from '../../../utils/enums'
import {useStores} from '../../../store'

const Secured: React.FC<PropsWithChildren<SecuredProps>> = props => {
    const {
        profileStore: {userRoles}
    } = useStores()
    const {role, roles} = props

    if (role && !userRoles.includes(role)) {
        return <React.Fragment />
    }
    // user must have all declared roles, otherwise the area is hidden
    if (roles?.every(r => !userRoles.includes(r))) {
        return <React.Fragment />
    }
    return <>{props.children}</>
}

type SecuredProps = RoleProps
export type RoleProps = {
    role?: Role
    roles?: Role[]
}

export default Secured
