import React, {useMemo} from 'react'
import {Table} from 'antd'
import {AnyObj, ProductDto, ProductTableEntryType, TableProps} from '../../../utils/types'
import {Routes} from '../../../utils/routes'
import {i18n}  from '../../../i18n'
import {generateProductColumns} from '../utils/products_helper'
import {useNavigate} from 'react-router-dom'
import {filterColumns} from '../../../utils/helper'
import {ColumnsType} from 'antd/es/table'

const ProductsTable: React.FC<ProductTableProps> = (props: ProductTableProps) => {
    const navigate = useNavigate()
    const dataSource: ProductTableEntryType[] =
        props.data?.map(pr => {
            return {
                key: pr.id,
                id: pr.id,
                name: pr.name,
                active: pr.active,
                packSize: pr.packSize ? i18n.t(`product.packSizeType.${pr.packSize}`) : '',
                packQuantity: pr.packQuantity,
                producer: pr.producer,
                pzn: pr.pzn
            }
        }) || []

    const columns: ColumnsType<ProductTableEntryType> = useMemo(() => {
        return filterColumns(generateProductColumns(), props.skipColumns)
    }, [props.skipColumns])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={props.pagination}
            loading={props.loading}
            onRow={(record: ProductTableEntryType) => {
                return {
                    onClick: () => navigate(`${Routes.PRODUCTS}/${record.id}`)
                }
            }}
            onChange={props.onTableChange}
        />
    )
}

interface ProductTableProps extends TableProps<ProductDto> {
    searchList?: AnyObj
}

export default ProductsTable
