import React, {useEffect, useMemo, useState} from 'react'
import {useForm, useLoading} from '../../../utils/hooks'
import {ObservationKeys, vitalMandatoryFields} from '../../../utils/forms/keys'
import {EditModalProps, OptionItem, VitalDataType, VitalParameterDefinition} from '../../../utils/types'
import {areArraysEqualsWithExclude, buildEnumOptions, deepCopyArray} from '../../../utils/helper'
import {ParameterType} from '../../../utils/enums'
import {useStores} from '../../../store'
import {sharedStyles} from '../../../style/shared_styles'
import {ActionButtons, CustomSelect, Input, TextAreaInput, TitleText} from '../../common'
import {i18n}  from '../../../i18n'
import {PlusCircleOutlined} from '@ant-design/icons'
import EditParamDefinitions from './EditParamDefinitions'
import {styles} from '../../observations/style/Observation.style'
import {emptyParamDef} from '../../side_effects/utils/observation_helper'
import {observer} from 'mobx-react'
import {useNavigate} from 'react-router-dom'

const EditVitalData: React.FC<EditVitalDataProps> = observer((props: EditVitalDataProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler, isDataChanged] = useForm(props.vitalData, vitalMandatoryFields)
    const [editLoading, setEditIsLoading] = useLoading(false)
    const [paramDef, setParamDef] = useState<VitalParameterDefinition[]>(props.vitalData?.parameterDefinitions || [])
    const [validators] = useState<Map<string, () => boolean>>(new Map()) /*map with validation function for each paramDef entry*/

    const parameterType: OptionItem[] = buildEnumOptions(ParameterType, 'observation.paramType')

    const {vitalDataStore} = useStores()
    const navigate = useNavigate()

    const onSubmit = async () => {
        const isValid = onValidateInputs()
        const data = formState.values as VitalDataType

        // validate parameter
        let areParamDefsValid = true
        validators.forEach(validator => {
            areParamDefsValid = areParamDefsValid && validator()
        })

        if (!isValid || !data || !areParamDefsValid) {
            return
        }

        data.parameterDefinitions = paramDef

        setEditIsLoading(true)
        await vitalDataStore.createOrUpdateData(data)
        setEditIsLoading(false)

        if (!props.vitalData) {
            props.onCloseModal?.()
        }
    }

    useEffect(() => {
        setParamDef(props.vitalData?.parameterDefinitions || [])
    }, [props.vitalData])

    useEffect(() => {
        if (!props.vitalData) {
            inputChangeHandler(ParameterType.OPTIONAL, ObservationKeys.parameterType)
            setParamDef([{...emptyParamDef, definitionId: '1'}])
        }
        return () => {
            vitalDataStore.setCurrentData(null)
        }
    }, [])

    const onAddParamDefinition = () => {
        const nextIndex = paramDef.length + 1
        setParamDef([
            ...paramDef,
            {
                ...emptyParamDef,
                definitionId: nextIndex.toString(),
                parameterId: props.vitalData ? props.vitalData.id + '.' + nextIndex : ''
            }
        ])
    }

    const onDeleteParamDef = (index: number) => {
        const copy = deepCopyArray(paramDef)
        copy.splice(index, 1)
        setParamDef(copy)
        validators.delete(index.toString())
    }

    const onChangeParamItem = (index: number) => (param: VitalParameterDefinition) => {
        const copy = deepCopyArray(paramDef)
        copy[index] = param
        setParamDef(copy)
    }

    const saveParamDefValidator = (index: number) => (validator: () => boolean) => {
        validators.set(index.toString(), validator)
    }

    const vitalParamHasChanged = useMemo(() => {
        if (props.vitalData) {
            return !areArraysEqualsWithExclude(paramDef, props.vitalData.parameterDefinitions)
        }
        return true
    }, [paramDef, props.vitalData])

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn}}>
                    <TitleText text={i18n.t('tabs.details.overview')} size={15} />
                    <Input label={i18n.t('observation.name')} {...extractProps(ObservationKeys.name)} />
                    <CustomSelect label={i18n.t('observation.type')} items={parameterType} {...extractProps(ObservationKeys.parameterType)} />
                    <TextAreaInput label={i18n.t('observation.description')} {...extractProps(ObservationKeys.description)} />
                    <Input label={i18n.t('observation.fhirCode')} {...extractProps(ObservationKeys.fhirCode)} />
                </div>
                <div style={styles.paramColWrapper}>
                    <TitleText text={i18n.t('tabs.details.paramDef')} size={15} />
                    {paramDef.map((param, index) => (
                        <EditParamDefinitions
                            key={index}
                            params={param}
                            onDeleteItem={() => onDeleteParamDef(index)}
                            onChangeParam={onChangeParamItem(index)}
                            onSaveValidator={saveParamDefValidator(index)}
                            showDelete={index === paramDef.length - 1}
                        />
                    ))}
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <PlusCircleOutlined style={styles.addIcon} onClick={onAddParamDefinition} />
                    </div>
                </div>
            </div>
            <ActionButtons
                onSave={onSubmit}
                loadingSave={editLoading}
                disabledSave={!isDataChanged && !vitalParamHasChanged}
                onCancel={props.vitalData ? () => navigate(-1) : props.onCloseModal}
            />
        </div>
    )
})

interface EditVitalDataProps extends EditModalProps {
    vitalData?: VitalDataType
}

export default EditVitalData
