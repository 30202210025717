import React from 'react'
import {useStores} from '../../../store'
import {ActionButtons, LiveSearch} from '../../common'
import {i18n}  from '../../../i18n'
import {EditModalProps, SideEffectIngredientDto} from '../../../utils/types'
import {useForm} from '../../../utils/hooks'
import {RelationsDtoKeys, sideEffectIngredientMandatoryFields} from '../../../utils/forms/keys'
import {SearchTypes} from '../../../utils/enums'
import {sharedStyles} from '../../../style/shared_styles'
import {observer} from 'mobx-react'
import {useRelationSubmit} from '../utils/hooks'
import {useNavigate} from 'react-router-dom'

const EditSideEffectIng = observer((props: EditSideEffectIngProps) => {
    const [formState, extractProps, onValidateInputs, , isDataChanged] = useForm(
        props.data,
        sideEffectIngredientMandatoryFields,
        sideEffectIngredientMandatoryFields
    )
    const {sideEffectIngStore} = useStores()
    const {loading, onDataSubmit} = useRelationSubmit(sideEffectIngStore, onValidateInputs, formState, (data: SideEffectIngredientDto) => [
        data.sideEffect.id,
        data.ingredient.id
    ])

    const onSubmit = async () => {
        const isSuccess: boolean = await onDataSubmit()
        props.onCloseModal?.(isSuccess)
    }

    const navigate = useNavigate()

    return (
        <div>
            <div style={sharedStyles.tab}>
                <div style={{...sharedStyles.leftColumn, maxWidth: undefined}}>
                    <LiveSearch
                        label={i18n.t('sideEffectIng.ingredient')}
                        searchType={SearchTypes.ingredient}
                        defaultValue={formState.values.ingredient}
                        disabled={!!formState.values.ingredient}
                        {...extractProps(RelationsDtoKeys.ingredient)}
                    />
                    <LiveSearch
                        label={i18n.t('sideEffectIng.sideEffect')}
                        searchType={SearchTypes.sideEffects}
                        defaultValue={formState.values.sideEffect}
                        {...extractProps(RelationsDtoKeys.sideEffect)}
                    />
                    <ActionButtons onSave={onSubmit} loadingSave={loading} disabledSave={!isDataChanged} onCancel={() => navigate(-1)} />
                </div>
            </div>
        </div>
    )
})

interface EditSideEffectIngProps extends EditModalProps {
    data?: Partial<SideEffectIngredientDto>
}

export default EditSideEffectIng
