import {i18n} from '../i18n'

export const validateVitalData = (decimal: boolean, value: string) => {
    let error = ''
    if (!decimal && value && !isInteger(value)) {
        error =  i18n.t('common.errors.invalidIntegerInput')
    }
    if (decimal && value && !isDecimal(value)) {
        error = i18n.t('common.errors.invalidDecimalInput')
    }
    return error
}

/**
 * This method check if the received value is integer based on a regex test
 * @param value
 */
export const isInteger = (value: any): boolean => {
    return /^\d$|^[1-9]\d*$/.test(value)
}

/**
 * Check if a value is decimal
 * @param value
 */
export const isDecimal = (value: any): boolean => {
    return /^\d$|^[1-9]\d*$|^\d[,.]{1}\d+$|^[1-9]\d*[,.]{1}\d+$/.test(value)
}

/**
 * Transforms a string in a number
 * @param value
 */
export const toNumber = (value: string) => {
    if (!value) {
        return undefined
    }
    if (value?.includes(',')) {
        value = value.replace(',', '.')
    }
    const numberVal = Number(value)
    if (isNaN(numberVal)) {
        return undefined // make it clear there's something wrong
    }
    return numberVal
}

