import {AddressInfo} from "./types";
import {styles} from "../components/profile/styles/ProfilePage.style";

export const formatAddress = (address: AddressInfo | undefined) => {
    if (!address) {
        return undefined;
    }
    return (
        <div>
            <span style={styles.addressLine}>{address.street}</span>
            <span style={styles.addressLine}>{address.postalCode}</span>
            <span style={styles.addressLine}>{`${address.city}, ${address.country}`}</span>
        </div>
    )
}
