import React, {PropsWithChildren, useState} from 'react'
import {Button, PageHeader} from 'antd'
import {i18n} from '../../../i18n'
import {CustomActionProps, EditModalProps} from '../../../utils/types'
import {CustomModal} from '../index'
import {UploadOutlined} from '@ant-design/icons'
import GenericUploadDragger, {GenericUploadDraggerProps} from '../GenericUploadDragger'
import {ButtonStyle} from '../../../style/button'

const ListPageLayout = <T extends unknown>(props: PropsWithChildren<ListPageLayoutProps<T>>) => {
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [uploadModalVisible, setUploadModalVisible] = useState(false)
    const CreateModal = props.createModal
    const extraButtons = []
    if (props.showUploadButton) {
        extraButtons.push(
            <Button key={"upload"} icon={<UploadOutlined />} shape={'round'} onClick={() => setUploadModalVisible(true)}>
                {i18n.t('upload.uploadTitle')}
            </Button>
        )
    }
    if (!props.hideCreateButton) {
        extraButtons.push(
            <Button key='create' type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={() => setCreateModalVisible(true)}>
                {i18n.t('button.create')}
            </Button>
        )
    }
    if (props.customButton !== undefined) {
        extraButtons.push(
            <Button key='custom' type={'default'} shape={'round'} style={ButtonStyle.secondary} onClick={props.customButton.action}>
                {i18n.t(props.customButton.labelKey)}
            </Button>
        )
    }
    return (
        <div>
            <PageHeader
                className='site-page-header-responsive'
                title={props.title}
                onBack={() => (props.onBack ? props.onBack() : window.history.back())}
                extra={extraButtons}>
                {props.children}
            </PageHeader>
            {!props.hideCreateButton && (
                <CustomModal
                    width={props.modalWidth || '70%'}
                    title={props.createModalTitle || ''}
                    visible={createModalVisible}
                    onCancel={() => setCreateModalVisible(false)}>
                    <CreateModal onCloseModal={() => setCreateModalVisible(false)} />
                </CustomModal>
            )}
            {props.showUploadButton && (
                <CustomModal
                    width={props.modalWidth || '70%'}
                    title={i18n.t('upload.uploadTitle')}
                    visible={uploadModalVisible}
                    onCancel={() => setUploadModalVisible(false)}>
                    {props.draggerProps && (
                        <GenericUploadDragger
                            store={props.draggerProps?.store}
                            onCloseModal={() => setUploadModalVisible(false)}
                            acceptedExtensions={props.draggerProps?.acceptedExtensions || []}
                        />
                    )}
                </CustomModal>
            )}
        </div>
    )
}

type ListPageLayoutProps<T>  = {
    title: string
    createModalTitle?: string
    createModal: React.ComponentType<EditModalProps>
    showUploadButton?: boolean
    onBack?: () => void
    modalWidth?: string
    hideCreateButton?: boolean
    draggerProps?: GenericUploadDraggerProps<T>
    customButton?: CustomActionProps
}

export default ListPageLayout
