import {ApiMessages, ICRUDService} from '../service/utils/types'
import {ICRUDStore, IUIStore} from './utils/types'
import {action, makeObservable, observable} from 'mobx'
import {isResponseSuccessful} from '../utils/helper'
import {AnyObj, CommonType, PageRequest} from '../utils/types'
import {deleteEmptyEntryFromObj} from '../service/utils/helper'

export const DEFAULT_PAGE_SIZE = 10

class CrudStore<T extends CommonType> implements ICRUDStore<T> {
    dataList: T[] | null | undefined = null /*if undefined => failed to get data from server*/
    dataCount: number | undefined
    currentData: T | null | undefined = null

    crudService: ICRUDService<T>
    uiStore: IUIStore

    // IF add new fields in child class add makeObservable!!!!!
    constructor(crudService: ICRUDService<T>, uiStore: IUIStore) {
        makeObservable(this, {
            dataList: observable,
            currentData: observable,
            getAllData: action,
            getPaginatedData: action,
            getDataById: action,
            createOrUpdateData: action,
            deleteData: action,
            refreshCurrentData: action,
            setDataList: action,
            setCurrentData: action,
        })
        this.crudService = crudService
        this.uiStore = uiStore
    }

    async getAllData(searchList?: AnyObj, apiMes?: ApiMessages): Promise<void> {
        deleteEmptyEntryFromObj(searchList)
        const response = await this.crudService.getAllData(searchList, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get ${this.getResourcePath}, ${response}`)
            this.setDataList(undefined)
            return Promise.resolve()
        }
        this.setDataList(response.data)
        return Promise.resolve()
    }

    async getPaginatedData(pageRequest: PageRequest, apiMes?: ApiMessages): Promise<void> {
        deleteEmptyEntryFromObj(pageRequest.searchList)
        const response = await this.crudService.getPaginatedData(pageRequest, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get paginated ${this.getResourcePath}, ${response}`)
            this.setDataList(undefined)
            this.setDataCount(undefined)
            return Promise.resolve()
        }
        this.setDataCount(response.data.count)
        this.setDataList(response.data.dataList)
        return Promise.resolve()
    }

    async getDataById(id: string, apiMes?: ApiMessages): Promise<void> {
        const response = await this.crudService.getDataById(id, apiMes)
        this.uiStore.setShowLoading(false)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to get ${this.getResourcePath}, ${response}`)
            this.setCurrentData(undefined)
            return
        }
        this.setCurrentData(response.data)
    }

    async createOrUpdateData(data: T, apiMes?: ApiMessages): Promise<boolean> {
        const response = await this.crudService.createOrUpdateData(data, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to create/update ${this.getResourcePath}, ${response}`)
            return Promise.resolve(false)
        }
        if (data.id) {
            await this.refreshCurrentData()
            this.uiStore.setShowLoading(true)
        } else {
            await this.getPaginatedData({page: 1, pageSize: DEFAULT_PAGE_SIZE})
        }

        return Promise.resolve(true)
    }

    async deleteData(id: string, apiMes?: ApiMessages): Promise<boolean> {
        const response = await this.crudService.deleteData(id, apiMes)
        if (!isResponseSuccessful(response)) {
            console.log(`Failed to delete ingredient, ${response}`)
            return Promise.resolve(false)
        }
        return Promise.resolve(true)
    }

    async refreshCurrentData(): Promise<void> {
        const id = this.currentData?.id
        if (!id) {
            return
        }
        this.uiStore.setShowLoading(true)
        this.getDataById(id)
    }

    setDataList(data: T[] | null | undefined) {
        this.dataList = data
    }

    setCurrentData(data: T | null | undefined) {
        this.currentData = data
    }

    setDataCount(count: number | undefined) {
        this.dataCount = count
    }

    get getResourcePath() {
        return this.crudService.getResourcePath
    }
}

export default CrudStore
