import React, {useMemo, useState} from 'react'
import {IngredientDto, TableProps, VitalDataIngredientDto, VitalDataIngTableType, VitalDataSearchDto} from '../../../utils/types'
import {Table} from 'antd'
import {filterColumns} from '../../../utils/helper'
import {i18n}  from '../../../i18n'
import {CustomModal, PopUpDelete} from '../../common'
import {observer} from 'mobx-react'
import {useDeleteRelations} from '../utils/hooks'
import {useStores} from '../../../store'
import {styles} from '../../observations/style/Observation.style'
import {PlusCircleOutlined} from '@ant-design/icons'
import EditVitalDataIng from './EditVitalDataIng'
import {Routes} from '../../../utils/routes'
import {useNavigate} from 'react-router-dom'

const VitalDataIngTable: React.FC<VitalDataIngTableProps> = observer((props: VitalDataIngTableProps) => {
    const navigate = useNavigate()

    const [modalVisible, setModalVisible] = useState(false)
    const {vitalDataIngStore} = useStores()

    const {loadingDelete, onDelete} = useDeleteRelations(vitalDataIngStore, data => [data.vitalData.id, data.ingredient.id])

    const onDeleteHandler = async (deleteData: VitalDataIngTableType) => {
        const isSuccess = await onDelete(deleteData)
        if (isSuccess) {
            props.onRefreshData?.()
        }
    }

    const onAddNewItem = (isSuccess?: boolean) => {
        setModalVisible(false)
        if (isSuccess) {
            props.onRefreshData?.()
        }
    }

    const dataSource: VitalDataIngTableType[] = useMemo(() => {
        return (
            props.data?.map(ingr => {
                const {vitalData, ingredient} = ingr
                return {
                    key: vitalData.id + ingredient.id,
                    id: vitalData.id + ingredient.id,
                    vitalData: vitalData,
                    ingredient: ingredient
                }
            }) || []
        )
    }, [props.data])

    const generateColumns = useMemo(() => {
        return [
            {
                title: i18n.t('vitalIng.vitalData'),
                dataIndex: 'vitalData',
                key: 'vitalData',
                sorter: (a: VitalDataIngTableType, b: VitalDataIngTableType) => a.vitalData.name.localeCompare(b.vitalData.name),
                render: (vitalData: VitalDataSearchDto) => vitalData.name,
                onCell: (record: VitalDataIngTableType) => {
                    return {
                        onClick: () => navigate(`${Routes.VITAL_DATA}/${record.vitalData.id}`)
                    }
                }
            },
            {
                title: '',
                dataIndex: 'operation',
                width: 30,
                render: (_: any, record: VitalDataIngTableType) => (
                    <PopUpDelete
                        onDelete={async () => onDeleteHandler(record)}
                        messageDelete={i18n.t('vitalIng.messages.delete')}
                        isIcon={true}
                        loading={loadingDelete}
                    />
                )
            }
        ]
    }, [])

    const columns = useMemo(() => {
        return filterColumns(generateColumns, props.skipColumns)
    }, [props.skipColumns])

    const data: Partial<VitalDataIngredientDto> | undefined = useMemo(() => {
        if (!props.ingredient) {
            return undefined
        }
        const {id, name, note, keySto} = props.ingredient
        return {
            ingredient: {
                id,
                name,
                note,
                keySto
            }
        }
    }, [props.ingredient])

    return (
        <div style={{display: 'flex', width: '50%', justifyContent: 'space-between'}}>
            <div style={{flex: 4}}>
                <Table dataSource={dataSource} columns={columns} loading={props.loading} rowClassName={() => 'editable-row'} pagination={false} />
            </div>

            <div style={styles.addIconWrapper}>
                <PlusCircleOutlined style={styles.addIcon} onClick={() => setModalVisible(true)} />
            </div>
            <CustomModal title={i18n.t('vitalIng.create')} visible={modalVisible} onCancel={() => setModalVisible(false)}>
                <EditVitalDataIng data={data} onCloseModal={onAddNewItem} />
            </CustomModal>
        </div>
    )
})

interface VitalDataIngTableProps extends TableProps<VitalDataIngredientDto> {
    ingredient?: IngredientDto
}

export default VitalDataIngTable
