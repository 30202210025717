import {
    IAbdaStore,
    IDoctorStore,
    II18nStore,
    IIcd10Store,
    IIngredientStore,
    IPraxisStore,
    IProductIngredientStore,
    IProductStore,
    IProfileStore,
    IRelationsStore,
    ISideEffectStore,
    IUIStore,
    IUsersStore,
    IVitalDataStore,
    Store,
} from './utils/types'
import RootService, {IRootService} from '../service/root_service'
import {createContext, useContext} from 'react'
import ProfileStore from './profile_store'
import {useLocalObservable} from 'mobx-react'
import Keycloak from 'keycloak-js'
import UIStore from './ui_store'
import ProductStore from './product_store'
import IngredientStore from './ingredient_store'
import RelationsStore from './relations_store'
import {SideEffectIngredientDto, VitalDataIngredientDto} from '../utils/types'
import DoctorStore from './doctor_store'
import VitalDataStore from './vital_data_store'
import SideEffectStore from './side_effect_store'
import Icd10Store from './icd10_store'
import PraxisStore from './praxis_store'
import UsersStore from './users_store'
import I18nStore from './i18n_store'
import ProductIngredientStore from './product_ingredient_store'
import AbdaStore from "./abda_store";

class StateManager {
    stores: IAppStores
    services: IRootService

    constructor() {
        this.services = new RootService()
        this.stores = this.createNewStores()

        this.initStore()
    }

    private createNewStores = (): IAppStores => {
        const ser = this.services
        const uiStore = new UIStore()

        return {
            profileStore: new ProfileStore(ser.patientService),
            vitalDataStore: new VitalDataStore(ser.vitalDataService, uiStore),
            sideEffectStore: new SideEffectStore(ser.sideEffectService, uiStore),
            productStore: new ProductStore(ser.productService, uiStore),
            icd10Store: new Icd10Store(ser.icd10Service, uiStore),
            abdaStore: new AbdaStore(ser.abdaService, uiStore),
            doctorStore: new DoctorStore(ser.doctorService, uiStore),
            praxisStore: new PraxisStore(ser.praxisService, uiStore),
            ingredientStore: new IngredientStore(ser.ingredientService, uiStore),
            usersStore: new UsersStore(ser.userService, uiStore),
            i18nStore: new I18nStore(ser.i18nService),
            productIngStore: new ProductIngredientStore(ser.productIngService, uiStore),
            sideEffectIngStore: new RelationsStore<SideEffectIngredientDto>(ser.sideEffectIngService, uiStore),
            vitalDataIngStore: new RelationsStore<VitalDataIngredientDto>(ser.vitalDataIngService, uiStore),
            uiStore,
        }
    }

    private initStore = () => {
        Object.values(this.stores).forEach((store: Store) => {
            if (typeof store.initialize === 'function') store.initialize()
        })
    }

    // todo: need to be called?
    // EDIT: don't need to call explicitly because after logout the entire JS will reload and the stores will be recreated
    private async reset() {
        const cleanupPromises: Promise<any>[] = []
        // Call the cleanup function for each store if present
        Object.values(this.stores).forEach((store: Store) => {
            if (typeof store.cleanup === 'function') cleanupPromises.push(store.cleanup())
        })

        // make sure cleanups are done before we re-init everything
        await Promise.all(cleanupPromises)
    }
}

export type IAppStores = {
    profileStore: IProfileStore
    sideEffectStore: ISideEffectStore
    vitalDataStore: IVitalDataStore
    uiStore: IUIStore
    productStore: IProductStore
    icd10Store: IIcd10Store
    abdaStore: IAbdaStore
    doctorStore: IDoctorStore
    praxisStore: IPraxisStore
    ingredientStore: IIngredientStore
    usersStore: IUsersStore
    i18nStore: II18nStore
    productIngStore: IProductIngredientStore
    sideEffectIngStore: IRelationsStore<SideEffectIngredientDto>
    vitalDataIngStore: IRelationsStore<VitalDataIngredientDto>
}

export const rootStore = new StateManager()

const createStateManager = () => rootStore

// @ts-ignore
export const storeContext = createContext<StateManager>(null)

export const useStores = (): IAppStores => {
    const state: StateManager = useContext<StateManager>(storeContext)
    return state?.stores
}
export const useServices = (): IRootService => {
    const state: StateManager = useContext<StateManager>(storeContext)
    return state?.services
}

export const StoreContextProvider = (props: any) => {
    const stateManager = useLocalObservable(createStateManager)
    return <storeContext.Provider value={stateManager}>{props.children}</storeContext.Provider>
}

export const useAuthToken = async ():  Promise<string> => {
    await rootStore.stores.profileStore.refreshAccessToken()
    return rootStore.stores.profileStore.keycloak?.token || '';
}

export const useLogout = (): Keycloak.KeycloakPromise<void, void> | undefined => {
    return rootStore.stores.profileStore.keycloak?.logout() || undefined;
}

export type RootStoreManager = StateManager
